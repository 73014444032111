<template>
    <div class="banner-container">
        <a-carousel class="carousel-container" autoplay>
            <div class="item-container" v-for="item in list" :key="item.id">
                <img class="item-img" :src="item.bannerImg" alt="" />
                <!-- <img class="item-bg" src="@/assets/image/home/banner-bg.png" alt=""> -->
                <div class="item-text">
                    <h3 class="item-title">{{item.title}}</h3>
                    <p class="item-desc">{{item.desc}}</p>
                    <div class="item-more" @click="handleClick(item)">了解更多</div>
                </div>
            </div>
        </a-carousel>
        <img class="banner-bg2" src="@/assets/image/home/banner-bg2.png" alt="" />
    </div>
</template>

<script>
export default {
  name: 'Banner',
  data () {
    return {
      list: [
        { title: '云服务器超低价畅享', desc: '多种规格选择 低价畅享 流量放心用', id: 9, bannerImg: 'https://img.cdn.mg.xyz/mgcloud/photo/image/home/home-banner-img1.jpg' },
        { title: '硬盘全新放送', desc: '满足线下业务无缝迁移上云', id: 11, bannerImg: 'https://img.cdn.mg.xyz/mgcloud/photo/image/home/home-banner-img2.jpg' },
      ],
    }
  },
  methods: {
    handleClick (item) {
      this.$router.push({
        name: 'products',
        params: {
          id: item.id,
        },
      })
    },
  },
}
</script>
<style lang="scss">
.slick-dots-bottom {
    bottom: 3.325vw !important;
}
</style>
<style lang="scss" scoped>
.banner-container{
    position: relative;
    width: 100%;
    height: 24.792vw;

     .banner-bg{
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 20;
        width: 100%;
        height: 100%;
    }

     .banner-bg2{
        position: absolute;
        right: 0;
        bottom: -1px;
        left: 0;
        z-index: 20;
        width: 100%;
        height: 3.099vw;
    }

    .carousel-container{
        height: 100%;

        .item-container{
            position: relative;
            width: 100%;
            height: 24.792vw;

            .item-img{
                position: absolute;
                top: 0;
                right: 0;
                z-index: 10;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            .item-bg{
                position: absolute;
                top: 0;
                right: 0;
                left: 0;
                z-index: 20;
                width: 100%;
                height: 100%;
            }

            .item-text{
                position: absolute;
                top: 50%;
                left: 18.75vw;
                z-index: 30;
                transform: translateY(-50%);

                .item-title{
                    height: 2.5vw;
                    color: #FFF;
                    font-weight: 500;
                    font-size: 2.083vw;
                    line-height: 2.5vw;
                }

                .item-desc{
                    width: 31.25vw;
                    margin-top: 0.833vw;
                    color: #FFF;
                    font-weight: normal;
                    font-size: 0.833vw;
                    opacity: 0.5;
                }

                .item-more{
                    display: inline-block;
                    height: 2.5vw;
                    margin-top: 2.5vw;
                    padding: 0 2.083vw;
                    color: #222;
                    font-weight: normal;
                    font-size: 0.729vw;
                    line-height: 2.5vw;
                    text-align: center;
                    background: #FFF;
                    border-radius: 1.302vw;
                    cursor: pointer;

                    &:hover{
                        background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), #FFF;
                    }
                }
            }

        }

    }

}
</style>
