<template>
    <div class="product-advantage">
        <div class="title">强大的生态服务</div>
        <div class="intro">
            <div>芒果云强大的生态体系和多样化的服务支持，助您无忧上云。</div>
            <div>数字化成功的关键是以云原生的思维践行云原生，全数字化、全云化、AI驱动，一切皆服务。</div>
            <div>致力于让云无处不在，让智能无所不及，共建智能世界云底座。</div>
            <div>面向未来的智能世界，数字化是企业发展的必由之路。</div>
        </div>
        <div class="product-item">
            <img class="img2" src="https://img.cdn.mg.xyz/mgcloud/photo/image/about/about-advantage-bg.jpg" alt="" />
        </div>
    </div>
</template>

<script>
export default {
  name: 'ProductAdvantage',
}
</script>

<style lang="scss" scoped>
.product-advantage{
    display: flex;
    flex-direction: column;
    padding: 0 18.75vw;
    .title{
        height: 1.875vw;
        margin-top: 2.5vw;
        color: #222;
        font-weight: 500;
        font-size: 1.563vw;
        line-height: 1.875vw;
        text-align: center;
    }

    .intro{
        margin-top: 1.25vw;
        color: #646566;
        font-weight: normal;
        font-size: 0.729vw;
        line-height: 1.146vw;
        text-align: center;
    }

    .product-item{
        display: flex;
        justify-content: space-between;
        height: 16.667vw;
        margin-top: 1.667vw;

        .img2{
            width: 100%;
            height: 16.667vw;
        }

    }
}
</style>
