import Layout from '@/layouts/RouterView'
import store from '@/store'
// import i18n from '@/locales'

const Customer = () => import(/* webpackChunkName: "customer" */ /* webpackPrefetch: true */ '@Customer/views/customer')
const CustomerCreate = () => import(/* webpackChunkName: "customer" */ /* webpackPrefetch: true */ '@Customer/views/customer/create')

export default {
  index: 1500,
  meta: {
    label: '客户管理',
    icon: 'menu-customer',
  },
  menus: [
    {
      meta: {
        label: '客户管理',
      },
      submenus: [
        {
          path: '/customer',
          meta: {
            label: '客户列表',
            permission: 'customers_list',
            hidden: () => {
              if (store.getters.userInfo.user_type === 'customer') {
                return true
              }
            },
          },
          component: Layout,
          children: [
            {
              name: 'Customer',
              path: '',
              meta: {},
              component: Customer,
            },
            {
              name: 'CustomerCreate',
              path: 'create',
              meta: {},
              component: CustomerCreate,
            },
          ],
        },
      ],
    },
  ],
}
