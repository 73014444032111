<template>
  <base-dialog @cancel="cancelDialog">
    <div slot="header">{{this.params.title}}</div>
    <div slot="body">
      <a-alert type="warning" class="mb-4">
        <div slot="message">{{ $t('storage.local_storage.help_alert_message') }}<help-link :href="localStorageUrl">{{ $t('storage.local_storage.help_link') }}</help-link></div>
      </a-alert>
      <a-form :form="form.fc" v-bind="formItemLayout">
        <a-form-item :label="$t('storage.text_55', [$t('dictionary.domain')])">
          <domain-select v-if="isAdminMode && l3PermissionEnable" v-decorator="decorators.project_domain" />
          <template v-else> {{userInfo.domain.name}} </template>
        </a-form-item>
        <!-- <a-form-item :label="$t('storage.text_47')">
          <cloudregion-zone
            :cloudregionParams="{cloud_env: 'onpremise'}"
            :decorator="decorators.regionZone"
            filterBrandResource="loadbalancer_engine" />
        </a-form-item> -->
        <area-selects
          class="mb-0"
          ref="areaSelects"
          :wrapperCol="formItemLayout.wrapperCol"
          :labelCol="formItemLayout.labelCol"
          :names="['cloudregion']"
          :cloudregionParams="{cloud_env: 'onpremise'}"
          :isRequired="true"
          :region.sync="regionList"
          filterBrandResource="loadbalancer_engine"
          :zone.sync="zoneList" />
        <a-form-item :label="$t('storage.text_40')">
          <a-input :placeholder="$t('storage.text_56')" v-decorator="decorators.name" />
        </a-form-item>
        <a-form-item :label="$t('common.description')">
          <a-textarea :auto-size="{ minRows: 1, maxRows: 3 }" v-decorator="decorators.description" :placeholder="$t('common_367')" />
        </a-form-item>
        <a-form-item :label="$t('storage.text_39')">
          <a-radio-group v-decorator="decorators.medium_type" buttonStyle="solid">
            <a-radio-button v-for="(v, k) in MEDIUM_TYPES" :key="v" :value="k">{{v}}</a-radio-button>
          </a-radio-group>
        </a-form-item>
        <a-form-item :label="$t('storage.text_38')">
          <a-radio-group v-decorator="decorators.storage_type" buttonStyle="solid">
            <template v-for="(v, k) in STORAGE_TYPES">
              <a-radio-button v-if="storageTypes.indexOf(k) > -1" :key="k"  :value="k">{{v}}</a-radio-button>
            </template>
          </a-radio-group>
        </a-form-item>
        <form-items :storage_type="getFieldValue('storage_type')" />
        <a-form-item :label="$t('common.text00012')" class="mb-0">
          <tag
            v-decorator="decorators.__meta__" :allowNoValue="false" />
        </a-form-item>
      </a-form>
    </div>
    <div slot="footer">
       <div class="d-inline-block" v-if="false">
        <div class="mr-4 d-flex align-items-center">
          <div class="text-truncate">{{ $t("compute.text_286") }}</div>
          <div class="ml-2 prices">
            <div v-if="price" class="hour d-flex">
              <div>{{ price }}</div>
            </div>
            <div v-if="originPrice" class="hour d-flex">
              <div>{{ originPrice }}</div>
            </div>
            <div v-else class="tips text-truncate">
              <span v-html="priceTips" />
            </div>
          </div>
        </div>
      </div>
      <a-button type="primary" @click="handleConfirm" :loading="loading">{{ $t("dialog.ok") }}</a-button>
      <a-button @click="cancelDialog">{{ $t('dialog.cancel') }}</a-button>
    </div>
  </base-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import { MEDIUM_TYPES, STORAGE_TYPES, formItemLayout, MEDIUM_TYPES_MAPPING } from '@Storage/constants/index.js'
import FormItems from '@Storage/views/blockstorage/components/FormItems'
import DialogMixin from '@/mixins/dialog'
import WindowsMixin from '@/mixins/windows'
// import CloudregionZone from '@/sections/CloudregionZone'
import AreaSelects from '@/sections/AreaSelects'
import DomainSelect from '@/sections/DomainSelect'
import Tag from '@/sections/Tag'
import validateForm from '@/utils/validate'
import { getDocsUrl } from '@/utils/utils'
import { numerify } from '@/filters'

export default {
  name: 'BlockStorageCreateDialog',
  components: {
    // CloudregionZone,
    AreaSelects,
    FormItems,
    DomainSelect,
    Tag,
  },
  mixins: [DialogMixin, WindowsMixin],
  provide () {
    return {
      form: this.form,
    }
  },
  data () {
    return {
      MEDIUM_TYPES,
      STORAGE_TYPES,
      loading: false,
      form: {
        fc: this.$form.createForm(this, {
          onValuesChange: (props, values) => {
            Object.keys(values).forEach((key) => {
              this.form.fd[key] = values[key]
            })
            // this._getPrice()
            // console.log('onValueChange this.form.fd=', this.form.fd)
          },
        }),
        fd: {
          storage_type: 'rbd',
        },
      },
      formItemLayout,
      regionList: {},
      zoneList: {},
      currency: '¥',
      discount: 0,
      price: null,
      priceFormat: null,
      originPrice: null,
      priceTips: '--',
    }
  },
  computed: {
    ...mapGetters(['isAdminMode', 'userInfo', 'l3PermissionEnable']),
    storageTypes () {
      return this.params.storageTypes || ['rbd', 'nfs', 'gpfs', 'local']
    },
    getFieldValue () {
      return this.form.fc.getFieldValue
    },
    decorators () {
      return {
        name: [
          'name',
          {
            validateFirst: true,
            rules: [
              { required: true, message: this.$t('storage.text_56') },
              { validator: this.$validate('blockStorageName') },
            ],
          },
        ],
        description: ['description'],
        regionZone: {
          cloudregion: [
            'cloudregion',
            {
              initialValue: { key: '', label: '' },
              rules: [
                { required: true, message: this.$t('storage.text_57') },
              ],
            },
          ],
          zone: [
            'zone',
            {
              initialValue: { key: '', label: '' },
              rules: [
                { required: true, message: this.$t('storage.text_58') },
              ],
            },
          ],
        },
        medium_type: [
          'medium_type',
          {
            initialValue: 'rotate',
          },
        ],
        storage_type: [
          'storage_type',
          {
            initialValue: 'rbd',
          },
        ],
        project_domain: [
          'project_domain',
          {
            initialValue: this.userInfo.projectDomainId,
          },
        ],
        __meta__: [
          '__meta__',
          {
            rules: [
              { validator: validateForm('tagName') },
            ],
          },
        ],
      }
    },
    isLocalStorage () {
      return this.form.fd.storage_type === 'local'
    },
    localStorageUrl () {
      return `${getDocsUrl()}function_principle/onpremise/storage/blockstorage/add-storage/ `
    },
  },
  methods: {
    validateForm () {
      return new Promise((resolve, reject) => {
        this.form.fc.validateFields((err, values) => {
          if (err) return reject(err)
          // eslint-disable-next-line camelcase
          const { storage_type, zone, cloudregion } = values
          const deleteRbdKeys = ['nfs_host', 'nfs_shared_dir']
          const deleteNfsKeys = ['rbd_mon_host', 'rbd_key', 'rbd_pool']
          const deleteKeys = {
            rbd: deleteRbdKeys,
            nfs: deleteNfsKeys,
            gpfs: [...deleteRbdKeys, ...deleteNfsKeys],
          }
          if (zone) {
            values.zone = zone.key
          }
          if (cloudregion) {
            values.area = cloudregion.key
            delete values.cloudregion
          }
          deleteKeys[storage_type].forEach(key => {
            delete values[key]
          })
          // 添加area_mapping_id
          values.area_mapping_id = this.form.fd.cloudregion
          resolve(Object.assign({}, { capacity: 0 }, values))
        })
      })
    },
    async handleConfirm () {
      this.loading = true
      try {
        const values = await this.validateForm()
        const manager = new this.$Manager('storages', 'v2')
        await manager.create({ data: values })
        this.cancelDialog()
        this.params.refresh()
      } catch (error) {
        this.loading = false
        throw error
      }
    },
    priceFormat1 (price, unit) {
      const str = price.toString()
      const ary = str.split('.')
      let len = 0
      if (ary.length > 1) {
        len = ary[1].length
      }
      let u = '0,0.00'
      if (len > 2) {
        for (let i = 0; i < len - 2; i++) {
          u += '0'
        }
      }
      price = numerify(price, u)

      return `${this.currency} ${price}${unit}`
    },
    async _getPrice () {
      await this.$nextTick()
      const medium_type = this.form.fc.getFieldValue('medium_type')
      const cloudregion = this.form.fd.cloudregion
      if (!cloudregion) return
      const params = {
        area_mapping_id: cloudregion,
        type: MEDIUM_TYPES_MAPPING[medium_type],
      }
      new this.$Manager('price_ebss/price', 'v2')
        .list({
          params: params,
        })
        .then((res) => {
          console.log(res)
          // this.price = this.priceFormat1(res.data.monthly_price, '/月')
          this.originPrice = this.priceFormat1(res.data.volume_price, '/时')
        })
    },
  },
}
</script>
<style lang="less" scoped>
@import "../../../../../src/styles/less/theme";

.prices {
  .hour {
    color: @error-color;
    font-size: 24px;
  }
  .tips {
    color: #999;
    font-size: 12px;
  }
}
</style>
