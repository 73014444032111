<template>
  <div>
    SubAccount
  </div>
</template>

<script>
export default {
  name: 'SubAccount',
}
</script>

<style lang="scss" scoped>

</style>
