<template>
  <base-side-page
    @cancel="cancelSidePage"
    :title="$t('dictionary.customer')"
    icon="res-user"
    :res-name="detailData.name"
    :actions="params.actions"
    :current-tab="params.windowData.currentTab"
    :tabs="detailTabs"
    :loaded="loaded"
    @tab-change="handleTabChange">
    <template v-slot:actions>
      <actions :options="singleActions" :row="detailData" button-type="link" button-size="small" />
    </template>
    <component :is="params.windowData.currentTab" :onManager="onManager" :res-id="data.id" :id="listId" :data="detailData" :getParams="getParams" @tab-change="handleTabChange" />
  </base-side-page>
</template>

<script>
import SidePageMixin from '@/mixins/sidePage'
import WindowsMixin from '@/mixins/windows'
import Actions from '@/components/PageList/Actions'
import ColumnsMixin from '../mixins/columns'
import SingleActionsMixin from '../mixins/singleActions'
import UserDetail from './Detail'
import SubAccount from './SubAccount'
import AvailableResources from './AvailableResources'
import UserCredites from './UserCredites'
import SecondPrice from './SecondPrice'

export default {
  name: 'CustomerSidePage',
  components: {
    'user-detail': UserDetail,
    SubAccount,
    AvailableResources,
    UserCredites,
    SecondPrice,
    Actions,
  },
  mixins: [SidePageMixin, WindowsMixin, ColumnsMixin, SingleActionsMixin],
  data () {
    return {
      detailTabs: [
        { label: this.$t('system.text_159'), key: 'user-detail' },
        // { label: '子账号', key: 'sub-account' },
        { label: '授信额度', key: 'user-credites' },
        { label: '可用资源', key: 'available-resources' },
        { label: '二次定价', key: 'second-price' },
        { label: this.$t('system.text_17'), key: 'event-drawer' },
      ],
    }
  },
  computed: {
    getParams () {
      if (this.params.windowData.currentTab === 'clouduser-list') {
        return {
          user_id: this.data.id,
        }
      } else if (this.params.windowData.currentTab === 'samluser-list') {
        return {
          user_id: this.data.id,
        }
      }
      return null
    },
    listId () {
      switch (this.params.windowData.currentTab) {
        case 'event-drawer':
          return 'EventListForUserSidePage'
        case 'sub-account':
          return 'SubAccountListForUserSidePage'
        case 'available-resources':
          return 'AvailableResourcesListForUserSidePage'
        case 'user-credites':
          return 'UserCreditesListForUserSidePage'
        case 'second-price':
          return 'SecondPriceListForUserSidePage'
        default:
          return ''
      }
    },
  },
  created () {
    if (this.params.tab) {
      this.handleTabChange(this.params.tab)
    }
  },
  methods: {
    handleOpenSidepage (row, tab) {
      this.handleTabChange(tab)
    },
  },
}
</script>
