import NotFoundPage from '@/views/exception/404'
import NoPermission from '@/views/exception/403'
import EmailVerify from '@/views/email-verify'
import NoProject from '@/views/no-project'
import Clouduser from '@/views/clouduser'
import Layout from '@/layouts/Portal/Layout'
import PortalLogin from '@/views/portal/login'
import PortalHome from '@/views/portal/home'
import PortalAbout from '@/views/portal/about'
import PortalContactUs from '@/views/portal/contactus'
import PortalBidding from '@/views/portal/bidding'
import PortalBiddingDetail from '@/views/portal/bidding/detail'
import PortalProducts from '@/views/portal/products'
import PortalDocuments from '@/views/portal/documents'
import PortalDocumentsDetail from '@/views/portal/documents/detail'
import PortalDocumentsBilling from '@/views/portal/documents/billing'

export const menusConfig = getModulesRouteConfig()

const routes = [
  ...getScopeRoutes(),
  ...generateRoutesFromMenu(menusConfig),
  {
    name: 'Clouduser',
    path: '/clouduser',
    component: Clouduser,
  },
  {
    path: '/email-verification/id/:id/token/:token',
    name: 'EmailVerification',
    meta: {
      layout: 'full-screen',
      auth: false,
    },
    component: EmailVerify,
  },
  { name: 'NoProject', path: '/no-project', component: NoProject, meta: { layout: 'full-screen' } },
  { name: '404', path: '/404', component: NotFoundPage, meta: { layout: 'full-screen', auth: false } },
  { name: '403', path: '/403', component: NoPermission, meta: { layout: 'full-screen', auth: false } },
  { name: 'NotFound', path: '*', component: NotFoundPage, meta: { layout: 'full-screen', auth: false } },
  { path: '/auth/login', component: PortalLogin, meta: { layout: 'full-screen', auth: false } },
  {
    path: '/portal',
    component: Layout,
    // component: AuthLayout,
    meta: { layout: 'full-screen', auth: false },
    redirect: '/portal/home',
    children: [
      {
        path: 'home',
        component: PortalHome,
        meta: { layout: 'full-screen', auth: false },
      },
      {
        path: 'about',
        component: PortalAbout,
        meta: { layout: 'full-screen', auth: false },
      },
      {
        path: 'contactus',
        component: PortalContactUs,
        meta: { layout: 'full-screen', auth: false },
      },
      {
        path: 'bidding',
        component: PortalBidding,
        meta: { layout: 'full-screen', auth: false },
      },
      {
        path: 'biddingDetail',
        component: PortalBiddingDetail,
        meta: { layout: 'full-screen', auth: false },
      },
      {
        name: 'products',
        path: 'products/:id',
        component: PortalProducts,
        meta: { layout: 'full-screen', auth: false },
      },
      {
        name: 'documents',
        path: 'documents',
        component: PortalDocuments,
        meta: { layout: 'full-screen', auth: false },
      },
      {
        name: 'documentsDetail',
        path: 'documentsDetail',
        component: PortalDocumentsDetail,
        meta: { layout: 'full-screen', auth: false },
      },
      {
        name: 'billing',
        path: 'billing',
        component: PortalDocumentsBilling,
        meta: { layout: 'full-screen', auth: false },
      },
    ],
  },
]

// console.log('routes=', routes)

function getScopeRoutes () {
  const r = require.context('../../scope', true, /.\/router\/routes.js/)
  const keys = r.keys()
  let ret = []
  if (keys && keys.length) {
    ret = r(keys[0]).default
  }
  return ret
}

function getModulesRouteConfig () {
  const isPrivate = process.env.VUE_APP_IS_PRIVATE
  let ret = []
  const r = isPrivate ? require.context('../../scope', true, /.\/router\/index.js/) : require.context('../../containers', true, /^((?![\\/]node_modules).)*.\/router\/index.js$/)
  r.keys().forEach(dir => {
    ret = ret.concat(r(dir).default)
  })
  ret.sort((a, b) => a.index - b.index)
  ret = ret.filter(val => !val.meta.undetected)
  for (let i = 0, len = ret.length; i < len; i++) {
    const item = ret[i]
    item.meta.group = i
    if (item.menus) {
      for (let j = 0; j < item.menus.length; j++) {
        const menu = item.menus[j]
        if (menu.submenus) {
          for (let m = 0; m < menu.submenus.length; m++) {
            const subitem = menu.submenus[m]
            subitem.meta.group = i
          }
        } else {
          menu.meta.group = i
        }
      }
    } else {
      item.menu.meta.group = i
    }
  }
  return ret
}

// Menu should have 2 levels.
function generateRoutesFromMenu (menugroups = [], routes = []) {
  for (let m = 0, ml = menugroups.length; m < ml; m++) {
    const mg = menugroups[m]
    if (mg.menu) {
      routes.push(mg.menu)
    }
    if (mg.menus) {
      for (let i = 0, l = mg.menus.length; i < l; i++) {
        const item = mg.menus[i]
        if (item.path) {
          routes.push(item)
        }
        if (item.submenus) {
          // second tier
          for (let j = 0; j < item.submenus.length; j++) {
            const subitem = item.submenus[j]
            if (subitem.path) {
              routes.push(subitem)
            }
          }
        }
      }
    }
  }
  return routes
}

export default routes
