// import { getEnabledSwitchActions } from '@/utils/common/tableActions'
import i18n from '@/locales'

export default {
  created () {
    this.singleActions = [
      {
        label: i18n.t('cost.text_2'),
        permission: 'order_properties',
        // sidePageTriggerHandle在windows.js中，第一个对象是options，第二个对象是params
        action: (obj) => {
          this.sidePageTriggerHandle(this, 'OrderSidePage', {
            id: obj.id,
            resource: 'order',
          }, {
            tab: 'order-detail',
            orderProductList: this.orderProductList,
          })
        },
      },
    ]
  },
}
