<template>
    <div class="product-spec">
        <div class="title">{{data.title}}</div>
        <div class="intro" v-if="data.intro">{{data.intro}}</div>
        <div class="list">
            <div class="item" v-for="(item,index) in data.specs" :key="index">
                <img class="img" :src="item.icon" alt="" />
                <div class="content">
                  <div class="name">{{item.name}}</div>
                  <div class="desc">{{item.desc}}</div>
                  <div class="tags">
                    <div class="tag" v-for="(tag,i) in item.tags" :key="i">
                        {{tag}}
                    </div>
                  </div>
                  <div class="prop-list">
                    <div class="prop-item" v-for="(prop,i) in item.props" :key="i">
                        <div class="prop-name">
                        {{prop.name}}
                        </div>
                        <div class="prop-value">
                        {{prop.value}}
                        </div>
                    </div>
                  </div>
                 <div class="item-down">
                    <img class="item-down-icon" src="@/assets/image/products/product-billing-rules-icon-down.png" alt="" />
                 </div>
                  <div class="price-box">
                    <span class="price">¥{{item.price}}</span>
                    <span class="unit"> /月/GB 起</span>
                  </div>
                  <div class="btn-buy">购买</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import paramsMixin from '@/mixins/paramsMixin'
export default {
  name: 'ProductSpec',
  mixins: [paramsMixin],
  data () {
    return {
      list: [],
    }
  },
}
</script>

<style lang="scss" scoped>
.product-spec{
  display: flex;
  flex-direction: column;
  height: 41.771vw;
  padding: 0 18.75vw;
  background: #FAFAFB;

  .title{
    height: 1.875vw;
    margin-top: 2.5vw;
    color: #222;
    font-weight: 500;
    font-size: 1.563vw;
    line-height: 1.875vw;
    text-align: center;
  }

  .intro{
    height: 1.146vw;
    margin-top: 1.25vw;
    color: #646566;
    font-weight: normal;
    font-size: 0.729vw;
    line-height: 1.146vw;
    text-align: center;
  }

  .list{
    display: flex;
    justify-content: space-between;
    margin-top: 1.875vw;

    .item{
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      width: 15vw;
      height: 30vw;
      padding: 0 0.833vw;
      background: #FFF;

      .img{
        width: 2.5vw;
        height: 2.5vw;
        margin-top: 1.667vw;
      }

      .content{
        display: flex;
        flex-direction: column;
        margin-top: 1.25vw;

        .name{
          height: 1.563vw;
          color: #222;
          font-size: 1.042vw;
          line-height: 1.563vw;
        }

        .desc{
          height: 1.146vw;
          margin-top: 0.417vw;
          color: #646566;
          font-size: 0.729vw;
          line-height: 1.146vw;
        }

        .tags{
            display: flex;
            margin-top: 0.417vw;

            .tag{
                margin-right: 0.208vw;
                padding: 0.208vw 0.417vw;
                color: #222;
                font-size: 0.625vw;
                line-height: 0.938vw;
                background: #E8E8E8;
                border-radius: 0.104vw;
            }
        }

        .prop-list{
            display: flex;
            flex-direction: column;
            margin-top: 1.25vw;

            .prop-item{
                display: flex;
                flex-direction: column;
                height: 2.5vw;
                margin-bottom: 1.25vw;

                .prop-name{
                    color: #646566;
                    font-size: 0.729vw;
                    line-height: 1.146vw;
                }

                .prop-value{
                    margin-top: 0.208vw;
                    color: #222;
                    font-size: 0.729vw;
                    line-height: 1.146vw;
                }

                &:last-child{
                    margin-bottom: 0.417vw;
                }

            }

        }

        .item-down{
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 1.25vw;

            .item-down-icon{
                width: 1.25vw;
                height: 1.25vw;
                color: #646566;
                cursor: pointer;
            }
        }

        .price-box{
            display: flex;
            align-items: baseline;
            margin-bottom: 0.885vw;

            .price{
                height: 1.51vw;
                color: #222;
                font-weight: 600;
                font-size: 1.25vw;
                line-height: 1.875vw;
            }

            .unit{
                height: 0.938vw;
                margin-left: 0.104vw;
                color: #646566;
                font-size: 0.625vw;
                line-height: 0.938vw;
            }
        }

        .btn-buy{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            box-sizing: border-box;
            width: 4.792vw;
            margin-bottom: 1.25vw;
            padding: 0.417vw 0.625vw;
            color: #222;
            font-weight: normal;
            font-size: 0.729vw;
            line-height: 1.146vw;
            text-align: center;
            border: 1px solid #646566;
            border-radius: 1.667vw;
        }

      }
    }
  }
}
</style>
