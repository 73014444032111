<template>
  <detail
    :onManager="onManager"
    :data="data"
    :hiddenBaseInfo="true"
    :extra-info="extraInfo" />
</template>

<script>
import AvailableResourcesInfo from './components/AvailableResourcesInfo'

export default {
  name: 'AvailableResources',
  components: {
    // eslint-disable-next-line vue/no-unused-components
    AvailableResourcesInfo,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    onManager: {
      type: Function,
      required: true,
    },
  },
  data () {
    return {
      configData: {},
      extraInfo: [
        {
          field: 'idps',
          title: '可用资源信息',
          slots: {
            default: ({ row }) => {
              return [<AvailableResourcesInfo data={this.data} row={row} />]
            },
          },
        },
      ],
    }
  },
}
</script>
