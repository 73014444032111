<template>
    <div class="product-reason">
        <div class="title">{{data.title}}</div>
        <div class="list">
            <div class="item" v-for="(item,index) in 4" :key="index">
                  <div class="name">具备高稳定性能，满足200+业务场景需求</div>
                  <ul class="desc">
                    <li class="desc-item">数据持久性高达99.9999999%，保证数据安全可靠，保护您的业务免受故障影响</li>
                    <li class="desc-item">提供极速型SSD、超高IO、通用型SSD、高IO等多种性能规格的云硬盘</li>
                    <li class="desc-item">支持共享云硬盘、云硬盘加密、云硬盘备份、云服务器备份、快照等功能</li>
                  </ul>
            </div>
        </div>
    </div>
</template>

<script>
import paramsMixin from '@/mixins/paramsMixin'
export default {
  name: 'ProductReason',
  mixins: [paramsMixin],
  data () {
    return {
      list: [],
    }
  },
}
</script>

<style lang="scss" scoped>
.product-reason{
  display: flex;
  flex-direction: column;
  height: 29.375vw;
  padding: 0 18.75vw;
  background: #FFF;

  .title{
    height: 1.875vw;
    margin-top: 2.5vw;
    color: #222;
    font-weight: 500;
    font-size: 1.563vw;
    line-height: 1.875vw;
    text-align: center;
  }

  .list{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0.833vw;
    margin-top: 3.125vw;

    .item{
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      height: 9.479vw;
      padding: 1.25vw 0.833vw;
      background: #EEF0F5;

      .name{
        height: 1.146vw;
        color: #3D3D3D;
        font-weight: 500;
        font-size: 0.729vw;
        line-height: 1.146vw;
      }

      .desc{
        margin-top: 0.833vw;
        color: #646566;
        font-weight: normal;
        font-size: 0.729vw;
        line-height: 1.146vw;

        .desc-item{
          display: flex;
          align-items: center;
          margin-bottom: 0.625vw;

          &::before{
            display: inline-block;
            width: 0.208vw;
            height: 0.208vw;
            margin-right: 0.417vw;
            background: #646566;
            border-radius: 50%;
            content: '';
          }

          &:last-child{
            margin-bottom: 0;
          }
        }
      }

    }
  }
}
</style>
