<template>
  <div class="second-price-info">
    <pricing-search
        @providerChange="handleProviderChange"
        @productTypeChange="handleProductTypeChange"
        @nameChange="handleNameChange"
        @chargeTypeChange="handleChargeTypeChange" />
    <vxe-grid
      :data="priceData"
      :columns="priceColumns" />
  </div>
</template>

<script>
import _ from 'lodash'
import WindowsMixin from '@/mixins/windows'
import PricingSearch from './PricingSearch'
export default {
  name: 'SecondPriceInfo',
  components: {
    PricingSearch,
  },
  mixins: [WindowsMixin],
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  data () {
    return {
      details: {
        effect_at: undefined,
      },
      priceData: [],
      priceColumns: [
        {
          title: '名称',
          field: 'product_name',
        },
        {
          title: '类型',
          field: 'product_type_name',
        },
        {
          title: '厂商',
          field: 'provider_name',
        },
        {
          title: '状态',
          field: 'enabled',
          slots: {
            default: ({ row }) => {
              return [<span>{ row.enabled ? '上架' : '下架' } </span>]
            },
          },
        },
        {
          title: '计费方式',
          field: 'charge_type',
          slots: {
            default: ({ row }) => {
              const item = this.getDictDatas(this.DICT_TYPE.CHARGE_TYPE).find(item => item.value === row.charge_type)
              return [<span>{item?.label || '-'}</span>]
            },
          },
        },
        {
          title: '操作',
          field: 'actions',
          slots: {
            default: ({ row }) => {
              return [
                <a-button type="link" onClick={() => this.handleView(row)}>查看</a-button>,
                <a-button type="link" onClick={() => this.handleSinglePricing(row)}>单独定价</a-button>,
              ]
            },
          },
        },
      ],
      tableParams: {},
    }
  },
  created () {
    this.load()
  },
  methods: {
    load () {
      this.$http.get('/v1/product_users', {
        params: { user_id: this.data.id },
      })
        .then((response) => {
          const res = response.data
          // console.log('res=', res)
          const data = res.data
          if (data && data.length > 0) {
            this.details = data[0]
            // 加载产品定价,产品ID
            this.loadProductPrice()
          } else {
            this.details = {
              effect_at: undefined,
            }
          }
        })
    },
    loadProductPrice () {
      const defaultParams = {
        enabled: true,
        product_user_id: this.details.id,
      }

      if (!this.tableParams.product_id) {
        const product_ids = _.flatten(this.details.providers_products.map(item => item.product_ids))
        if (product_ids && product_ids.length > 0) {
          Object.assign(defaultParams, {
            product_id: product_ids,
          })
        }
      }

      if (!this.tableParams.provider) {
        const providers = this.details.providers
        if (providers && providers.length > 0) {
          Object.assign(defaultParams, {
            provider: providers,
          })
        }
      }
      this.$http.get('/v1/product_prices', {
        params: {
          // user_id: this.data.id,
          ...this.tableParams,
          ...defaultParams,
        },
      })
        .then((response) => {
          const res = response.data
          // console.log('res=', res)
          const data = res.data
          if (data && data.length > 0) {
            this.priceData = data
          } else {
            this.priceData = []
          }
        })
    },
    handleView (row) {
      // TODO 查看
      console.log(row)
      this.createDialog('SinglePricingUpdateForCustomerDialog', {
        user: this.data,
        row: row,
        mode: 'view',
      })
    },
    handleSinglePricing (row) {
      // TODO 单独定价
      console.log(row)
      this.createDialog('SinglePricingUpdateForCustomerDialog', {
        user: this.data,
        mode: 'edit',
        row: row,
      })
    },
    handleProviderChange (val) {
      console.log('handleProviderChange(val)', val)
      Object.assign(this.tableParams, { provider: val })
      this.loadProductPrice()
    },
    handleProductTypeChange (val) {
      console.log('handleProductTypeChange(val)', val)
      Object.assign(this.tableParams, { product_type: val })
      this.loadProductPrice()
    },
    handleNameChange (val) {
      console.log('handleNameChange(val)', val)
      Object.assign(this.tableParams, { product_id: val })
      this.loadProductPrice()
    },
    handleChargeTypeChange (val) {
      console.log('handleChargeTypeChange(val)', val)
      Object.assign(this.tableParams, { charge_type: val })
      this.loadProductPrice()
    },
  },
}
</script>

<style lang="scss" scoped>
.second-price-info{
  width: 100%;
  display: flex;
  flex-direction: column;
}
</style>
