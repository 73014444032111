<template>
    <div class="product-function" :class="data.style" :style="{'background': data.bgColor}">
        <div class="title">{{data.title}}</div>
        <div class="intro" v-if="data.intro">{{data.intro}}</div>
        <div class="list">
            <div class="item" v-for="(item,index) in data.list" :key="index">
                <img class="img" :src="item.icon" alt="" />
                <div class="content">
                  <div class="name">{{item.name}}</div>
                  <div class="desc">{{item.desc}}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import paramsMixin from '@/mixins/paramsMixin'
export default {
  name: 'ProductFunction',
  mixins: [paramsMixin],
  data () {
    return {
      list: [],
    }
  },
}
</script>

<style lang="scss" scoped>
.product-function{
  display: flex;
  flex-direction: column;
  padding: 2.5vw 18.75vw;
  background: #FAFAFB;

  .title{
    height: 1.875vw;
    color: #222;
    font-weight: 500;
    font-size: 1.563vw;
    line-height: 1.875vw;
    text-align: center;
  }

  .intro{
    height: 1.146vw;
    margin-top: 1.25vw;
    color: #646566;
    font-weight: normal;
    font-size: 0.729vw;
    line-height: 1.146vw;
    text-align: center;
  }

  .list{
    display: flex;
    flex-direction: column;
    gap: 0.833vw;
    margin-top: 1.875vw;

    .item{
      display: flex;
      width: 100%;
      height: 5.625vw;
      padding: 0.833vw;
      background: #FFF;
      // border: 1px solid #E5E5E5;
      border-radius: 0.208vw;

      .img{
        width: 2.5vw;
        height: 2.5vw;
      }

      .content{
        display: flex;
        flex-direction: column;
        margin-left: 1.25vw;

        .name{
          height: 1.146vw;
          color: #222222;
          font-weight: 500;
          font-size: 0.833vw;
          line-height: 1.146vw;
        }

        .desc{
          height: 2.292vw;
          margin-top: 0.625vw;
          color: #646566;
          font-weight: normal;
          font-size: 0.729vw;
          line-height: 1.146vw;
        }

      }
    }
  }
}
.style1{
  .list{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    flex-direction: row;
    .item{
      width: calc(50% - 0.417vw);
    }
  }
}
</style>
