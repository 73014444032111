<template>
  <detail
    :on-manager="onManager"
    resource="servertemplates"
    status-module="servertemplate"
    :data="data"
    :base-info="baseInfo"
    :extra-info="extraInfo"
    :showName="false"
    :showDesc="false"
    :hiddenKeys="['status', 'created_at', 'updated_at']" />
</template>

<script>
// import * as R from 'ramda'
// import { LOGIN_TYPES_MAP } from '@Compute/constants'
// import { STORAGE_TYPES } from '@/constants/compute'
// import { HYPERVISORS_MAP } from '@/constants'
// import { PRODUCT_TYPES } from '../../../constant'
// import { sizestrWithUnit } from '@/utils/utils'
import {
  getBrandTableColumn,
  getTimeTableColumn,
  // getBillingTypeTableColumn,
  // getCopyWithContentTableColumn,
  // getTagTableColumn,
  // getOsArch,
} from '@/utils/common/tableColumn'
import WindowsMixin from '@/mixins/windows'

export default {
  name: 'ProductPriceDetail',
  mixins: [WindowsMixin],
  props: {
    data: {
      type: Object,
      required: true,
    },
    onManager: {
      type: Function,
      required: true,
    },
  },
  data () {
    return {
      baseInfo: [
        {
          title: '产品名称',
          field: 'product_name',
        },
        {
          field: 'product_type',
          title: '类型',
          formatter: ({ row }) => {
            const item = this.getDictDatas(this.DICT_TYPE.PRODUCT_TYPE).find(item => item.value === row.product_type)
            return [<span>{item?.label || '-'}</span>]
          },
        },
        getBrandTableColumn({
          title: '厂商',
          field: 'provider',
        }),
        {
          field: 'enabled',
          title: '上架状态',
          slots: {
            default: ({ row }) => {
              return [
                <list-body-cell-wrap row={row} field="enabled" resource="products" onManager={ this.onManager } hideField>
                  <span>{ row.enabled ? '上架' : '下架' }</span>
                </list-body-cell-wrap>,
              ]
            },
          },
        },
        {
          field: 'charge_type',
          title: '计费方式',
          formatter: ({ row }) => {
            const item = this.getDictDatas(this.DICT_TYPE.CHARGE_TYPE).find(item => item.value === row.charge_type)
            return [<span>{item?.label || '-'}</span>]
          },
        },
        {
          field: 'coefficient',
          title: '定价',
          // formatter: ({ row }) => {
          //   return [<span>{ '厂商价x' + row.coefficient + '倍' } </span>]
          // },
          slots: {
            default: ({ row }) => {
              return [
                <list-body-cell-wrap row={row} field="coefficient" resource="products" onManager={ this.onManager } hideField>
                  <span>{ '厂商价x' + row.coefficient + '倍' } </span>
                </list-body-cell-wrap>,
              ]
            },
          },
        },
        getTimeTableColumn({
          title: '上架时间',
          field: 'up_shelf_at',
        }),
      ],
      extraInfo: [],
    }
  },
  methods: {
    getI18NValue (key, originVal) {
      if (this.$t(key)) {
        return this.$t(key)
      }
      return originVal
    },
  },
}
</script>
