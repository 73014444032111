<template>
  <detail
    :onManager="onManager"
    :data="data"
    :hiddenBaseInfo="true"
    :extra-info="extraInfo" />
</template>

<script>
// import {
//   getEnabledTableColumn,
// } from '@/utils/common/tableColumn'
// import {
//   getUserTagColumn,
// } from '@/utils/common/detailColumn'
import AvailableCredites from './components/AvailableCredites'
import UserCreditesInfo from './components/UserCreditesInfo'

export default {
  name: 'UserCredites',
  components: {
    // eslint-disable-next-line vue/no-unused-components
    AvailableCredites,
    // eslint-disable-next-line vue/no-unused-components
    UserCreditesInfo,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    onManager: {
      type: Function,
      required: true,
    },
  },
  data () {
    // const driverOptions = Object.keys(this.$t('idpDrivers')).reduce((prev, current) => {
    //   prev[current.toLowerCase()] = current
    //   return prev
    // }, {})
    return {
      configData: {},
      extraInfo: [
        {
          // field: 'idps',
          title: '基本信息',
          slots: {
            default: ({ row }) => {
              return [
                <available-credites data={this.data} />,
              ]
            },
          },
        },
        {
          title: '额度使用明细',
          slots: {
            default: ({ row }) => {
              return [
                <user-credites-info data={this.data} />,
              ]
            },
          },
        },
      ],
    }
  },
}
</script>
