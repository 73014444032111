<template>
  <detail
    :on-manager="onManager"
    resource="servertemplates"
    status-module="servertemplate"
    :data="data"
    :base-info="baseInfo"
    :extra-info="extraInfo"
    :showDesc="false"
    :hiddenKeys="['status']" />
</template>

<script>
// import * as R from 'ramda'
// import { LOGIN_TYPES_MAP } from '@Compute/constants'
// import { STORAGE_TYPES } from '@/constants/compute'
// import { HYPERVISORS_MAP } from '@/constants'
// import { PRODUCT_TYPES } from '../../../constant'
// import { sizestrWithUnit } from '@/utils/utils'
import {
  // getBrandTableColumn,
  getEnabledTableColumn,
  // getBillingTypeTableColumn,
  // getCopyWithContentTableColumn,
  // getTagTableColumn,
  // getOsArch,
} from '@/utils/common/tableColumn'
import WindowsMixin from '@/mixins/windows'

export default {
  name: 'ProductDetail',
  mixins: [WindowsMixin],
  props: {
    data: {
      type: Object,
      required: true,
    },
    onManager: {
      type: Function,
      required: true,
    },
  },
  data () {
    return {
      baseInfo: [
        {
          field: 'product_type',
          title: '类型',
          formatter: ({ row }) => {
            const item = this.getDictDatas(this.DICT_TYPE.PRODUCT_TYPE).find(item => item.value === row.product_type)
            return [<span>{item?.label || '-'}</span>]
          },
        },
        {
          title: '产品编码',
          field: 'code',
        },
        // getBrandTableColumn({
        //   title: '厂商',
        //   field: 'provider',
        // }),
        getEnabledTableColumn(),
      ],
      extraInfo: [],
    }
  },
  methods: {
    getI18NValue (key, originVal) {
      if (this.$t(key)) {
        return this.$t(key)
      }
      return originVal
    },
  },
}
</script>
