import { wikiService } from './request'
// import http from '@/utils/http'

// 菜单列表
export function openPageList (data) {
  return wikiService({
    url: '/zyplayer-doc-wiki/open-api/page/list',
    method: 'POST',
    data: data,
  })
  // return http.post('/v1/zyplayer-doc-wiki/open-api/page/list', data)
}
// 空间详情
export function openSpaceInfo (data) {
  return wikiService({
    url: '/zyplayer-doc-wiki/open-api/space/info',
    method: 'POST',
    data,
  })
  // return http.post('/v1/zyplayer-doc-wiki/open-api/space/info', data)
}
// 详情
export function openPageDetail (data) {
  return wikiService({
    url: '/zyplayer-doc-wiki/open-api/page/detail',
    method: 'POST',
    data,
  })
  // return http.post('/v1/zyplayer-doc-wiki/open-api/page/detail', data)
}
