<template>
    <div class="consult">
        <div class="title">售前咨询</div>
        <div class="intro">产品优惠抢先知，1v1 提供专业上云方案，售前售后多形式服务提供多重保障</div>
        <div class="list">
            <div class="item">
                <img class="img" src="@/assets/image/contactus/contactus-headset.png" alt="" />
                <div class="content">
                  <div class="name">售前在线咨询</div>
                  <div class="desc">专属客户经理在线解答，提供 1v1 购买咨询，解决方案，机型推荐等服务</div>
                  <div class="time">工作日 9:00-18:00</div>
                  <div class="btn" @click="handleConsult">立即咨询</div>
                </div>
            </div>
            <div class="item">
                <img class="img" src="@/assets/image/contactus/contactus-phonecall.png" alt="" />
                <div class="content">
                  <div class="name">售前电话咨询</div>
                  <div class="desc">立即致电客户经理进行购买咨询，或预约技术架构师获取全面的技术解决方案</div>
                  <div class="time">7×24小时</div>
                  <a href="tel:185 0306 6161" class="phone">185 0306 6161</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'Consult',
  methods: {
    handleConsult () {
      this.$message.info('当前咨询人数过多，请通过致电185 0306 6161进行咨询')
    },
  },
}
</script>

<style lang="scss" scoped>
.consult{
  display: flex;
  flex-direction: column;
  height: 23.646vw;
  padding: 0 18.75vw;
  background: #FAFAFB;

  .title{
    height: 1.875vw;
    margin-top: 2.5vw;
    color: #222;
    font-weight: 500;
    font-size: 1.563vw;
    line-height: 1.875vw;
    text-align: center;
  }

  .intro{
    height: 1.146vw;
    margin-top: 1.25vw;
    color: #646566;
    font-weight: normal;
    font-size: 0.729vw;
    line-height: 1.146vw;
    text-align: center;
  }

  .list{
    display: flex;
    justify-content: space-between;
    margin-top: 1.042vw;

    .item{
      width: 30.208vw;
      height: 13.542vw;
      padding: 1.667vw 10.573vw 1.667vw 3.177vw;
      background: #fff;
      border-radius: 0.313vw;

      .img{
        width: 2.083vw;
        height: 2.083vw;
      }

      .content{
        display: flex;
        flex-direction: column;
        margin-top: 0.729vw;

        .name{
          height: 1.25vw;
          color: #3D3D3D;
          font-weight: 500;
          font-size: 0.833vw;
          line-height: 1.25vw;
          letter-spacing: 0;

        }

        .desc{
          height: 2.292vw;
          margin-top: 0.417vw;
          color: #3D3D3D;
          font-weight: normal;
          font-size: 0.729vw;
          line-height: 1.146vw;
        }

        .time{
          margin-top: 0.417vw;
          color: #0066FE;
          font-weight: normal;
          font-size: 0.729vw;
          line-height: 1.146vw;
        }

        .btn{
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: 3.75vw;
          height: 1.458vw;
          margin-top: 0.417vw;
          padding: 0 0.625vw;
          color: #FFF;
          font-weight: normal;
          font-size: 0.625vw;
          line-height: 0.938vw;
          letter-spacing: 0;
          text-align: center;
          background: #0066FE;
          cursor: pointer;
          opacity: 1;
        }

        .phone{
          height: 1.458vw;
          color: #0066FE;
          font-weight: 500;
          font-size: 0.938vw;
          line-height: 1.458vw;
          letter-spacing: 0;
          cursor: pointer;
          opacity: 1;
        }
      }
    }
  }
}
</style>
