<template>
  <base-dialog @cancel="cancelDialog">
    <div slot="header">{{title}}</div>
    <div slot="body">
      <a-form
        :form="form.fc"
        v-bind="formItemLayout">
        <a-row>
          <a-col :span="24">
            <a-form-item label="名称">
              <a-input v-decorator="decorators.name" />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="24">
            <a-form-item label="英文名称">
              <a-input v-decorator="decorators.en" />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="24">
            <a-form-item label="类型">
              <a-input v-decorator="decorators.dict_type" />
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <div slot="footer">
      <a-button type="primary" @click="handleConfirm" :loading="loading">{{ $t('dialog.ok') }}</a-button>
      <a-button @click="cancelDialog">{{ $t('dialog.cancel') }}</a-button>
    </div>
  </base-dialog>
</template>

<script>
import DialogMixin from '@/mixins/dialog'
import WindowsMixin from '@/mixins/windows'

export default {
  name: 'DictTypeUpdateDialog',
  mixins: [DialogMixin, WindowsMixin],
  data () {
    return {
      loading: false,
      form: {
        fc: this.$form.createForm(this),
        fi: {
        },
        fd: {
          id: '',
        },
      },
      decorators: {
        name: [
          'name',
          {
            rules: [
              { required: true, message: '请输入名称' },
            ],
          },
        ],
        en: [
          'en',
          {
            rules: [
              { required: true, message: '请输入英文名称' },
            ],
          },
        ],
        dict_type: [
          'dict_type',
          {
            rules: [
              { required: true, message: '请输入类型' },
            ],
          },
        ],
      },
      formItemLayout: {
        wrapperCol: {
          span: 19,
        },
        labelCol: {
          span: 5,
        },
      },
    }
  },
  computed: {
    title () {
      return this.params.data ? '编辑字典' : '新增字典'
    },
  },
  async created () {
    if (this.params && this.params.data) {
      this.form.fd = this.params.data
      await this.$nextTick()
      this.form.fc.setFieldsValue({
        name: this.form.fd.name,
        en: this.form.fd.en,
        dict_type: this.form.fd.dict_type,
      })
    }
  },
  methods: {
    async saveOrUpdate (data) {
      try {
        if (this.form.fd.id) {
          return new this.$Manager('dict_types').update({ id: this.form.fd.id, data })
        } else {
          return new this.$Manager('dict_types').create({ data })
        }
      } catch (error) {
        throw error
      }
    },
    async handleConfirm () {
      console.log('this.params=', this.params)
      this.loading = true
      try {
        const { ...values } = await this.form.fc.validateFields()
        // values.id = this.params.data.id
        values.cn = values.name
        await this.saveOrUpdate(values)
        this.cancelDialog()
        this.params.list.refresh()
      } finally {
        this.loading = false
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.ant-form-item{
  margin-bottom: 0;
}
</style>
