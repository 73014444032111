<template>
  <div class="home">
    <banner />
    <product-list />
    <product-info />
    <partner />
  </div>
</template>

<script>
import Banner from '@/views/portal/home/components/Banner'
import ProductList from '@/views/portal/home/components/ProductList'
import ProductInfo from '@/views/portal/home/components/ProductInfo'
import Partner from '@/components/Partner'
export default {

  name: 'HomePage',
  components: {
    Banner,
    ProductList,
    ProductInfo,
    Partner,
  },
}
</script>

<style lang="scss" scoped>
.home{
  display: flex;
  flex-direction: column;
  height: 100%;
}
</style>
