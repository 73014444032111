<template>
  <base-dialog @cancel="cancelDialog" :width="1000">
    <div slot="header">配置可用规格{{ titleSuffix }}</div>
    <div slot="body">
      <div class="search-wrap">
        <a-form class="search-form" layout="inline" :colon="false" :form="queryParams">
          <a-row :gutter="24">
            <template v-if="isECS">
              <a-col :span="6">
                <a-form-item class="search-form-item">
                  <a-select placeholder="规格族：全部" v-model="queryParams.instance_type_family" class="search-item"
                    @change="handleInstanceTypeFamilyChange" allowClear show-search option-filter-prop="children" :filter-option="filterOption">
                    <!-- <a-select-option value="">全部</a-select-option> -->
                    <a-select-option v-for="item of queryOpts.instanceTypeFamilyOpts" :key="item.value"
                      :value="item.value">
                      {{ item.label }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :span="6">
                <a-form-item class="search-form-item">
                  <a-select placeholder="实例规格：全部" v-model="queryParams.specs" class="search-item" allowClear show-search option-filter-prop="children" :filter-option="filterOption">
                    <!-- <a-select-option value="">全部</a-select-option> -->
                    <a-select-option v-for="item of queryOpts.instanceSpecsOpts" :key="item.value" :value="item.value">
                      {{ item.label }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
            </template>
            <template v-if="isEBS">
              <a-col :span="6">
                <a-form-item class="search-form-item">
                  <a-select placeholder="规格：全部" v-model="queryParams.disk_type" class="search-item" allowClear show-search option-filter-prop="children" :filter-option="filterOption">
                    <!-- <a-select-option value="">全部</a-select-option> -->
                    <a-select-option v-for="item of queryOpts.skuOpts" :key="item.value" :value="item.value">
                      {{ item.label }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
            </template>
            <template v-if="isEIP">
              <a-col :span="6">
                <a-form-item class="search-form-item">
                  <a-select placeholder="类型：全部" v-model="queryParams.specs" class="search-item" allowClear show-search option-filter-prop="children" :filter-option="filterOption">
                    <!-- <a-select-option value="">全部</a-select-option> -->
                    <a-select-option v-for="item of queryOpts.skuOpts" :key="item.value" :value="item.value">
                      {{ item.label }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
            </template>
            <template v-if="isSLB">
              <a-col :span="6">
                <a-form-item class="search-form-item">
                  <a-select placeholder="规格：全部" v-model="queryParams.specs_type" class="search-item" allowClear show-search option-filter-prop="children" :filter-option="filterOption">
                    <!-- <a-select-option value="">全部</a-select-option> -->
                    <a-select-option v-for="item of queryOpts.skuOpts" :key="item.value" :value="item.value">
                      {{ item.label }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
            </template>
            <template v-if="isNAT">
              <a-col :span="6">
                <a-form-item class="search-form-item">
                  <a-select placeholder="类型：全部" v-model="queryParams.specs" class="search-item" allowClear show-search option-filter-prop="children" :filter-option="filterOption">
                    <!-- <a-select-option value="">全部</a-select-option> -->
                    <a-select-option v-for="item of queryOpts.skuOpts" :key="item.value" :value="item.value">
                      {{ item.label }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
            </template>
            <a-col :span="6">
              <a-form-item class="search-form-item">
                <a-select placeholder="地域：全部" v-model="queryParams.region_id" class="search-item"
                  @change="handleRegionChange" allowClear show-search option-filter-prop="children" :filter-option="filterOption">
                  <!-- <a-select-option value="">全部</a-select-option> -->
                  <a-select-option v-for="item of queryOpts.regionOpts" :key="item.value" :value="item.value">
                    {{ item.label }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="6" v-if="!isNAT">
              <a-form-item class="search-form-item">
                <a-select placeholder="可用区：全部" v-model="queryParams.zone_id" class="search-item" allowClear show-search option-filter-prop="children" :filter-option="filterOption">
                  <!-- <a-select-option value="">全部</a-select-option> -->
                  <a-select-option v-for="item of queryOpts.zoneOpts" :key="item.value" :value="item.value">
                    {{ item.label }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="6" v-if="!isECS">
              <a-form-item class="search-form-item">
                <a-select placeholder="选中：全部" v-model="queryParams.checked" class="search-item" allowClear>
                  <!-- <a-select-option value="">全部</a-select-option> -->
                  <a-select-option :value="'false'">否</a-select-option>
                  <a-select-option :value="'true'">是</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <div class="search-btns" v-if="isNAT">
              <a-form-item>
                <a-button type="primary" @click="onSearch">查询</a-button>
              </a-form-item>
              <a-form-item>
                <a-button @click="onReset">重置</a-button>
              </a-form-item>
            </div>
          </a-row>
          <a-row :gutter="24" v-if="isECS">
            <a-col :span="6">
              <a-form-item class="search-form-item">
                <a-select placeholder="CPU：全部" v-model="queryParams.cpu_core_count" class="search-item" allowClear show-search option-filter-prop="children" :filter-option="filterOption">
                  <!-- <a-select-option value="">全部</a-select-option> -->
                  <a-select-option v-for="item of queryOpts.cpuCoreCountOpts" :key="item.value" :value="item.value">
                    {{ item.label }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <!-- <a-col :span="6">
              <a-form-item class="search-form-item">
                <a-select placeholder="请选择CPU架构" v-model="queryParams.cpu_arch" class="search-item" allowClear>
                  <a-select-option value="">全部</a-select-option>
                  <a-select-option v-for="item of queryOpts.cpuArchOpts" :key="item.value" :value="item.value">
                    {{ item.label }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col> -->
            <a-col :span="6">
              <a-form-item class="search-form-item">
                <a-select placeholder="内存：全部" v-model="queryParams.memory_size_mb" class="search-item" allowClear show-search option-filter-prop="children" :filter-option="filterOption">
                  <!-- <a-select-option value="">全部</a-select-option> -->
                  <a-select-option v-for="item of queryOpts.memorySizeMbOpts" :key="item.value" :value="item.value">
                    {{ item.label }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="6">
              <a-form-item class="search-form-item">
                <a-select placeholder="GPU规格：全部" v-model="queryParams.gpu_spec" class="search-item" allowClear show-search option-filter-prop="children" :filter-option="filterOption">
                  <!-- <a-select-option value="">全部</a-select-option> -->
                  <a-select-option v-for="item of queryOpts.gpuSpecOpts" :key="item.value" :value="item.value">
                    {{ item.label }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="6">
              <a-form-item class="search-form-item">
                <a-select placeholder="GPU核心：全部" v-model="queryParams.gpu_count" class="search-item" allowClear show-search option-filter-prop="children" :filter-option="filterOption">
                  <!-- <a-select-option value="">全部</a-select-option> -->
                  <a-select-option v-for="item of queryOpts.gpuCoreCountOpts" :key="item.value" :value="item.value">
                    {{ item.label }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
          </a-row>
          <a-row :gutter="24">
            <a-col :span="6" v-if="isECS">
              <a-form-item class="search-form-item">
                <a-select placeholder="选中：全部" v-model="queryParams.checked" class="search-item" allowClear>
                  <!-- <a-select-option value="">全部</a-select-option> -->
                  <a-select-option :value="'false'">否</a-select-option>
                  <a-select-option :value="'true'">是</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <div class="search-btns" v-if="!isNAT">
              <a-form-item>
                <a-button type="primary" @click="onSearch">查询</a-button>
              </a-form-item>
              <a-form-item>
                <a-button @click="onReset">重置</a-button>
              </a-form-item>
            </div>
          </a-row>
        </a-form>
      </div>
      <div class="table-wrap">
        <vxe-grid
          ref="gridRef"
          :data="list"
          :columns="columns"
          row-id="id"
          :pager-config="pagerConfig"
          @page-change="handlePageChange"
          :checkbox-config="checkboxConfig"
          @checkbox-change="handleCheckboxChange"
          @checkbox-all="handleCheckboxAllChange" />
      </div>
    </div>
    <div slot="footer">
      <a-button type="primary" @click="handleConfirm" :loading="loading">确认配置</a-button>
      <a-button @click="cancelDialog">{{ $t('dialog.cancel') }}</a-button>
    </div>
  </base-dialog>
</template>

<script>
// import _ from 'lodash'
import DialogMixin from '@/mixins/dialog'
import WindowsMixin from '@/mixins/windows'
// import { STORAGE_TYPES } from '@Compute/constants'

export default {
  name: 'AvailableSpecificationConfigUpdateDialog',
  mixins: [DialogMixin, WindowsMixin],
  data () {
    return {
      queryOpts: {
        instanceTypeFamilyOpts: [],
        instanceSpecsOpts: [],
        regionOpts: [],
        zoneOpts: [],
        cpuCoreCountOpts: [],
        cpuArchOpts: [],
        memorySizeMbOpts: [],
        gpuCoreCountOpts: [],
        gpuSpecOpts: [],
        skuOpts: [],
      },
      queryParams: {
        offset: 0,
        limit: 1000,
        instance_type_family: undefined, // 规格族
        specs: undefined, // 实例规格
        region_id: undefined, // 地域
        zone_id: undefined, // 可用区
        cpu_core_count: undefined, // CPU核数
        cpu_arch: undefined, // CPU架构
        memory_size_mb: undefined, // 内存大小
        gpu_count: undefined, // GPU核数
        gpu_spec: undefined, // GPU规格
        disk_type: undefined, // EBS磁盘规格
        specs_type: undefined, // SLB规格
        checked: undefined,
      },
      addedSet: new Set(),
      removedSet: new Set(),
      loading: false,
      list: [],
      pagerConfig: {
        total: 0,
        currentPage: 1,
        pageSize: 1000,
        pageSizes: [10, 15, 20, 50, 100, 200, 500, 1000],
      },
      productColumns: [],
      extProductColumns: [],
      checkRowKeys: [], // 选中
    }
  },
  computed: {
    titleSuffix () {
      let title = `-${this.params.row.name}`
      const item = this.getDictDatas(this.DICT_TYPE.PROVIDER).find(item => item.value === this.params.row.provider)
      if (item) {
        title = title + '-' + item?.label
      }
      return title
    },
    isECS () {
      return this.params.row.code === 'ecs'
    },
    isEBS () {
      return this.params.row.code === 'disk' || this.params.row.code === 'ebs'
    },
    isEIP () {
      return this.params.row.code === 'eip'
    },
    isSLB () {
      return this.params.row.code === 'slb'
    },
    isNAT () {
      return this.params.row.code === 'nat_gateway'
    },
    columns () {
      return [
        { type: 'checkbox', width: 50 },
        ...this.productColumns, // 各产品的特征字段
        // 地域
        {
          field: 'region',
          title: '地域',
          align: 'center',
          slots: {
            default: ({ row }) => {
              return [<span>{row.region}</span>]
            },
          },
        },
        // 可用区
        ...this.extProductColumns,
      ]
    },
    checkboxConfig () {
      return {
        // 如何解决只能初始化一次的问题，不做跨页保存
        // checkRowKeys: this.checkRowKeys,
        // reserve: true,
      }
    },
  },
  async created () {
    this.setQueryOpts()
    this.setColumns()
    await this.$nextTick()
    this.fetchData()
  },
  methods: {
    fetchData () {
      // 清空已选
      this.addedSet.clear()
      this.removedSet.clear()
      this.$http
        .get(`${this.params.resource}?provider=${this.params.row.provider}`, {
          params: {
            ...this.queryParams,
          },
        })
        .then((response) => {
          const { data, total } = response.data
          // console.log(data)
          if (data && data.length > 0) {
            this.list = data
            this.checkRowKeys = data.filter(item => item.checked).map(item => item.id)
            this.pagerConfig.total = total
            this.$nextTick(() => {
              this.checkRowKeys.forEach(id => {
                const row = this.$refs.gridRef.getRowById(id)
                this.$refs.gridRef.setCheckboxRow(row, true)
              })
            })
          } else {
            this.list = []
            this.pagerConfig.total = 0
          }
        })
    },
    filterOption (input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    },
    handleInstanceTypeFamilyChange (val) {
      this.queryParams.specs = undefined
      if (val) {
        const instanceSpecs = this.params.queryData.specs_familys.find(item => item.instance_type_family === val)?.instance_specs || []
        this.queryOpts.instanceSpecsOpts = instanceSpecs.map(item => {
          return {
            label: item,
            value: item,
          }
        })
      } else {
        this.queryOpts.instanceSpecsOpts = []
      }
    },
    handleRegionChange (val) {
      this.queryParams.zone_id = undefined
      if (val) {
        const areas = this.params.queryData.regions.find(item => item.region_id === val)?.areas || []
        this.queryOpts.zoneOpts = areas.map(item => {
          return {
            label: item.zone_name,
            value: item.zone_id,
          }
        })
      } else {
        this.queryOpts.zoneOpts = []
      }
    },
    onSearch () {
      this.pagerConfig.currentPage = 1
      this.queryParams.offset = 0
      this.fetchData()
    },
    onReset () {
      this.queryParams = {
        offset: 0,
        limit: 1000,
        instance_type_family: undefined, // 规格族
        specs: undefined, // 实例规格
        region_id: undefined, // 地域
        zone_id: undefined, // 可用区
        cpu_core_count: undefined, // CPU核数
        cpu_arch: undefined, // CPU架构
        memory_size_mb: undefined, // 内存大小
        gpu_count: undefined, // GPU核数
        gpu_spec: undefined, // GPU规格
        disk_type: undefined, // EBS磁盘规格
        specs_type: undefined, // SLB规格
        checked: undefined,
      }
      this.pagerConfig.currentPage = 1
      this.pagerConfig.pageSize = 1000
      this.queryOpts.instanceSpecsOpts = []
      this.queryOpts.zoneOpts = []
      this.fetchData()
    },
    handlePageChange ({ type, currentPage, pageSize }) {
      console.log('handlePageChange', type, currentPage, pageSize)
      this.pagerConfig.currentPage = currentPage
      this.pagerConfig.pageSize = pageSize
      this.queryParams.offset = (currentPage - 1) * pageSize
      this.queryParams.limit = pageSize
      this.fetchData()
    },
    handleCheckboxChange ({ row, checked }) {
      console.log(row, checked)
      if (checked) {
        // 判断是否之前本来就是属于勾选的
        if (!this.checkRowKeys.includes(row.id)) {
          this.addedSet.add(row.id)
        } else {
          this.removedSet.delete(row.id)
        }
      } else {
        if (!this.checkRowKeys.includes(row.id)) {
          this.addedSet.delete(row.id)
          return
        }
        // 取消勾选，先询问
        this.$confirm({
          // title: '确认取消勾选?',
          content: '取消配置该规格，可能会影响部分资源规格的上架，确认配置吗？',
          okText: '确认配置',
          onOk: () => {
            // 用户确认取消勾选
            this.removedSet.add(row.id)
          },
          onCancel: () => {
            // 用户取消，保持当前选中状态
            this.$refs.gridRef.setCheckboxRow(row, true)
          },
        })
      }
    },
    handleCheckboxAllChange ({ records, checked }) {
      console.log('全选状态改变', this.$refs.gridRef.getTableData())
      console.log('全选状态:', records, checked)
      if (checked) {
        records.forEach((item) => {
          if (!this.checkRowKeys.includes(item.id)) {
            this.addedSet.add(item.id)
          } else {
            this.removedSet.delete(item.id)
          }
        })
      } else {
        // 获取所有行
        const { tableData } = this.$refs.gridRef.getTableData()

        // 取消勾选，先询问
        this.$confirm({
          // title: '确认取消勾选?',
          content: '取消配置该规格，可能会影响部分资源规格的上架，确认配置吗？',
          okText: '确认配置',
          onOk: () => {
            // 用户确认取消勾选
            this.addedSet.clear()
            tableData.forEach(item => {
              if (this.checkRowKeys.includes(item.id)) {
                this.removedSet.add(item.id)
              }
            })
          },
          onCancel: () => {
            // 用户取消，保持当前选中状态
            this.$refs.gridRef.setAllCheckboxRow(true)
          },
        })
      }
    },
    setQueryOpts () {
      if (this.params.queryData) {
        // 设置初始值
        this.queryOpts.regionOpts = this.params.queryData.regions.map(item => {
          return {
            label: item.region_name,
            value: item.region_id,
          }
        })
        if (this.isECS) {
          this.queryOpts.instanceTypeFamilyOpts = this.params.queryData.specs_familys.map(item => {
            return {
              label: item.name,
              value: item.instance_type_family,
            }
          })
          this.queryOpts.cpuCoreCountOpts = this.params.queryData.cpu_core_counts.map(item => {
            return {
              label: item,
              value: item,
            }
          })
          this.queryOpts.memorySizeMbOpts = this.params.queryData.memory_size_mb.map(item => {
            return {
              label: item.memory + 'MB',
              value: item.memory,
            }
          })
          this.queryOpts.gpuCoreCountOpts = this.params.queryData.gpu_core_counts.map(item => {
            return {
              label: item,
              value: item,
            }
          })
          this.queryOpts.gpuSpecOpts = this.params.queryData.gpu_spec.map(item => {
            return {
              label: item,
              value: item,
            }
          })
        }
        if (this.isEBS) {
          this.queryOpts.skuOpts = this.params.queryData.skus.map(item => {
            return {
              label: item.description,
              value: item.disk_type,
            }
          })
        }
        if (this.isEIP) {
          this.queryOpts.skuOpts = this.params.queryData.skus.map(item => {
            return {
              label: item.line_type,
              value: item.specs,
            }
          })
        }
        if (this.isSLB) {
          this.queryOpts.skuOpts = this.params.queryData.skus.map(item => {
            return {
              label: item.specs_name,
              value: item.specs_type,
            }
          })
        }
        if (this.isNAT) {
          this.queryOpts.skuOpts = this.params.queryData.specs.map(item => {
            return {
              label: item.description,
              value: item.name,
            }
          })
        }
      }
    },
    setColumns () {
      this.resetState()
      const product_code = this.params.row.code
      switch (product_code) {
        case 'ecs':
          this.productColumns = [
            {
              field: 'instance_type_family',
              title: '规格族',
              align: 'center',
              slots: {
                default: ({ row }) => {
                  const label = this.queryOpts.instanceTypeFamilyOpts.find(item => item.value === row.instance_type_family)?.label || '-'
                  return [<span>{label}</span>]
                },
              },
            },
            {
              field: 'specs',
              title: '实例',
            },
          ]
          this.extProductColumns = [
            {
              field: 'zone',
              title: '可用区',
              align: 'center',
              slots: {
                default: ({ row }) => {
                  return [<span>{row.zone}</span>]
                },
              },
            },
            {
              field: 'cpu_core_count',
              align: 'center',
              title: 'vCPU',
              slots: {
                default: ({ row }) => {
                  return [<span>{row.cpu_core_count} vCPU</span>]
                },
              },
            },
            {
              field: 'memory_size_mb',
              align: 'center',
              title: '内存',
              slots: {
                default: ({ row }) => {
                  return [<span>{(+row.memory_size_mb) / 1024} GiB</span>]
                },
              },
            },
            {
              field: 'cpu_arch',
              align: 'center',
              title: '架构',
            },
            {
              field: 'gpu_spec',
              align: 'center',
              title: 'GPU规格',
            },
            {
              field: 'gpu_count',
              align: 'center',
              title: 'GPU核心',
            },
          ]
          break
        case 'ebs':
        case 'disk':
          this.productColumns = [
            {
              field: 'description',
              title: '规格',
              align: 'center',
              // slots: {
              //   default: ({ row }) => {
              //     const types = STORAGE_TYPES
              //     const item = types[this.params.row.provider.toLowerCase()][row.ebs_type]
              //     return [
              //       <span>{item.label}</span>,
              //     ]
              //   },
              // },
            },
          ]
          this.extProductColumns = [
            {
              field: 'zone',
              title: '可用区',
              align: 'center',
              slots: {
                default: ({ row }) => {
                  return [<span>{row.zone}</span>]
                },
              },
            },
          ]
          break
        case 'eip':
          this.productColumns = [
            {
              field: 'line_type',
              title: '线路类型',
              align: 'center',
            },
          ]
          this.extProductColumns = [
            {
              field: 'zone',
              title: '可用区',
              align: 'center',
              slots: {
                default: ({ row }) => {
                  return [<span>{row.zone}</span>]
                },
              },
            },
          ]
          break
        case 'slb':
          this.productColumns = [
            {
              field: 'specs_name',
              title: '实例规格',
              align: 'center',
            },
          ]
          this.extProductColumns = [
            {
              field: 'zone',
              title: '可用区',
              align: 'center',
              slots: {
                default: ({ row }) => {
                  return [<span>{row.zone}</span>]
                },
              },
            },
          ]
          break
        case 'nat_gateway':
          this.productColumns = [
            {
              field: 'description',
              title: '线路类型',
              align: 'center',
            },
          ]
          break
        default:
          break
      }
    },
    resetState () {
      this.productColumns = []
      this.extProductColumns = []
    },
    async update (data) {
      try {
        let url = ''
        const product_code = this.params.row.code
        switch (product_code) {
          case 'ecs':
            url = '/v2/serverskus/ecs-sku-state'
            break
          case 'ebs':
          case 'disk':
            url = '/v3/ebs_sku_on_area_maps/ebs-sku-state'
            break
          case 'eip':
            url = '/v3/eip_sku_on_area_maps/eip-sku-state'
            break
          case 'slb':
            url = '/v3/slb_sku_on_area_maps/slb-sku-state'
            break
          case 'nat_gateway':
            // url = '/v2/nat_sku_on_area_maps/nat-gateway-sku-state'
            url = '/v2/nat_skus/nat-sku-state'
            break
          default:
            break
        }
        return this.$http.post(url, data)
      } catch (error) {
        throw error
      }
    },
    async handleConfirm () {
      this.loading = true
      try {
        console.log('addedSet = ', this.addedSet, 'removedSet = ', this.removedSet)
        const addedValues = this.list.filter(v => this.addedSet.has(v.id)).map(v => {
          return {
            ...v,
            // mg_enabled: true,
            checked: true,
          }
        })
        const removedValues = this.list.filter(v => this.removedSet.has(v.id)).map(v => {
          return {
            ...v,
            // mg_enabled: false,
            checked: false,
          }
        })
        console.log('addedValues = ', addedValues, 'removedValues = ', removedValues)
        const values = [...addedValues, ...removedValues]
        await this.update(values)
        this.$message.success('可用规格配置成功')
        this.fetchData()
        // this.addedSet.clear()
        // this.removedSet.clear()
        // this.cancelDialog()
        // this.params.refresh()
      } finally {
        this.loading = false
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.search-wrap {
  margin-bottom: 10px;
  width: calc(100% - 30px);

  .search-form {
    .search-form-item {
      ::v-deep {
        .ant-form-item-label {
          width: 60px;
        }
      }

      .search-item {
        width: 230px;
      }
    }
    .search-btns{
      display: flex;
      justify-content: flex-end;
      gap: 12px;
    }
  }
}
.table-wrap{
  width: 100%;
}

.ant-form-item {
  margin-bottom: 0;
  margin-right: 0;
}

.checkbox-item {
  width: 96%;
  margin-left: 10px;

  .checkbox-item-content {
    width: 100%;
    overflow: hidden;
    /*超出隐藏*/
    text-overflow: ellipsis;
    /*隐藏后添加省略号*/
    white-space: nowrap;
    /*强制不换行*/
  }
}
</style>
