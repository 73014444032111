<template>
  <div class="server-password">
    <a-form-item class="mb-0">
      <a-radio-group :disabled="disabled" v-decorator="decorators.loginType" @change="loginTypeChange">
        <a-radio-button v-for="item of loginTypeMap" :value="item.key" :key="item.key">
          {{ item.label }}
          <help-tooltip v-if="['image', 'keypair'].includes(item.key)" :name="`${item.key}Password`" class="ml-2" />
        </a-radio-button>
      </a-radio-group>
    </a-form-item>
    <a-form-item v-if="(loginTypeMap && loginTypeMap.keypair) && vmLoginType === loginTypeMap.keypair.key" class="mb-0">
      <template #extra>
        {{$t('compute.text_201')}}<help-link :href="href">{{$t('compute.text_202')}}</help-link>
      </template>
      <base-select
        class="w-50"
        v-decorator="decorators.keypair"
        resource="keypairs"
        :isDefaultSelect="true"
        :showSync="true"
        :select-props="{ allowClear: true, placeholder: $t('compute.text_203') }" />
    </a-form-item>
    <a-form-item v-if="(loginTypeMap && loginTypeMap.password) && vmLoginType === loginTypeMap.password.key" class="mb-0">
      <a-input-password
        class="w-50"
        v-decorator="decorators.password"
        :placeholder="$t('compute.text_204')" />
    </a-form-item>
  </div>
</template>

<script>
import * as R from 'ramda'
import _ from 'lodash'
import { LOGIN_TYPES_MAP } from '@Compute/constants'
import { passwordValidator } from '@/utils/validate'
import i18n from '@/locales'
import { HYPERVISORS_MAP } from '@/constants'

const mgecloudPasswordValidator = (rule, value, callback) => {
  value = value.trim()
  let isLenOK = true
  // 密码长度校验
  if (value.length < 8 || value.length > 16) {
    isLenOK = false
  }

  // 校验是否包含数字、大小写字母和特殊字符
  const hasNumber = /\d/.test(value)
  const hasLowerCase = /[a-z]/.test(value)
  const hasUpperCase = /[A-Z]/.test(value)
  const hasSpecialChar = /[~@#$%*_+\-=,.?[\]{}]/.test(value)

  let isSpecialCharOK = true
  // 校验特殊字符的数量
  const specialChars = value.match(/[~@#$%*_+\-=,.?[\]{}]/g) || []
  if (specialChars.length > 3) {
    isSpecialCharOK = false
  }

  let hasNoRepeat = true
  // 校验是否有三个及以上连续或重复的数字
  if (/(\d)\d*(?=\d{2})/.test(value)) {
    hasNoRepeat = false
  }

  if (!isLenOK || !hasNumber || !hasLowerCase || !hasUpperCase || !hasSpecialChar || !isSpecialCharOK || !hasNoRepeat) {
    callback(new Error('8-16位，同时包括数字、大小写字母和特殊字符，特殊字符且需要在“~ @ # $ % * _ - + = , . ? [ ] { }”范围内，特殊字符最多不能超过3个，不可出现3个及以上连续或重复的数字'))
  }

  callback() // 验证通过
}

const DEFAULT_DECORATOR = {
  password: [
    'password',
    {
      validateFirst: true,
      rules: [
        { required: true, message: i18n.t('compute.text_204') },
        { validator: passwordValidator },
      ],
    },
  ],
}

export default {
  name: 'ServerPassword',
  props: {
    loginTypes: {
      type: Array,
    },
    decorator: {
      type: Object,
      required: true,
      // validator: val => R.is(Array, val.loginType) && R.is(Array, val.keypair) && R.is(Array, val.password),
    },
    form: {
      type: Object,
    },
    isSnapshotImageType: { // 表单的镜像类型是否是主机快照
      type: Boolean,
      default: false,
    },
    hypervisor: {
      type: String,
      default: '',
    },
  },
  data () {
    return {
      vmLoginType: 'random',
      disabled: false,
    }
  },
  computed: {
    loginTypeMap () {
      if (this.loginTypes && this.loginTypes.length > 0) {
        const _ = {}
        for (const k in LOGIN_TYPES_MAP) {
          if (this.loginTypes.includes(k)) {
            _[k] = LOGIN_TYPES_MAP[k]
          }
        }
        return _
      }
      return LOGIN_TYPES_MAP
    },
    decorators () {
      const decorator = _.cloneDeep(this.decorator)
      switch (this.hypervisor) {
        case HYPERVISORS_MAP.mgecloud.key:
          decorator.password[1].rules[1].validator = mgecloudPasswordValidator
          break
        default:
          break
      }
      return {
        ...DEFAULT_DECORATOR,
        ...decorator,
      }
    },
    href () {
      const url = this.$router.resolve('/keypair')
      return url.href
    },
  },
  watch: {
    isSnapshotImageType (val) {
      if (val) {
        this.disabled = true
        const v = LOGIN_TYPES_MAP.image.key
        this.form.fc.setFieldsValue({
          [this.decorators.loginType[0]]: v,
        })
        this.vmLoginType = v
      } else {
        this.disabled = false
      }
    },
    loginTypeMap (val, oldv) {
      if (!R.equals(val, oldv)) {
        this.setLoginType()
      }
    },
  },
  mounted () {
    this.setLoginType()
  },
  methods: {
    loginTypeChange (e) {
      this.vmLoginType = e.target.value
    },
    setLoginType () {
      if (this.loginTypeMap && !R.isEmpty(this.loginTypeMap)) {
        const loginTypeInitailValue = this.decorator.loginType[1].initialValue
        const keys = Object.keys(this.loginTypeMap)
        let vmLoginType = loginTypeInitailValue
        if (!keys.includes(loginTypeInitailValue) || this.isSnapshotImageType) { // 如果表单中的初始值不在 loginTypeMap 中, 主机快照只支持保留镜像设置
          if (keys.includes(LOGIN_TYPES_MAP.image.key)) { // 如果maps中有"保留镜像设置"，则设置
            vmLoginType = LOGIN_TYPES_MAP.image.key
          } else { // 否则设置第一项
            vmLoginType = keys[0]
          }
        }
        if (this.form && this.form.fc) {
          this.form.fc.setFieldsValue({
            [this.decorators.loginType[0]]: vmLoginType,
          })
        }
        this.vmLoginType = vmLoginType
      }
    },
  },
}
</script>
