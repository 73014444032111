<template>
  <div class="available-credites">
    <h2 class="title">可用额度</h2>
    <div class="credites-box">
      <div class="credites">
        <span class="money">{{details.balance_amount | numerify('0,0.0000')}}</span>
        <span class="unit">元</span>
      </div>
      <a-button type="primary" class="btn-adjust" @click="handleAdjust">调整额度</a-button>
    </div>
    <div class="triangle-box">
      <div class="triangle" />
    </div>
    <div class="bottom-box">
      <div class="credites-item">
        <div class="label">授信额度</div>
        <div class="value">{{details.amount | numerify('0,0.0000')}}元</div>
      </div>
      <div class="credites-item">
        <div class="operate">+</div>
      </div>
      <div class="credites-item">
        <div class="label">赠送金额度</div>
        <div class="value">{{0 | numerify('0,0.0000')}}元</div>
      </div>
      <div class="credites-item">
        <div class="operate">-</div>
      </div>
      <div class="credites-item">
        <div class="label red">已使用额度</div>
        <div class="value red">{{details.use_amount | numerify('0,0.0000')}}元</div>
      </div>
      <div class="credites-item">
         <div class="operate">-</div>
      </div>
      <div class="credites-item">
        <div class="label">冻结额度</div>
        <div class="value">{{details.frozen_amount | numerify('0,0.0000')}}元</div>
      </div>
    </div>
  </div>
</template>

<script>
import WindowsMixin from '@/mixins/windows'
// import { numerify } from '@/filters'
export default {
  name: 'AvailableCredites',
  mixins: [WindowsMixin],
  props: {
    data: {
      type: Object,
      required: true,
    },
    onManager: {
      type: Function,
      required: true,
    },
  },
  data () {
    return {
      isNew: false,
      details: {
        use_amount: 0,
        amount: 0,
        balance_amount: 0,
        frozen_amount: 0,
      },
    }
  },
  watch: {
    data: {
      immediate: true,
      deep: true,
      handler (newValue, oldValue) {
        this.load()
      },
    },
  },
  methods: {
    load () {
      console.log('avalibale cre', this.data)
      this.$http.get('/v1/user_credits', {
        params: { user_id: this.data.id },
      })
        .then((response) => {
          console.log(response)
          const res = response.data
          const data = res.data
          if (data && data.length > 0) {
            this.isNew = false
            this.details = data.find(item => item.user_id === this.data.id)
          } else {
            this.isNew = true
            this.details = {
              amount: 0,
              balance_amount: 0,
              use_amount: 0,
              frozen_amount: 0,
            }
          }
        })
    },
    handleAdjust () {
      this.createDialog('UserCreditesUpdateForCustomerDialog', {
        isNew: this.isNew,
        user: this.data,
        details: this.details,
        refresh: this.load,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.available-credites{
  display: flex;
  flex-direction: column;
  border: 1px solid #e5e7e9;
  .title{
    font-size: 16px;
    margin-top: 20px;
    font-weight: 600;
    padding: 0 20px;
  }
  .credites-box{
    display: flex;
    margin-top: 20px;
    padding: 0 20px;
    .credites{
      display: flex;
      align-items: baseline;
      .money{
        font-size: 24px;
        font-weight: 600;
      }
      .unit{
        font-size: 16px;
      }
    }
    .btn-adjust{
      margin-left: 24px;
    }
  }
  .triangle-box{
    padding-left: 40px;
    .triangle {
      width: 0;
      height: 0;
      border-left: 15px solid transparent;
      border-right: 15px solid transparent;
      border-bottom: 20px solid #f5f5f5;
    }
  }
  .bottom-box{
    display: flex;
    background: #f5f5f5;
    padding: 10px 20px;
    .credites-item{
      flex: 1;
      display: flex;
      flex-direction: column;
      .label{
        font-size: 14px;
        color: #6c757d;
      }
      .value{
        font-size: 16px;
        font-weight: 600;
        margin-top: 10px;
      }
      .operate{
        height: 100%;
        display: flex;
        align-items: center;
      }
      .red{
        color: #f3253c;
      }
    }
  }
}
</style>
