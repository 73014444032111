<template>
    <div class="product-feature">
        <div class="title">{{data.title}}</div>
        <div class="intro" v-if="data.intro">{{data.intro}}</div>
        <div class="list">
            <div class="item" v-for="(item,index) in 3" :key="index">
                <img class="img" src="@/assets/image/products/product-feature-icon.png" alt="" />
                <div class="content">
                  <div class="name">按量计费，灵活方便</div>
                  <div class="desc">按照实际使用量和使用时长计费，无需支付前期成本，支持随时购买随时释放，可根据业务情况灵活调整配置</div>
                  <a href="#" class="more">了解更多 <a-icon type="right" /></a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import paramsMixin from '@/mixins/paramsMixin'
export default {
  name: 'ProductFeature',
  mixins: [paramsMixin],
  data () {
    return {
      list: [],
    }
  },
}
</script>

<style lang="scss" scoped>
.product-feature{
  display: flex;
  flex-direction: column;
  height: 23.958vw;
  padding: 0 18.75vw;
  background: #FFF;

  .title{
    height: 1.875vw;
    margin-top: 2.5vw;
    color: #222;
    font-weight: 500;
    font-size: 1.563vw;
    line-height: 1.875vw;
    text-align: center;
  }

  .intro{
    height: 1.146vw;
    margin-top: 1.25vw;
    color: #646566;
    font-weight: normal;
    font-size: 0.729vw;
    line-height: 1.146vw;
    text-align: center;
  }

  .list{
    display: flex;
    gap: 0.833vw;
    justify-content: space-between;
    margin-top: 1.875vw;

    .item{
      display: flex;
      flex: 1;
      flex-direction: column;
      align-items: center;
      height: 12.812vw;
      padding: 0 1.042vw;
      background: #FFF;
      border: 1px solid #DFE1E6;

      &:hover{
        border: 1px solid #FFF;
        box-shadow: 0 0.625vw 1.875vw 0 rgba(0, 0, 0, 0.1);
      }

      .img{
        width: 2.5vw;
        height: 2.5vw;
        margin-top: 1.458vw;
      }

      .content{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 0.833vw;

        .name{
          height: 1.146vw;
          color: #3D3D3D;
          font-weight: 500;
          font-size: 0.729vw;
          line-height: 1.146vw;
        }

        .desc{
          height: 2.292vw;
          margin-top: 0.417vw;
          color: #646566;
          font-weight: normal;
          font-size: 0.729vw;
          line-height: 1.146vw;
        }

        .more{
          margin-top: 1.458vw;
          color: #646566;
          font-weight: normal;
          font-size: 0.625vw;
          line-height: 0.938vw;

          &:hover{
            color: #0066FE;
          }
        }
      }
    }
  }
}
</style>
