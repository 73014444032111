import cookies from './util.cookies'
import http from '@/utils/http'
// const { VUE_APP_PUBLIC_PATH } = process.env
import { TOKEN_KEY } from '@/maps/common'

const util = {
  cookies,
  // log,
  // browser,
  // dataType
}

/**
 * @description 更新标题
 * @param {String} title 标题
 */
util.title = function (titleText) {
  const processTitle = process.env.VUE_APP_TITLE || ''
  window.document.title = `${processTitle}${titleText ? ` | ${titleText}` : ''}`
}

/**
 * @description 获取当前浏览器存储的token
 */
util.getToken = async () => {
  const token = util.cookies.get(TOKEN_KEY)
  return token
}

// 获取字典数据
util.getDictData = async (dictType, ...params) => {
  const response = await http({
    method: 'GET',
    url: '/v1/dict_datas',
    params: {
      scope: 'system',
      show_fail_reason: true,
      details: false,
      limit: 10000,
      enabled: true,
      dict_type: dictType,
      ...params,
    },
  })

  let result = []
  const res = response.data
  if (res.data && res.data.length > 0) {
    result = res.data.sort((a, b) => a.priority - b.priority)
    // console.log('data=', res.data)
  }
  return result
}

export default util
