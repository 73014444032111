<template>
  <base-dialog @cancel="cancelDialog" :width="1512">
    <div slot="header" class="header-tittle">配置可用区域</div>
    <div slot="body">
      <a-form v-bind="formItemLayout">
        <a-row>
          <a-col :span="10">
            <a-form-item label="厂商">
              <span>{{ BRAND_MAP[params.row.provider]?.label || '-' }}</span>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="类型">
              <span>{{
                this.getDictDataLabel(
                  this.DICT_TYPE.PRODUCT_TYPE,
                  params.row.product_type
                )
              }}</span>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="10">
            <a-form-item label="资源">
              <span>{{ params.row.name }}</span>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
      <div class="content">
        <div class="left">
          <div class="city-list">
            <div
              class="city-item"
              v-for="city in cityList"
              :key="city.value"
              @click="handleCityClick(city)">
              <div class="city-item-left" :title="city.label">
                <span class="city-item-title">{{ city.label }}</span>
              </div>
              <a-icon
                type="plus"
                class="city-item-add"
                @click="handleAddCityRegion(city)" />
            </div>
          </div>
          <div class="region-list">
            <div class="region-item" v-for="item in regionList" :key="item.id">
              <div
                class="region-item-left"
                :title="`${item.mapping_name}（${item.provider_name}（${item.region_name}）-${item.zone_name}）`">
                <span class="region-item-title">{{ item.mapping_name }}</span>
                <span class="region-item-desc">（{{ item.provider_name }}（{{ item.region_name }}）-{{
                    item.zone_name
                  }}）</span>
              </div>
              <a-icon
                type="plus"
                class="region-item-add"
                @click="handleAddRegion(item)" />
            </div>
          </div>
        </div>
        <div class="right">
          <div class="region-title">可用区域</div>
          <div class="region-list">
            <div
              class="region-item"
              v-for="item in availableRegionList"
              :key="item.id">
              <div
                class="region-item-left"
                :title="`${item.mapping_name}（${item.provider_name}（${item.region_name}）-${item.zone_name}）`">
                <span class="region-item-title">{{ item.mapping_name }}</span>
                <span class="region-item-desc">（{{ item.provider_name }}（{{ item.region_name }}）-{{
                    item.zone_name
                  }}）</span>
              </div>
              <a-icon
                type="close"
                class="region-item-remove"
                @click="handleRemoveRegion(item)" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div slot="footer">
      <a-button type="primary" @click="handleConfirm" :loading="loading">确认可用区域</a-button>
      <a-button @click="cancelDialog">{{ $t("dialog.cancel") }}</a-button>
    </div>
  </base-dialog>
</template>

<script>
import _ from 'lodash'
import DialogMixin from '@/mixins/dialog'
import WindowsMixin from '@/mixins/windows'
import { BRAND_MAP } from '@/constants'

export default {
  name: 'AvailableRegionAllConfigUpdateForProductDialog',
  mixins: [DialogMixin, WindowsMixin],
  data () {
    return {
      treeData: [],
      cityList: [],
      regionList: [],
      allRegionList: [],
      availableRegionList: [],
      loading: false,
      form: {
        fc: this.$form.createForm(this),
        fi: {
          cloudaccount: {},
          project: {},
        },
      },
      formItemLayout: {
        wrapperCol: {
          span: 17,
        },
        labelCol: {
          span: 6,
        },
      },
      BRAND_MAP,
    }
  },
  created () {
    this.loadAreaMappingsTree()
  },
  methods: {
    loadAreaMappingsTree () {
      const provider = this.params.row.provider
      this.$http
        .get(`/v2/area_mappings/tree?provider=${provider}`)
        .then((response) => {
          const data = response.data
          if (data && data.length > 0) {
            this.treeData = data
            this.allRegionList = data.flatMap(item => item.regions)
            this.cityList = data.map((item) => ({
              label: item.city_name,
              value: item.city_name,
            }))
            this.loadProductUserAreas()
          }
        })
    },
    loadProductUserAreas () {
      this.availableRegionList = this.allRegionList.filter(item => this.params.row.area_ids?.includes(item.id.toString()))
    },
    handleCityClick (city) {
      this.regionList =
        this.treeData.find((item) => item.city_name === city.label)?.regions ||
        []
    },
    // 将该城市下的所有可用区添加到可用区域列表中
    handleAddCityRegion (city) {
      this.handleCityClick(city)
      const oldList = [...this.availableRegionList].concat([
        ...this.regionList,
      ])
      this.availableRegionList = _.uniqBy(oldList, 'id')
    },
    // 将该可用区域（单个）添加到可用区域列表中
    handleAddRegion (item) {
      const oldList = [...this.availableRegionList].concat([item])
      this.availableRegionList = _.uniqBy(oldList, 'id')
    },
    // 将该可用区域（单个）从可用区域列表中移除
    handleRemoveRegion (item) {
      const oldList = [...this.availableRegionList]
      const index = oldList.findIndex((i) => i.id === item.id)
      if (index > -1) {
        oldList.splice(index, 1)
        this.availableRegionList = oldList
      }
    },
    // 修改与校验可用区接口：verify true校验，false提交
    productsPut (verify) {
      return this.$http
        .post('/v1/products/regional-config', {
          verify,
          product_id: this.params.row.id,
          area_id: this.availableRegionList.map((item) => item.id.toString()),
        })
    },
    async handleConfirm () {
      this.loading = true
      const result = await this.productsPut(true)
      this.loading = false
      if (result.status !== 200) return
      // disk404表示可用区有已上架资源
      if (result.data.code === 'disk404') {
        const _self = this
        this.$confirm({
          title: '提示',
          content: `${result.data.msg}可用区有已上架资源，确认删除该可用区吗`,
          okText: '确定',
          okType: 'danger',
          cancelText: '取消',
          // 二次确认
          async onOk () {
            await _self.productsPut(false)
            _self.$message.success('操作成功')
            _self.cancelDialog()
            _self.params.refresh.fetchData()
          },
          // 取消时还原参数
          onCancel () {
            _self.loadAreaMappingsTree()
          },
        })
      } else {
        await this.productsPut(false)
        this.$message.success('操作成功')
        this.params.refresh.fetchData()
        this.cancelDialog()
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.header-tittle,.ant-form-item,.ant-form ::v-deep label {
  font-size: 24px;
  line-height: 36px;
  height: 36px;
  color: #333333;
  user-select: none;
}
.ant-form-item {
  margin-bottom: 0;
}
.content {
  display: flex;
  gap: 44px;
  padding: 10px;
  .left {
    display: flex;
    width: 789px;
    height: 607px;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.16);
    border-radius: 8px;
    .city-list {
      width: 202px;
      padding: 24px 12px;
      border-right: 1px solid #d8d8d8;
      .city-item {
        padding: 0 12px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .city-item-left {
          width: 100%;
          overflow: hidden; /*超出隐藏*/
          text-overflow: ellipsis; /*隐藏后添加省略号*/
          white-space: nowrap; /*强制不换行*/
          .city-item-title {
            font-size: 24px;
            line-height: 36px;
            height: 36px;
            color: #333333;
            user-select: none;
          }
        }
        .city-item-add {
          font-size: 24px;
          display: none;
        }
        &:hover {
          cursor: pointer;
          background: #e2e2e2;
          border-radius: 8px;
          .city-item-add {
            display: block;
          }
        }
      }
    }
  }
  .right {
    width: 604px;
    height: 607px;
    overflow: auto;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.16);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    .region-title {
      font-size: 28px;
      font-weight: 500;
      padding: 24px;
      color: #333333;
      border-bottom: 1px solid #d8d8d8;
      user-select: none;
    }
  }
  .region-list {
    width: 587px;
    height: 607px;
    display: flex;
    flex-direction: column;
    padding: 24px 12px;
    .region-item {
      padding: 0 12px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .region-item-left {
        width: 100%;
        overflow: hidden; /*超出隐藏*/
        text-overflow: ellipsis; /*隐藏后添加省略号*/
        white-space: nowrap; /*强制不换行*/
        // display: flex;
        .region-item-title {
          font-size: 24px;
          line-height: 36px;
          font-weight: 500;
          height: 36px;
          color: #333333;
          user-select: none;
        }
        .region-item-desc {
          font-size: 20px;
          line-height: 36px;
          font-weight: 400;
          height: 36px;
          color: #999;
        }
      }
      .region-item-add {
        font-size: 24px;
        display: none;
      }
      .region-item-remove {
        font-size: 24px;
        display: none;
      }
      &:hover {
        cursor: pointer;
        background: #e2e2e2;
        border-radius: 8px;
        .region-item-add {
          display: block;
        }
        .region-item-remove {
          display: block;
        }
      }
    }
  }
}
</style>
