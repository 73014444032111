<template>
    <div class="contact-us">
      <banner />
      <consult />
      <after-sales v-if="false" />
    </div>
</template>

<script>
import Banner from '@/views/portal/contactus/components/Banner'
import Consult from '@/views/portal/contactus/components/Consult'
import AfterSales from '@/views/portal/contactus/components/AfterSales'
export default {
  name: 'ContactUs',
  components: {
    Banner,
    Consult,
    AfterSales,
  },
}
</script>

<style lang="scss" scoped>

</style>
