<template>
  <div class="navigation">
    <div ref="navigationRef" style="display: inline-block; width: 100%" />
    <div class="navigation-heading" :style="{ width: navigationWidth }">
      <div
        v-for="(item, index) in heading"
        :class="'heading-item heading-' + item.level"
        @click="headingItemClick(item)"
        :key="index">
        {{ item.text }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    heading: {
      type: Array,
      default: () => [],
    },
  },
  data () {
    return {
      navigationWidth: '100px',
      navigationRef: null,
    }
  },
  mounted () {
    window.onresize = () => {
      this.computeNavigationWidth()
    }
    setTimeout(() => this.computeNavigationWidth(), 100)
  },
  methods: {
    computeNavigationWidth () {
      this.navigationWidth = window.getComputedStyle(this.$refs.navigationRef).width
    },
    headingItemClick (item) {
      item.node.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
      })
    },
  },
}
</script>

<style scoped>
.navigation {
  width: 100%;
}

.navigation-heading {
  position: fixed;
  top: 7.813vw;
  z-index: 4;
  box-sizing: border-box;
  width: 100%;
  max-height: calc(100vh - 13.021vw);
  padding-left: 0.833vw;
  overflow-y: auto;
}

.navigation-heading .heading-item {
  padding: 0.26vw 0;
  overflow: hidden;
  color: #646a73;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
}

.navigation-heading .heading-item:hover {
  color: #3370ff;
}

.navigation-heading .heading-1 {
  padding-left: 0;
}

.navigation-heading .heading-2 {
  padding-left: 0.833vw;
}

.navigation-heading .heading-3 {
  padding-left: 1.667vw;
}

.navigation-heading .heading-4 {
  padding-left: 2.5vw;
}

.navigation-heading .heading-5 {
  padding-left: 3.333vw;
}

.navigation-heading .heading-6 {
  padding-left: 4.167vw;
}
</style>
