<template>
  <div class="home">
    <banner />
    <product-system />
    <product-support />
    <product-advantage />
    <partner />
  </div>
</template>

<script>
import Partner from '@/components/Partner'
import Banner from '@/views/portal/about/components/Banner'
import ProductAdvantage from '@/views/portal/about/components/ProductAdvantage'
import ProductSystem from '@/views/portal/about/components/ProductSystem'
import ProductSupport from '@/views/portal/about/components/ProductSupport'
export default {
  name: 'About',
  components: {
    Banner,
    ProductAdvantage,
    ProductSystem,
    ProductSupport,
    Partner,
  },
}
</script>

<style lang="scss" scoped>
.home{
  display: flex;
  flex-direction: column;
  height: 100%;
}
</style>
