<template>
    <div class="documents">
      <!-- <DetailIframe :pageId="pageId" :space="space"/> -->
      <doc-detail :space="space" />
      <!-- <DocDetailIframe :space="space"/> -->
    </div>
</template>

<script>
// import DetailIframe from '@/views/documents/components/DetailIframe';
import DocDetail from '@/views/portal/documents/components/DocDetail'
// import DocDetailIframe from '@/views/documents/components/DocDetailIframe';
export default {
  name: 'Documents',
  components: {
    // DetailIframe, // 左侧分类树
    DocDetail, // 图片、文件显示问题
    // DocDetailIframe,
  },
  data () {
    return {
      pageId: '',
      space: '',
    }
  },
  watch: {
    $route (newValue, oldValue) {
      this.pageId = newValue.query.pageId + ''
      this.space = newValue.query.space
    },
  },
  created () {
    this.pageId = this.$route.query.pageId + ''
    this.space = this.$route.query.space
  },
}
</script>

<style lang="scss" scoped>

</style>
