import { getEnabledSwitchActions } from '@/utils/common/tableActions'
import i18n from '@/locales'

export default {
  created () {
    this.singleActions = [
      // {
      //   label: '查看',
      //   action: row => {
      //     this.handleOpenSidepage(row)
      //   },
      // },
      {
        label: i18n.t('system.text_479'),
        permission: 'customers_update',
        action: row => {
          this.createDialog('CustomerUpdateDialog', {
            data: [row],
            columns: this.columns,
            onManager: this.onManager,
          })
        },
      },
      {
        label: i18n.t('system.text_153'),
        actions: row => {
          return [
            {
              label: '资源配置',
              action: row => {
                this.handleOpenSidepage(row, 'available-resources')
              },
            },
            {
              label: '授信额度',
              action: () => {
                this.handleOpenSidepage(row, 'user-credites')
              },
            },
            {
              label: '二次定价',
              action: () => {
                this.handleOpenSidepage(row, 'second-price')
              },
            },
            ...getEnabledSwitchActions(this, row, ['customers_perform_enable', 'customers_perform_disable'], {
              actions: [
                () => {
                  this.onManager('performAction', {
                    id: row.id,
                    managerArgs: {
                      action: 'enable',
                    },
                  })
                },
                () => {
                  this.onManager('performAction', {
                    id: row.id,
                    managerArgs: {
                      action: 'disable',
                    },
                  })
                },
              ],
            }),
            // {
            //   label: i18n.t('compute.text_276'),
            //   permission: 'customers_update',
            //   action: () => {
            //     this.createDialog('UserUpdatePasswordDialog', {
            //       data: [row],
            //       columns: this.columns,
            //       onManager: this.onManager,
            //     })
            //   },
            //   meta: () => {
            //     const { idps } = row
            //     if (idps && idps.length > 0) {
            //       const [{ idp_driver }] = idps
            //       if (idp_driver === 'ldap') {
            //         return {
            //           validate: false,
            //           tooltip: this.$t('common_603'),
            //         }
            //       }
            //     }
            //     if (!row.is_local) return { validate: false }
            //     return {
            //       validate: true,
            //     }
            //   },
            // },
            // {
            //   label: i18n.t('common_493'),
            //   action: () => {
            //     this.handleOpenSidepage(row, 'projects')
            //   },
            // },
            // {
            //   label: `${i18n.t('common.reset')}MFA`,
            //   action: () => {
            //     this.createDialog('ResetMFADialog', {
            //       data: [row],
            //       columns: this.columns,
            //       onManager: this.onManager,
            //     })
            //   },
            // },
            // {
            //   label: i18n.t('system.text_129'),
            //   permission: 'customers_delete',
            //   action: () => {
            //     this.createDialog('DeleteResDialog', {
            //       vm: this,
            //       data: [row],
            //       columns: this.columns,
            //       title: i18n.t('system.text_480'),
            //       name: this.$t('dictionary.user'),
            //       onManager: this.onManager,
            //       alert: {
            //         message: i18n.t('system.text_513'),
            //         type: 'error',
            //       },
            //       refresh: this.refresh,
            //     })
            //   },
            //   meta: () => this.$getDeleteResult(row),
            // },
          ]
        },
      },
    ]
  },
}
