<template>
  <detail
    :onManager="onManager"
    :data="data"
    :hiddenBaseInfo="true"
    :extra-info="extraInfo" />
</template>

<script>
// import {
//   getEnabledTableColumn,
// } from '@/utils/common/tableColumn'
// import {
//   getUserTagColumn,
// } from '@/utils/common/detailColumn'
import WindowsMixin from '@/mixins/windows'
import PricingMode from './components/PricingMode'
import PricingSearch from './components/PricingSearch'
import SecondPriceInfo from './components/SecondPriceInfo'

export default {
  name: 'SecondPrice',
  components: {
    // eslint-disable-next-line vue/no-unused-components
    PricingMode,
    // eslint-disable-next-line vue/no-unused-components
    PricingSearch,
    // eslint-disable-next-line vue/no-unused-components
    SecondPriceInfo,
  },
  mixins: [WindowsMixin],
  props: {
    data: {
      type: Object,
      required: true,
    },
    onManager: {
      type: Function,
      required: true,
    },
  },
  data () {
    // const driverOptions = Object.keys(this.$t('idpDrivers')).reduce((prev, current) => {
    //   prev[current.toLowerCase()] = current
    //   return prev
    // }, {})
    return {
      configData: {},
      extraInfo: [
        {
          // field: 'idps',
          title: '定价方式',
          hidden: true,
          slots: {
            default: ({ row }) => {
              return [
                <PricingMode data={this.data} onManager={this.onManager}/>,
              ]
            },
          },
        },
        {
          field: 'idps',
          title: '资源详细配置',
          slots: {
            default: ({ row }) => {
              return [
                <SecondPriceInfo data={this.data}/>,
                // <PricingSearch/>,
                // <vxe-grid
                //   // data={row.idps}
                //   data={[{ no: 1 }]}
                //   columns={[
                //     {
                //       title: '名称',
                //       field: 'no',
                //     },
                //     {
                //       title: '类型',
                //       field: 'time',
                //     },
                //     {
                //       title: '厂商',
                //       field: 'time',
                //     },
                //     {
                //       title: '状态',
                //       field: 'time',
                //     },
                //     {
                //       title: '计费方式',
                //       field: 'time',
                //     },
                //     {
                //       title: '操作',
                //       field: 'actions',
                //       slots: {
                //         default: ({ row }) => {
                //           return [
                //             <a-button type="link" onClick={() => this.handleView(row)}>查看</a-button>,
                //             <a-button type="link" onClick={() => this.handleSinglePricing(row)}>单独定价</a-button>,
                //           ]
                //         },
                //       },
                //     },
                //   ]} />,
              ]
            },
          },
        },
      ],
    }
  },
  methods: {
    handleView (row) {
      // TODO 查看
      console.log(row)
      this.createDialog('SinglePricingUpdateForCustomerDialog', {
        user: this.data,
        row: row,
        mode: 'view',
        onManager: this.onManager,
      })
    },
    handleSinglePricing (row) {
      // TODO 单独定价
      console.log(row)
      this.createDialog('SinglePricingUpdateForCustomerDialog', {
        user: this.data,
        mode: 'edit',
        row: row,
        onManager: this.onManager,
      })
    },
  },
}
</script>
