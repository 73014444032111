<template>
    <div class="bidding">
      <banner :title="title" :desc="desc" v-if="false" />
      <bidding-detail :id="id" />
    </div>
</template>

<script>
import Banner from '@/views/portal/bidding/components/Banner'
import BiddingDetail from '@/views/portal/bidding/components/BiddingDetail'
export default {
  name: 'BDetail',
  components: {
    Banner,
    BiddingDetail,
  },
  data () {
    return {
      title: '公告',
      desc: '',
      id: '',
    }
  },
  created () {
    this.id = this.$route.query.id
  },
}
</script>

<style lang="scss" scoped>
.bidding{
  display: flex;
  flex-direction: column;
  height: 100%;
}
</style>
