import Layout from '@/layouts/RouterView'
// import store from '@/store'
// import i18n from '@/locales'

const Order = () => import(/* webpackChunkName: "customer" */ /* webpackPrefetch: true */ '@Cost/views/order')

export default {
  index: 1800,
  meta: {
    label: '费用中心',
    icon: 'menu-expense',
  },
  menus: [
    {
      meta: {
        label: '订单管理',
      },
      submenus: [
        {
          path: '/order',
          meta: {
            label: '订单管理',
            permission: 'order_list',
            // hidden: () => {
            //   if (isScopedPolicyMenuHidden('sub_hidden_menus.vminstance')) {
            //     return true
            //   }
            //   return !hasSetupKey(['onestack', 'private', 'public', 'vmware'])
            // },
          },
          component: Layout,
          children: [
            {
              name: 'OrderIndex',
              path: '',
              meta: {},
              component: Order,
            },
          ],
        },
      ],
    },
  ],
}
