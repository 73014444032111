<template>
    <div class="partner">
        <div class="title">合作伙伴</div>
        <div class="list">
          <div class="item">
            <img class="aliyun" src="https://img.cdn.mg.xyz/mgcloud/photo/image/common/aliyun.png" alt="" />
          </div>
          <div class="item">
            <img class="huaweiyun" src="https://img.cdn.mg.xyz/mgcloud/photo/image/common/huaweiyun.png" alt="" />
          </div>
          <div class="item">
            <img class="tencentcloud" src="https://img.cdn.mg.xyz/mgcloud/photo/image/common/tencentcloud.png" alt="" />
          </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'Partner',
}
</script>

<style lang="scss" scoped>
.partner{
  display: flex;
  flex-direction: column;
  padding: 3.75vw 18.75vw;
  background: #FFF;

  .title{
    height: 1.875vw;
    color: #222;
    font-weight: 500;
    font-size: 1.563vw;
    line-height: 1.875vw;
    text-align: center;
  }

  .list{
    display: flex;
    gap: 0.833vw;
    justify-content: space-between;
    margin-top: 2.5vw;

    .item{
      display: flex;
      flex: 1;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;
      height: 7.5vw;
      background: #FFF;
      border: 1px solid #E6E6E6;

      .aliyun{
        width: 6.25vw;
        height: 1.42vw;
      }

      .huaweiyun{
        width: 6.25vw;
        height: 2.199vw;
      }

      .tencentcloud{
        width: 6.25vw;
        height: 1.406vw;
      }

      .aws{
        width: 5.897vw;
        height: 3.125vw;
      }
    }
  }
}
</style>
