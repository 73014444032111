<template>
  <div class="available-resources-info">
    <div class="no-available-resources" v-if="!showDetail">
      <h2 class="title">暂未配置可用资源，请先 <a href="javascript:void();" @click.prevent="handleResourceAllConfig">配置</a> </h2>
      <span class="desc">未配置默认该账号可使用所有已上线资源</span>
    </div>
    <div class="available-resources" v-else>
      <div class="resource-info">
        <div class="info-row">
          <div class="title">可用资源厂商</div>
          <div class="text">{{getProvidersName(details.providers)}}</div>
          <a href="javascript:void();" @click.prevent="handleResourceAllConfig">配置</a>
        </div>
        <div class="info-row">
          <div class="title">生效起始日期</div>
          <div class="text">{{$moment(details.effect_at).format('YYYY-MM-DD')}}</div>
        </div>
        <div class="info-row" v-if="false">
          <div class="title">可用资源</div>
          <a href="javascript:void();" @click.prevent="handleRegionAllConfig">统一配置可用区域</a>
        </div>
      </div>
      <pricing-search
        @providerChange="handleProviderChange"
        @productTypeChange="handleProductTypeChange"
        @nameChange="handleNameChange"
        @chargeTypeChange="handleChargeTypeChange" />
      <vxe-grid
        :data="resourceData"
        :columns="resourceColumns" />
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
// import ListMixin from '@/mixins/list'
import WindowsMixin from '@/mixins/windows'
import PricingSearch from './PricingSearch'
export default {
  name: 'AvailableResourcesInfo',
  components: {
    PricingSearch,
  },
  mixins: [WindowsMixin],
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  data () {
    return {
      showDetail: false,
      details: {
        effect_at: undefined,
      },
      // list: this.$list.createList(this, {
      //   id: 'AvailableResourcesInfoList',
      //   resource: 'second_prices/second-price',
      //   apiVersion: 'v2',
      //   getParams: this.getParam,
      //   responseData: this.responseData,
      // }),
      resourceData: [],
      resourceColumns: [
        {
          title: '名称',
          field: 'product_name',
        },
        {
          title: '类型',
          field: 'product_type_name',
        },
        {
          title: '厂商',
          field: 'provider_name',
        },
        {
          title: '状态',
          field: 'enabled',
          slots: {
            default: ({ row }) => {
              return [<span>{ row.enabled ? '上架' : '下架' } </span>]
            },
          },
        },
        {
          title: '计费方式',
          field: 'charge_type',
          slots: {
            default: ({ row }) => {
              const item = this.getDictDatas(this.DICT_TYPE.CHARGE_TYPE).find(item => item.value === row.charge_type)
              return [<span>{item?.label || '-'}</span>]
            },
          },
        },
        {
          title: '操作',
          field: 'actions',
          slots: {
            default: ({ row }) => {
              return [
                <a-button type="link" onClick={() => this.handleRegionConfig(row)} disabled={!row.product_status}>配置可用区域</a-button>,
              ]
            },
          },
        },
      ],
      tableParams: {},
    }
  },
  created () {
    this.load()
  },
  methods: {
    load () {
      this.$http.get('/v1/product_users', {
        params: { user_id: this.data.id },
      })
        .then((response) => {
          const res = response.data
          console.log('res=', res)
          const data = res.data
          if (data && data.length > 0) {
            this.showDetail = true
            this.details = data[0]
            // 加载产品定价,产品ID
            this.loadProductPrice()
          } else {
            this.showDetail = false
            this.details = {
              effect_at: undefined,
            }
          }
        })
    },
    loadProductPrice () {
      const defaultParams = {
        enabled: true,
        product_user_id: this.details.id,
      }

      console.log('this.tableParams=', this.tableParams)

      if (!this.tableParams.product_id) {
        const product_ids = _.flatten(this.details.providers_products.map(item => item.product_ids))
        if (product_ids && product_ids.length > 0) {
          Object.assign(defaultParams, {
            product_id: product_ids,
          })
        }
      }

      if (!this.tableParams.provider) {
        const providers = this.details.providers
        if (providers && providers.length > 0) {
          Object.assign(defaultParams, {
            provider: providers,
          })
        }
      }

      this.$http.get('/v1/product_prices', {
        params: {
          // user_id: this.data.id,
          ...this.tableParams,
          ...defaultParams,
        },
      })
        .then((response) => {
          const res = response.data
          // console.log('res=', res)
          const data = res.data
          if (data && data.length > 0) {
            this.resourceData = data
          } else {
            this.resourceData = []
          }
        })
    },
    getProvidersName (providers) {
      const result = []
      providers.forEach((item) => {
        result.push(this.getDictDataLabel(this.DICT_TYPE.PROVIDER, item))
      })
      return result.join(' ')
    },
    handleResourceAllConfig () {
      // 配置资源
      this.createDialog('AvailableResourceAllConfigUpdateForCustomerDialog', {
        user: this.data,
        details: this.details,
        refresh: this.load,
      })
    },
    handleRegionAllConfig () {
      // 统一配置可用区域
      this.createDialog('AvailableRegionAllConfigUpdateForCustomerDialog', {
        user: this.data,
        details: this.details,
        refresh: this.load,
      })
    },
    handleRegionConfig (row) {
      // TODO 配置可用区域
      this.createDialog('AvailableRegionConfigUpdateForCustomerDialog', {
        user: this.data,
        row: row,
        refresh: this.load,
      })
    },
    handleProviderChange (val) {
      console.log('handleProviderChange(val)', val)
      Object.assign(this.tableParams, { provider: val })
      this.loadProductPrice()
    },
    handleProductTypeChange (val) {
      console.log('handleProductTypeChange(val)', val)
      Object.assign(this.tableParams, { product_type: val })
      this.loadProductPrice()
    },
    handleNameChange (val) {
      console.log('handleNameChange(val)', val)
      Object.assign(this.tableParams, { product_id: val })
      this.loadProductPrice()
    },
    handleChargeTypeChange (val) {
      console.log('handleChargeTypeChange(val)', val)
      Object.assign(this.tableParams, { charge_type: val })
      this.loadProductPrice()
    },
  },
}
</script>

<style lang="scss" scoped>
.available-resources-info{
  display: flex;
  width: 100%;
  .no-available-resources{
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0 10px;
    .title{
      font-size: 16px;
      font-weight: 600;
    }
    .desc{
      color: #333;
      font-size: 15px;
    }
  }
  .available-resources{
    width: 100%;
    display: flex;
    flex-direction: column;
    .resource-info {
      display: flex;
      flex-direction: column;
      width: 100%;
      .info-row{
        display: flex;
        align-items: center;
        margin: 5px 0;
        .title{
          font-weight: 600;
          margin-right: 20px;
        }
        .text{
          color: #333;
          margin-right: 10px;
        }
      }
    }
  }
}
</style>
