<template>
  <div class="bidding-detail">
    <div class="title">{{ currentTitle }}</div>
    <div class="sub-desc">
      <span class="publish-time">公告发布时间：{{publishTime}}</span>
      <!-- <span class="bidding-end-time">投标截止时间：{{biddingEndTime}}</span> -->
    </div>
    <div class="content" v-html="content" ref="richTextContainer" />
    <template v-if="attachmentList.length">
      <div class="attachment-name">附件：</div>
      <div class="attachment-list">
        <a class="attachment-item" v-for="(item,index) in attachmentList" :key="index" @click.prevent="handleDownload(item)">
          附件{{ index + 1 }}: {{ item.name }}
        </a>
      </div>
    </template>
  </div>
</template>

<script>
import moment from 'moment'
import http from '@/utils/http'
import { downloadFile } from '@/utils/utils'
export default {
  name: 'BiddingDetail',
  props: {
    id: {
      type: String,
      default: '',
    },
  },
  data () {
    return {
      currentTitle: '',
      publishTime: '',
      biddingEndTime: '2024-03-25 12:00',
      content: '',
      attachmentList: [],
    }
  },
  watch: {
    $route (newValue, oldValue) {
      this.loadDetail()
    },
  },
  created () {
    this.loadDetail()
  },
  methods: {
    loadDetail () {
      const params = {
        id: this.$route.query.id,
      }
      // notice(this.$route.query.id, params)
      http.get('/v1/bill-web/notice/getInfo', { params: params }).then(response => {
        const res = response.data
        const detail = res.data
        this.currentTitle = detail.name
        this.content = detail.content
        this.publishTime = moment(detail.publicityf_at).format('YYYY-MM-DD')
        const annexs = detail.annexs
        if (annexs) {
          this.attachmentList = annexs.split(',').map((item) => {
            const name = item.includes('@@@') ? item.split('@@@')[1] : item
            return { name: name, url: `${detail.cdn_uri}/${detail.name}/${item}` }
          })
        } else {
          this.attachmentList = []
        }
        this.$nextTick(() => {
          const tables = this.$refs.richTextContainer.querySelectorAll('table')
          tables.forEach(table => {
            table.classList.add('bidding-detail-content-table')
            table.classList.add('bidding-detail-content-table-border')
            const trs = table.querySelectorAll('tr')
            trs.forEach(tr => {
              tr.classList.add('bidding-detail-content-table-border')
              const tds = tr.querySelectorAll('td')
              tds.forEach(td => {
                td.classList.add('bidding-detail-content-table-border')
              })
              const ths = tr.querySelectorAll('th')
              ths.forEach(th => {
                th.classList.add('bidding-detail-content-table-border')
              })
            })
          })
        })
      }).catch(() => {
        this.$message.error('获取详情失败')
        setTimeout(() => {
          this.$router.push('/portal/bidding')
        }, 500)
      })
    },
    handleDownload (item) {
      if (item.url) {
        // window.open(item.url)
        try {
          downloadFile(item.url, item.name)
        } catch (err) {
          this.$message.error('附件下载失败')
        }
      }
    },
  },
}
</script>
<style>
.bidding-detail-content-table{
  width: 100% !important;
}
.bidding-detail-content-table-border{
  border: 1px solid #ccc;
  border-collapse: collapse;
  line-height: 1.5;
  height: 1.458vw;
}
</style>
<style lang="scss" scoped>
.bidding-detail {
  display: flex;
  flex-direction: column;
  padding: 2.5vw 18.75vw;
  background: #fff;

  .title {
    height: 1.875vw;
    color: #222;
    font-weight: 500;
    font-size: 1.563vw;
    line-height: 1.875vw;
    text-align: center;
  }

  .sub-desc {
    margin-top: 1.875vw;
    color: #222;
    font-size: 0.729vw;
    line-height: 1.146vw;
    text-align: center;

    .publish-time{
      margin-right: 1.042vw;
    }
  }

  .content{
    margin-top: 1.875vw;
    font-weight: 400;
    font-size: .729vw;
    line-height: 1.146vw;
  }

  .attachment-name{
    margin-top: 0.875vw;
    font-weight: 400;
    font-size: .729vw;
    line-height: 1.146vw;
  }

  .attachment-list{
    margin-top: 0.475vw;
    font-size: .729vw;
    line-height: 1.146vw;
    display: flex;
    flex-direction: column;
  }
}
</style>
