<template>
  <detail
    :onManager="onManager"
    :data="data"
    :showName="false"
    :showDesc="false"
    :hiddenKeys="hiddenKeys"
    :base-info="baseInfo"
    :extra-info="extraInfo" />
</template>

<script>
import {
  getEnabledTableColumn,
} from '@/utils/common/tableColumn'
// import {
//   getUserTagColumn,
// } from '@/utils/common/detailColumn'

export default {
  name: 'CustomerDetail',
  props: {
    data: {
      type: Object,
      required: true,
    },
    onManager: {
      type: Function,
      required: true,
    },
  },
  data () {
    // const driverOptions = Object.keys(this.$t('idpDrivers')).reduce((prev, current) => {
    //   prev[current.toLowerCase()] = current
    //   return prev
    // }, {})
    return {
      hiddenKeys: ['status'],
      configData: {},
      baseInfo: [
        getEnabledTableColumn(),
        {
          field: 'name',
          title: '客户ID',
          slots: {
            default: ({ row }) => {
              return [<list-body-cell-wrap copy row={ row } field='name' title={ row.name } />]
            },
          },
        },
        {
          field: 'mobile',
          title: '手机号',
          slots: {
            default: ({ row }) => {
              return [<list-body-cell-wrap copy row={ row } field='mobile' title={ row.mobile || '-' } />]
            },
          },
        },
        // {
        //   field: 'sub_account_number',
        //   title: '子账号数',
        //   slots: {
        //     default: ({ row }) => {
        //       return [<list-body-cell-wrap row={ row } field='sub_account_number' title={ row.sub_account_number } />]
        //     },
        //   },
        // },
        // {
        //   field: 'credites_amount',
        //   title: '授信额度',
        //   slots: {
        //     default: ({ row }) => {
        //       return [<list-body-cell-wrap row={ row } field='credites_amount' title={ row.credites_amount } />]
        //     },
        //   },
        // },
        {
          field: 'business_type',
          title: '合作方式',
          slots: {
            default: ({ row }) => {
              return [
                <list-body-cell-wrap row={ row } field='business_type' title={ row.business_type === 0 ? '定向' : '竞标' } hideField>
                  { row.business_type === 0 ? '定向' : '竞标' }
                </list-body-cell-wrap>,
              ]
            },
          },
        },
        // {
        //   field: 'second_price',
        //   title: '二次定价',
        //   slots: {
        //     default: ({ row }) => {
        //       return [<list-body-cell-wrap row={ row } field='second_price' title={ row.second_price ? '是' : '否' } />]
        //     },
        //   },
        // },
        // {
        //   field: 'displayname',
        //   title: this.$t('scope.text_245'),
        //   slots: {
        //     default: ({ row }) => {
        //       return [<list-body-cell-wrap copy row={ row } field='displayname' title={ row.displayname || '-' } />]
        //     },
        //   },
        // },
        // getEnabledTableColumn(),
        // getEnabledTableColumn({
        //   field: 'allow_web_console',
        //   title: this.$t('system.text_512'),
        // }),
        // getEnabledTableColumn({
        //   field: 'enable_mfa',
        //   title: 'MFA',
        // }),
        // getUserTagColumn({
        //   onManager: this.onManager,
        //   resource: 'users',
        //   needExt: true,
        //   columns: () => this.columns,
        // }),
        // {
        //   field: 'group_count',
        //   title: this.$t('system.text_514'),
        //   slots: {
        //     default: ({ row }) => {
        //       if (!row.group_count) return '0'
        //       return [<a onClick={ () => this.$emit('tab-change', 'Group') }>{row.group_count}</a>]
        //     },
        //   },
        // },
        // {
        //   field: 'project_count',
        //   title: this.$t('system.text_560'),
        //   slots: {
        //     default: ({ row }) => {
        //       if (!row.project_count) return '0'
        //       return [<a onClick={ () => this.$emit('tab-change', 'projects') }>{row.project_count}</a>]
        //     },
        //   },
        // },
        // {
        //   field: 'idps',
        //   title: this.$t('system.text_165'),
        //   slots: {
        //     default: ({ row }) => {
        //       if (row.idps && row.idps.length) {
        //         return row.idps.map(item => {
        //           return item.idp
        //         }).join('、')
        //       }
        //       return '-'
        //     },
        //   },
        // },
      ],
      extraInfo: [
        {
          // field: 'idps',
          title: '基础设置资源统计',
          hidden: true,
          slots: {
            default: ({ row }) => {
              return [
                <vxe-grid
                  data={row.idps}
                  columns={[
                    {
                      title: 'CPU',
                      field: 'idp_driver',
                      formatter: ({ cellValue }) => cellValue + '核',
                    },
                    {
                      title: '内存',
                      field: 'idp_driver',
                      formatter: ({ cellValue }) => cellValue + 'GiB',
                    },
                    {
                      title: '存储',
                      field: 'idp_driver',
                      formatter: ({ cellValue }) => cellValue + 'T',
                    },
                    {
                      title: 'GPU',
                      field: 'template',
                      formatter: ({ row }) => this.$t('idpTmplTitles')[row.template] ? this.$t(`idpTmplTitles.${row.template}`) : row.template || '-',
                    },
                    {
                      title: '虚拟机',
                      field: 'idp_driver',
                      formatter: ({ cellValue }) => cellValue + '台',
                    },
                    {
                      title: 'EIP',
                      field: 'idp_driver',
                      formatter: ({ cellValue }) => cellValue + '个',
                    },
                    {
                      title: '带宽',
                      field: 'idp_driver',
                      formatter: ({ cellValue }) => cellValue + 'B',
                    },
                  ]} />,
              ]
            },
          },
        },
        {
          title: '资源统计',
          hidden: true,
          items: [
            {
              field: 'last_login_ip',
              title: 'VPC',
              slots: {
                default: ({ row }) => {
                  return row.last_login_ip + '个'
                },
              },
            },
            {
              field: 'last_login_ip',
              title: 'DNS解析',
              slots: {
                default: ({ row }) => {
                  return row.last_login_ip + '个'
                },
              },
            },
            {
              field: 'last_login_ip',
              title: '子账号',
              slots: {
                default: ({ row }) => {
                  return row.last_login_ip + '个'
                },
              },
            },
          ],
        },
      ],
    }
  },
}
</script>
