import Vue from 'vue'
import { DICT_TYPE, getDictDataLabel, getDictDatas, getDictDatas2 } from '@/utils/dict'

export default {
  install () {
    Vue.prototype.getDictDatas = getDictDatas
    Vue.prototype.getDictDatas2 = getDictDatas2
    Vue.prototype.getDictDataLabel = getDictDataLabel
    Vue.prototype.DICT_TYPE = DICT_TYPE
  },
}
