<template>
  <div class="user-credites-info">
    <page-list
      :list="list"
      :columns="columns"
      :showSearchbox="false"
      :showSingleActions="false"
      :showGroupActions="false"
      :hideRowselect="true" />
  </div>
</template>

<script>
import WindowsMixin from '@/mixins/windows'
import ListMixin from '@/mixins/list'
import { numerify } from '@/filters'
export default {
  name: 'UserCreditesInfo',
  components: {},
  mixins: [WindowsMixin, ListMixin],
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  data () {
    return {
      list: this.$list.createList(this, {
        id: 'UserCreditesInfoList',
        resource: 'credit_transactions',
        apiVersion: 'v1',
        getParams: this.getParam,
        responseData: this.responseData,
      }),
      columns: [
        {
          title: '流水号',
          field: 'serial_number',
        },
        {
          title: '交易时间',
          field: 'billing_start_time',
          slots: {
            default: ({ row }) => {
              return [<span>{this.$moment(row.billing_start_time).format()}</span>]
            },
          },
        },
        {
          title: '交易类型',
          field: 'transaction_type',
          slots: {
            default: ({ row }) => {
              return [<span>{this.getDictDataLabel(this.DICT_TYPE.TRANSACTION_TYPE, row.transaction_type)}</span>]
            },
          },
        },
        {
          title: '金额',
          field: 'amount',
          slots: {
            default: ({ row }) => {
              return [<span>￥{numerify(row.amount, '0,0.0000')}</span>]
            },
          },
        },
        {
          title: '资金形式',
          field: 'capital_form',
          slots: {
            default: ({ row }) => {
              return [<span>{this.getDictDataLabel(this.DICT_TYPE.CAPITAL_FORM, row.capital_form)}</span>]
            },
          },
        },
        {
          title: '交易备注',
          field: 'remark',
        },
        {
          title: '订单号',
          field: 'order_number',
        },
        {
          title: '账期',
          field: 'billing_period',
        },
      ],
    }
  },
  created () {
    this.list.fetchData()
  },
  methods: {
    getParam () {
      const ret = {
        // ...this.getParams,
        details: true,
        user_id: this.data.id,
      }
      return ret
    },
  },
}
</script>

<style lang="scss" scoped>
.user-credites-info{
  width: 100%;
  display: flex;
  flex-direction: column;
}
</style>
