<template>
    <div class="product-list">
        <div class="title">热门产品精选</div>
        <div class="intro">基于丰富的产品，将计算、存储、网络、数据库等最新产品技术与场景深度融合，为开发者打造稳定可靠的云基础设施以及云原生的开发环境</div>
        <div class="list">
            <div class="item" v-for="(item,index) in list" :key="index">
                <img class="img" :src="item.img" alt="" />
                <div class="content">
                  <div class="name">{{item.name}}</div>
                  <div class="desc">{{item.desc}}</div>
                  <a href="#" class="more" @click.prevent="handleClick(item)">了解更多 <a-icon type="right" class="icon-more" /></a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'ProductList',
  data () {
    return {
      list: [
        { id: 9, name: '云服务器', desc: '丰富的实例类型和多种存储选择，助您提升运维效率，使您更专注于核心业务创新', img: 'https://img.cdn.mg.xyz/mgcloud/photo/image/home/product-img.png' },
        { id: 21, name: '负载均衡SLB', desc: '多协议支持、多层次容灾，超强性能、调度灵活，自带免费基础安全防护能力', img: 'https://img.cdn.mg.xyz/mgcloud/photo/image/home/product-img2.png' },
        { id: 22, name: '专有网络VPC', desc: '自定义网络规划、二层逻辑隔离，助您构建混合云架构', img: 'https://img.cdn.mg.xyz/mgcloud/photo/image/home/product-img3.png' },
      ],
    }
  },
  methods: {
    handleClick (item) {
      this.$router.push({
        name: 'products',
        params: {
          id: item.id,
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.product-list{
  display: flex;
  flex-direction: column;
  padding: 3.75vw 18.75vw;
  background: #FFF;

  .title{
    height: 1.875vw;
    color: #222;
    font-weight: 500;
    font-size: 1.563vw;
    line-height: 1.875vw;
    text-align: center;
  }

  .intro{
    height: 1.146vw;
    margin-top: 1.25vw;
    color: #646566;
    font-weight: normal;
    font-size: 0.729vw;
    line-height: 1.146vw;
    text-align: center;
  }

  .list{
    display: flex;
    justify-content: space-between;
    margin-top: 1.875vw;
    gap: 0.833vw;

    .item{
      flex: 1;
      height: 20.104vw;
      box-shadow: 0 0.625vw 1.875vw 0 rgba(0, 0, 0, 0.1);

      .img{
        width: 100%;
        height: 11.458vw;
      }

      .content{
        display: flex;
        flex-direction: column;
        padding: 0.833vw;

        .name{
          height: 1.146vw;
          color: #222;
          font-weight: 500;
          font-size: 0.729vw;
          line-height: 1.146vw;
        }

        .desc{
          height: 2.292vw;
          margin-top: 0.833vw;
          color: #646566;
          font-weight: normal;
          font-size: 0.729vw;
          line-height: 1.146vw;
        }

        .more{
          margin-top: 0.833vw;
          color: #646566;
          font-weight: normal;
          font-size: 0.625vw;
          line-height: 0.938vw;

          .icon-more{
            margin-left: 0.417vw;
          }

          &:hover{
            color: #0066FE;
            text-decoration: underline;
            text-underline-offset: 0.104vw;

            .icon-more{
              margin-left: 0.625vw;
            }
          }
        }
      }
    }
  }
}
</style>
