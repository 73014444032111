import axios from 'axios'

export const wikiService = axios.create({
  baseURL: '/', // api 的 base_url  process.env.BASE_API
  withCredentials: true,
  timeout: 30000, // request timeout
  headers: { 'Content-type': 'application/x-www-form-urlencoded' },
})

wikiService.interceptors.response.use(
  (response) => {
    if (!response.config.needValidateResult || response.data.errCode === 200) {
      return response.data
    }

    return Promise.reject(response.message)
  }, (error) => {
    return Promise.reject(error)
  },
)
