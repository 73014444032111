<template>
  <base-dialog @cancel="cancelDialog" :width="1120">
    <div slot="header">{{isViewMode?'查看':'单独定价'}}</div>
    <div slot="body">
      <a-form
        :form="form.fc"
        v-bind="formItemLayout">
        <a-row>
          <a-col :span="24">
            <span class="info-title">基础信息</span>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="12">
            <a-form-item label="资源厂商">
              <span>{{ params.row.provider_name }}</span>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="资源类型">
              <span>{{ params.row.product_type_name }}</span>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="12">
            <a-form-item label="资源">
              <span>{{ params.row.product_name }}</span>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="24">
            <span class="info-title">定价信息</span>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="12">
            <a-form-item label="计费方式">
              <span>{{ this.getDictDataLabel(DICT_TYPE.CHARGE_TYPE,params.row.charge_type) }}</span>
            </a-form-item>
          </a-col>
          <a-col :span="12" v-if="false">
            <a-form-item label="整体定价">
              <span>芒果云价格x <a-input-number v-model="rate" /> 倍</span>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="12">
            <a-form-item label="定价表" />
          </a-col>
        </a-row>
      </a-form>
      <page-body style="width:1040px">
        <page-list
          :list="list"
          :columns="columns"
          :showSearchbox="false"
          :showSingleActions="false"
          :showGroupActions="false"
          :hideRowselect="isViewMode" />
      </page-body>
    </div>
    <div slot="footer">
      <a-button type="primary" @click="handleConfirm" :loading="loading" v-if="!isViewMode">{{ $t('dialog.ok') }}</a-button>
      <a-button @click="cancelDialog">{{ $t('dialog.cancel') }}</a-button>
    </div>
  </base-dialog>
</template>

<script>
import ListMixin from '@/mixins/list'
import DialogMixin from '@/mixins/dialog'
import WindowsMixin from '@/mixins/windows'
import { numerify } from '@/filters'

export default {
  name: 'SinglePricingUpdateForCustomerDialog',
  mixins: [ListMixin, DialogMixin, WindowsMixin],
  data () {
    return {
      isViewMode: false,
      list: this.$list.createList(this, {
        id: 'SinglePricingUpdateForCustomerDialogList',
        idKey: 'flavors_id',
        resource: 'second_prices/second-price',
        apiVersion: 'v2',
        getParams: this.getParam,
        responseData: this.responseData,
      }),
      rate: 1,
      loading: false,
      form: {
        fc: this.$form.createForm(this),
      },
      formItemLayout: {
        wrapperCol: {
          span: 17,
        },
        labelCol: {
          span: 7,
        },
      },
      productColumns: [],
    }
  },
  computed: {
    columns () {
      return [
        {
          field: 'mapping_name',
          title: '地域名称',
          width: 130,
          slots: {
            default: ({ row }) => {
              return [<span>{row.mapping_name}</span>]
            },
          },
        },
        {
          field: 'instance_type_category',
          title: '规格',
          width: 130,
        },
        ...this.productColumns,
        {
          field: 'first_price',
          title: '芒果云价格',
          width: 130,
          slots: {
            default: ({ row }) => {
              return [<span>￥{numerify(row.first_price, '0,0.00')} /时</span>]
            },
          },
        },
        {
          field: 'price',
          title: '二次定价',
          width: this.isViewMode ? 190 : 150,
          slots: {
            default: ({ row }) => {
              if (this.isViewMode) {
                return [<span>￥{numerify(row.price, '0,0.00')} /时</span>]
              } else {
                return [
                  <a-input-number
                    formatter={value => `￥ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={value => value.replace(/￥\s?|(,*)/g, '')}
                    v-model={row.price}
                    min={0}
                  />,
                  <span> /时</span>,
                ]
              }
            },
          },
        },
      ]
    },
  },
  created () {
    this.isViewMode = this.params.mode === 'view'
    console.log('this.params', this.params)
    this.setColumns()
    this.list.fetchData()
    this.$nextTick(() => {
      console.log('this.responseData=', this.responseData)
    })
  },
  methods: {
    setColumns () {
      const product_code = this.params.row.product_code
      switch (product_code) {
        case 'ecs':
          this.productColumns = [
            {
              field: 'name',
              width: 130,
              title: '实例',
            },
            {
              field: 'cpu_core_count',
              width: 110,
              title: 'vCPU',
              slots: {
                default: ({ row }) => {
                  return [<span>{row.cpu_core_count} vCPU</span>]
                },
              },
            },
            {
              field: 'memory_size_gb',
              width: 110,
              title: '内存',
              slots: {
                default: ({ row }) => {
                  return [<span>{row.memory_size_gb} GiB</span>]
                },
              },
            },
            {
              field: 'cpu_arch',
              width: 110,
              title: '架构',
            },
          ]
          break
        // TODO 其他资源的列
        default:
          break
      }
    },
    getParam () {
      const ret = {
        // ...this.getParams,
        details: true,
        user_id: this.params.user.id,
        provider: this.params.row.provider,
        resource_unique_id: this.params.row.product_code,
        billing_type: this.params.row.charge_type,
        // TODO 可以新增区域选择的条件
        // area_mapping_id:257
      }
      return ret
    },
    async save (data) {
      try {
        return this.$http.post('/v2/second_prices/create-second-price', data)
      } catch (error) {
        throw error
      }
    },
    async handleConfirm () {
      if (this.list.selectedItems.length === 0) {
        this.$message.error('请至少选择一项！')
        return
      }
      if (this.list.selectedItems.some(item => !item.price)) {
        this.$message.error('存在价格为空的数据，请检查！')
        return
      }
      // console.log('this.list.selectedItems=', this.list.selectedItems)
      this.loading = true
      try {
        const values = {
          user_id: this.params.user.id,
          resource_unique_id: this.params.row.product_code,
          data: this.list.selectedItems.map(item => {
            return {
              id: item.id,
              flavors_id: item.flavors_id,
              price: item.price,
              area_mapping_id: item.area_mapping_id,
              provider: item.provider,
              billing_type: this.params.row.charge_type,
            }
          }),
        }
        await this.save(values)
        this.$message.success('可用资源配置成功')
        this.cancelDialog()
        this.params.refresh()
      } finally {
        this.loading = false
      }
    },

  },
}
</script>
<style lang="scss" scoped>
.ant-form-item{
  margin-bottom: 0;
}
</style>
