<template>
    <div class="product-info">
        <div class="title">性能强大、安全稳定的产品</div>
        <div class="product-item">
            <img class="img" src="https://img.cdn.mg.xyz/mgcloud/photo/image/home/product-info-img.jpg" alt="" />
            <div class="content">
                <div class="name">硬盘</div>
                <div class="desc">为云服务器ECS提供的低时延、持久性、高可靠的块级随机存储。硬盘支持在可用区内自动复制您的数据，防止意外硬件故障导致的数据不可用，保护您的业务免于硬件故障的威胁。</div>
                <div class="btns">
                    <a class="more" href="#" @click.prevent="handleGo(11)">了解更多</a>
                </div>
            </div>
        </div>
        <div class="product-item">
            <div class="content">
                <div class="name">弹性公网IP</div>
                <div class="desc">可与芒果云专有网络VPC类型的云服务器ECS、NAT网关、私网负载均衡SLB绑定，并可以动态解绑满足灵活管理的要求。弹性公网IP可为您在云上部署的网站提供Internet访问服务。</div>
                <div class="btns">
                    <a class="more" href="#" @click.prevent="handleGo(19)">了解更多</a>
                </div>
            </div>
            <img class="img" src="https://img.cdn.mg.xyz/mgcloud/photo/image/home/product-info-img2.jpg" alt="" />
        </div>
    </div>
</template>

<script>
export default {
  name: 'ProductInfo',
  data () {
    return {
      list: [
        { id: 11, name: '硬盘', desc: '为云服务器ECS提供的低时延、持久性、高可靠的块级随机存储。硬盘支持在可用区内自动复制您的数据，防止意外硬件故障导致的数据不可用，保护您的业务免于硬件故障的威胁。', img: 'https://img.cdn.mg.xyz/mgcloud/photo/image/home/product-info-img.jpg' },
        { id: 19, name: '弹性公网IP', desc: '可与芒果云专有网络VPC类型的云服务器ECS、NAT网关、私网负载均衡SLB绑定，并可以动态解绑满足灵活管理的要求。弹性公网IP可为您在云上部署的网站提供Internet访问服务。', img: 'https://img.cdn.mg.xyz/mgcloud/photo/image/home/product-info-img2.jpg' },
      ],
    }
  },
  methods: {
    handleClick (item) {
      this.$router.push({
        name: 'products',
        params: {
          id: item.id,
        },
      })
    },
    handleGo (id) {
      this.$router.push({
        name: 'products',
        params: {
          id: id,
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.product-info{
  display: flex;
  flex-direction: column;
  padding: 3.75vw 18.75vw;
  background: #FAFAFB;

  .title{
    height: 1.875vw;
    color: #222;
    font-weight: 500;
    font-size: 1.563vw;
    line-height: 1.875vw;
    text-align: center;
  }

  .product-item{
    display: flex;
    justify-content: space-between;
    height: 19.167vw;
    margin-top: 2.5vw;

    .img{
      width: 34.115vw;
      height: 19.167vw;
    }

    .content{
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 26.51vw;

      .name{
        height: 1.146vw;
        color: #222;
        font-weight: 500;
        font-size: 0.833vw;
        line-height: 1.146vw;
      }

      .desc{
        margin-top: 0.833vw;
        color: #646566;
        font-weight: normal;
        font-size: 0.729vw;
        line-height: 1.146vw;
      }

      .btns{
        margin-top: 2.083vw;

        .more{
          display: inline-block;
          box-sizing: border-box;
          height: 2.5vw;
          padding: 0 1.667vw;
          color: #FFF;
          font-weight: normal;
          font-size: 0.729vw;
          line-height: 2.5vw;
          text-align: center;
          background: #0066FE;
          border-radius: 1.302vw;
          cursor: pointer;

          &:hover{
            background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #0066FE;
          }
        }

      }

    }
  }
}
</style>
