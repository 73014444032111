<template>
    <div class="footer">
        <div class="link-box">
            <div class="link-list" v-for="(item,index) in list" :key="index">
                <div class="link-title">{{item.name}}</div>
                <div class="link-content">
                    <a class="link-item" href="#" v-for="link in item.children" :key="link.id" @click.prevent="handleClick(link)">{{link.name}}</a>
                </div>
            </div>
        </div>
        <div class="line" />
        <div class="copyright">
            <span>Copyright ©2024 Cloud.mg.xyz 芒果云 版权所有</span>
            <span> 湖南芒果融创科技有限公司 </span>
            <a href="https://beian.miit.gov.cn/#/Integrated/index"><span> ICP备案/许可证号：{{ beianNo }}</span></a>
            <span v-if="false"> 湘公网安备号：湘B2-20240089</span>
        </div>
    </div>
</template>

<script>
export default {
  name: 'MgFooter',
  data () {
    return {
      list: [
        {
          name: '推荐产品',
          id: 1,
          children: [
            { name: '云服务器ECS', id: 9, isProduct: true },
            // { name: '边缘节点服务ENS', id: 3, children: [] },
            { name: '硬盘', id: 11, isProduct: true },
            { name: '弹性公网IP', id: 19, isProduct: true },
            { name: '负载均衡SLB', id: 21, isProduct: true },
            { name: '专有网络VPC', id: 22, isProduct: true },
            { name: 'NAT网关', id: 23, isProduct: true },
          ],
        },
        {
          name: '服务支持',
          id: 9,
          children: [
            { name: '文档中心', id: 10, path: '/portal/documents' },
            { name: '计费规则', id: 11, path: '/portal/billing' },
            { name: '联系我们', id: 12, path: '/portal/contactus' },
          ],
        },
        {
          name: '关于芒果云',
          id: 13,
          children: [
            { name: '关于芒果云', id: 14, path: '/portal/about' },
          ],
        },
        {
          name: '公告',
          id: 23,
          children: [
            { name: '公告', id: 24, path: '/portal/bidding' },
          ],
        },
      ],
      beianNo: '湘ICP备2021012551号-2',
    }
  },
  mounted () {
    this.initBeianNo()
  },
  methods: {
    initBeianNo () {
      if (window.location.hostname.indexOf('mg.xyz') > -1) { this.beianNo = '湘ICP备2021012551号-2' } else { this.beianNo = '湘ICP备2021012551号-1' }
    },
    handleClick (item) {
      if (item.isProduct) {
        this.$router.push({
          name: 'products',
          params: {
            id: item.id,
          },
        })
      } else {
        this.$router.push(item.path)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.footer{
    display: flex;
    flex-direction: column;
    padding: 2.5vw 18.75vw 1.875vw;
    color: #000;
    background: #FAFAFB;

    .link-box{
        display: flex;
        height: 14.271vw;

        .link-list{
            display: flex;
            flex-direction: column;
            height: 100%;
            margin-right: 6.458vw;

            &:last-child{
                margin-right: 0;
            }

            .link-title{
                height: 1.146vw;
                margin-bottom: 1.042vw;
                color: #222;
                font-weight: 600;
                font-size: 0.729vw;
                line-height: 1.146vw;
            }

            .link-content{
                display: flex;
                flex-direction: column;

                .link-item{
                    margin-bottom: 1.042vw;
                    color: #646566;
                    font-weight: normal;
                    font-size: 0.729vw;
                    line-height: 1.146vw;
                    white-space: nowrap;

                    &:last-child{
                        margin-bottom: 0;
                    }

                    &:hover{
                      text-decoration: underline;
                      text-underline-offset: 0.104vw;
                    }
                }

                .link-qrcodes{
                    display: flex;

                    .link-qrcode{
                        width: 5.729vw;
                        height: 5.729vw;
                        margin-right: 0.833vw;
                    }
                }
            }
        }
    }

    .line{
        height: 0.5px;
        margin-top: 3.151vw;
        background: #222;
        opacity: 0.5;
    }

    .copyright{
        margin-top: 1.25vw;

        span{
            height: 0.938vw;
            color: #222;
            font-size: 0.625vw;
            line-height: 0.938vw;
            opacity: 0.6;
        }

        a:hover{
          color: #0066FE;
        }
    }
}
</style>
