<template>
    <div class="documents">
      <banner title="文档中心" desc="全链路的自研能力，形成“云智一体”，集成视频创作、图片生成和图文写作，全方位赋能媒体、金融、政企等行业的高效内容创作" />
      <doc-menu :menus="menus" />
    </div>
</template>

<script>
import { productCategoryMenu } from '@/maps/common'
import Banner from '@/views/portal/documents/components/Banner'
import DocMenu from '@/views/portal/documents/components/DocMenu'
export default {
  name: 'Documents',
  components: {
    Banner,
    DocMenu,
  },
  data () {
    return {
      menus: productCategoryMenu,
    }
  },
}
</script>

<style lang="scss" scoped>

</style>
