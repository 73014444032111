<template>
    <div class="document-and-tool">
        <div class="title">{{data.title}}</div>
        <div class="intro" v-if="data.intro">{{data.intro}}</div>
        <div class="list">
            <div class="item" v-for="(item,index) in data.documentList" :key="index" @click="handleClick(item)">
                <div class="name-box">
                  <div class="name">{{item.name}}</div><img src="@/assets/image/products/document-tool-icon.svg" alt="" class="item-icon" />
                </div>
                <div class="desc">{{item.desc}}</div>
            </div>
        </div>
    </div>
</template>

<script>
import paramsMixin from '@/mixins/paramsMixin'
export default {
  name: 'DocumentAndTool',
  mixins: [paramsMixin],
  data () {
    return {
      list: [],
    }
  },
  methods: {
    handleClick (item) {
      if (item.space && item.pageId) {
        this.$router.push({
          name: 'documentsDetail',
          query: {
            space: item.space,
            pageId: item.pageId,
          },
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.document-and-tool{
  display: flex;
  flex-direction: column;
  padding: 2.5vw 18.75vw;
  background: url('../../../../assets/image/products/document-tool-bg.jpg') no-repeat center center;
  background-size: 100% 100%;

  .title{
    height: 1.875vw;
    color: #FFF;
    font-weight: 500;
    font-size: 1.563vw;
    line-height: 1.875vw;
    text-align: center;
  }

  .intro{
    height: 1.146vw;
    margin-top: 1.25vw;
    color: #646566;
    font-weight: normal;
    font-size: 0.729vw;
    line-height: 1.146vw;
    text-align: center;
  }

  .list{
    display: flex;
    gap: 0.833vw;
    justify-content: space-between;
    margin-top: 2.5vw;

    .item{
      display: flex;
      flex: 1;
      flex-direction: column;
      height: 6.979vw;
      padding: 1.875vw;
      background: #FFF;
      cursor: pointer;

      .name-box{
        position: relative;

        .name{
          height: 1.146vw;
          color: #3D3D3D;
          font-weight: 500;
          font-size: 0.833vw;
          line-height: 1.146vw;
        }

        .item-icon{
          position: absolute;
          top: 1px;
          right: 0;
          display: none;
          width: 0.938vw;
          height: 0.938vw;
        }
      }

      &:hover{
        box-shadow: 0px 0.781vw 0.781vw -0.521vw rgba(0, 0, 0, 0.15);
        .name-box{
          .name{
            color: #0066FE;
          }

          .item-icon{
            display: block;
            color: #0066FE;
          }
        }
      }

      .desc{
        width: 10.208vw;
        margin-top: 0.833vw;
        overflow: hidden;
        color: #646566;
        font-weight: normal;
        font-size: 0.729vw;
        line-height: 1.146vw;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

    }
  }
}
</style>
