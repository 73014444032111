<template>
  <div class="login-container">
    <div class="login-left">
      <img class="login-img" src="@/assets/image/login-bg.jpg" alt="" />
    </div>
    <div v-loading="loading" class="login-right">
      <div class="login-form-box">
        <h2 class="login-title">欢迎回来，请登录</h2>
        <a-form ref="form" :form="loginForm" layout="vertical" class="login-form">
          <a-form-item label="用户名">
            <a-input
              placeholder="请输入用户名"
              v-decorator="['username', { rules: [{ required: true, message: '请输入用户名' }] }]" />
          </a-form-item>
          <a-form-item label="密码">
            <a-input-password
              placeholder="请输入密码"
              v-decorator="['password', { rules: [{ required: true, message: '请输入密码' }] }]" />
          </a-form-item>
        </a-form>
        <div class="login-btn">
          <a-button v-debounce type="primary" class="btn" size="default" @click="check">登 录</a-button>
        </div>
        <div class="rememberme">
          <a-checkbox v-model="form.rememberMe">记住密码</a-checkbox>
        </div>
        <div class="operate-btns">
          <!-- <a class="unlockAccount">解锁账户</a>
          <div class="divider"></div> -->
          <a class="forgetPassword" @click="handleForgetPwd">忘记密码?</a>
          <!-- <div class="divider"></div>
          <a class="help" >帮助</a> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { deepClone } from '@/libs/util.deepClone'
import { aesEncrypt } from '@/libs/crypto'
import util from '@/libs/util'
// import { TOKEN_KEY } from '@/maps/common';
import {
  getPassword,
  getRememberMe,
  getUsername,
  removePassword, removeRememberMe,
  removeUsername,
  setPassword, setRememberMe,
  setUsername,
} from '@/libs/util.auth.js'
// import { login, getUserInfo } from '@/api/login'
// import { TOKEN_KEY } from '@/maps/common';

export default {
  name: 'Login',
  data () {
    return {
      loginForm: this.$form.createForm(this, { name: 'loginForm' }),
      util,
      loading: false,
      ticket: '',
      uuid: '',
      messages: '',
      rememberme: true,
      loadingCaptcha: false,
      form: {
        // username: '',
        // password: '',
        rememberMe: false,
        // domain: 'Default',
      },
    }
  },
  created () {
    this.getCookie()
    // util.cookies.clearAll()
    // this.$store.dispatch('user/setToken', null)
    // this.$store.dispatch('user/setUserInfo', null)
    // this.$store.dispatch('system/setIsLogin', false)
  },
  methods: {
    getCookie () {
      const username = getUsername()
      const password = getPassword()
      const rememberMe = getRememberMe()
      this.$nextTick(() => {
        this.loginForm.setFieldsValue({
          username: username || '',
          password: password || '',
        })
        this.form.rememberMe = rememberMe || false
      })
      // this.form = {
      //   ...this.form,
      //   username: username || this.form.username,
      //   password: password || this.form.password,
      //   rememberMe: rememberMe || false,
      // }
    },
    /**
     * 校验表单
     */
    check () {
      // 账号密码登录
      this.loginForm.validateFields(err => {
        if (!err) {
          console.info('success')
          // 设置 Cookie
          if (this.form.rememberMe) {
            setUsername(this.loginForm.getFieldValue('username'))
            setPassword(this.loginForm.getFieldValue('password'))
            setRememberMe(this.form.rememberMe)
          } else {
            removeUsername()
            removePassword()
            removeRememberMe()
          }
          this.login()
        }
      })
      // this.$refs.form.validate((valid, err) => {
      //   if (valid) {
      //     this.messages = ''
      //     // 设置 Cookie
      //     if (this.form.rememberMe) {
      //       setUsername(this.form.username)
      //       setPassword(this.form.password)
      //       setRememberMe(this.form.rememberMe)
      //     } else {
      //       removeUsername()
      //       removePassword()
      //       removeRememberMe()
      //     }
      //     this.login()
      //   } else {
      //     if (err.password) {
      //       this.messages = err.password[0].message
      //     }
      //     if (err.username) {
      //       this.messages = err.username[0].message
      //     }
      //   }
      // })
    },
    /**
     * 账号密码登录
     */
    async login () {
      this.loading = true
      const data = {
        username: this.loginForm.getFieldValue('username'),
        password: this.loginForm.getFieldValue('password'),
      }
      data.password = aesEncrypt(data.password)
      // console.log('form password=', this.form.password)
      // console.log('aesEncrypt password=', subLogin.password)
      try {
        await this.$store.commit('auth/SET_LOGIN_FORM_DATA', data)
        await this.$store.dispatch('auth/login', data)
        await this.$emit('after-login')
        await this.$store.dispatch('auth/onAfterLogin')
      } catch (e) {
        this.loading = false
      }
      // login(subLogin)
      //   .then(res => {
      //     const { data } = res
      //     this.loginSuccess(data)
      //   })
      //   .catch(e => {
      //     const { msg } = e
      //     this.messages = msg
      //   })
      //   .finally(() => {
      //     this.loading = false
      //   })
    },
    /** 登录成功之后的处理 */
    // async loginSuccess (data) {
    //   const userinfo = await getUserInfo()
    //   console.log('userinfo=', userinfo)
    //   // // 设置用户token
    //   // console.log('-------------data.userInfo', data.userInfo);
    //   // // 存储在vuex中
    //   this.$store.dispatch('user/setToken', data + 'mytoken')
    //   // // 设置用户info
    //   this.$store.dispatch('user/setUserInfo', userinfo.data)
    //   // 设置系统为登录状态
    //   this.$store.dispatch('system/setIsLogin', true)
    //   // const redirect = decodeURIComponent(this.$route.query.redirect || '/');
    //   // if (redirect.includes('http')) {
    //   //   window.location.href = redirect;
    //   //   return;
    //   // }
    //   // this.goto(redirect);
    //   this.goto('/')
    // },
    /**
     * 跳转到某一个地址路由地址去
     */
    goto (path) {
      this.$router.push(path)
    },
    handleForgetPwd () {
      this.$router.push('/portal/contactus')
    },
  },
}
</script>
<style lang="scss" scoped>
.login-container {
  position: relative;
  display: flex;
  height: 100vh;
  overflow: hidden;

  .login-left {
    flex: 1;

    .login-img{
      width: 100%;
      height: 100%;
    }
  }

  .login-right{
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;

    .login-form-box {
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      width: 25.208vw;
      height: 33.333vw;
      padding: 0 1.667vw;
      border: 0.5px solid #E5E5E5;
      opacity: 1;

      .login-title {
        display: flex;
        justify-content: center;
        width: 100%;
        margin-top: 4.583vw;
        color: #222;
        font-weight: 500;
        font-size: 1.563vw;
        line-height: 1.875vw;
      }

      .login-form {
        width: 100%;
        margin-top: 3.125vw;

        ::v-deep{
          .ant-form-item  {
            margin-bottom: 1.25vw;
          }

          .ant-form-item-label > label {
            color: #999;
          }

          .ant-form-item-label{
            height: 1.146vw;
            margin-bottom: 0.417vw;
            color: #999;
            font-size: 0.729vw;
            line-height: 1.146vw;
            .ant-form-item-required{
              &::before {
                margin-right: 0;
                content: '';
              }
            }
          }

          .ant-input{
            width: 21.875vw;
            height: 2.5vw;
            background: #EEF0F5;
            border-radius: 0.208vw;
          }
        }
      }

      .login-btn{
        width: 100%;
        margin-top: 1.25vw;

        .btn{
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 2.5vw;
          background: #0066FE;
          border-radius: 0.208vw;
        }
      }

      .rememberme{
        height: 1.146vw;
        margin-top: 0.833vw;
        font-size: 0.729vw;
        line-height: 1.146vw;

        ::v-deep{
          .ant-checkbox-wrapper {
            color: #999;
          }
        }
      }

      .operate-btns{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 1.146vw;
        margin-top: 3.125vw;
        line-height: 1.146vw;

        a{
          color: #0066FE;
          font-weight: normal;
          font-size: 0.729vw;
        }

        .divider{
          width: 1px;
          height: 0.625vw;
          margin: 0 0.833vw;
          background: #646566;
        }
      }
    }

  }

}

</style>
