<template>
  <base-dialog @cancel="cancelDialog">
    <div slot="header">可用资源配置</div>
    <div slot="body">
      <a-form
        :form="form.fc"
        v-bind="formItemLayout">
        <a-row>
          <a-col :span="24">
            <a-form-item label="可用资源厂商">
              <a-checkbox-group
                v-decorator="decorators.providers"
                name="providers"
                :options="this.getDictDatas(DICT_TYPE.PROVIDER)"
                @change="onProvidersChange" />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="24">
            <a-form-item label="生效日期">
              <a-date-picker v-decorator="decorators.effect_at" @change="onValidDateChange" />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="24">
            <span class="info-title">可用资源</span>
          </a-col>
        </a-row>
        <a-row v-if="selectedProviders.includes('Huawei')">
          <a-col :span="24">
            <a-form-item label="华为云">
              <a-tree-select
                v-model="huaweicloud"
                style="width: 100%"
                :tree-data="treeObj['Huawei']"
                tree-checkable
                treeDefaultExpandAll
                search-placeholder="Please select" />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row v-if="selectedProviders.includes('Aliyun')">
          <a-col :span="24">
            <a-form-item label="阿里云">
              <a-tree-select
                v-model="aliyun"
                style="width: 100%"
                :tree-data="treeObj['Aliyun']"
                tree-checkable
                treeDefaultExpandAll
                search-placeholder="Please select" />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row v-if="selectedProviders.includes('Qcloud')">
          <a-col :span="24">
            <a-form-item label="腾讯云">
              <a-tree-select
                v-model="tencentcloud"
                style="width: 100%"
                :tree-data="treeObj['Qcloud']"
                tree-checkable
                treeDefaultExpandAll
                search-placeholder="Please select" />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row v-if="selectedProviders.includes('MgEcloud')">
          <a-col :span="24">
            <a-form-item label="移动云">
              <a-tree-select
                v-model="mgecloud"
                style="width: 100%"
                :tree-data="treeObj['MgEcloud']"
                tree-checkable
                treeDefaultExpandAll
                search-placeholder="Please select" />
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <div slot="footer">
      <a-button type="primary" @click="handleConfirm" :loading="loading">{{ $t('dialog.ok') }}</a-button>
      <a-button @click="cancelDialog">{{ $t('dialog.cancel') }}</a-button>
    </div>
  </base-dialog>
</template>

<script>
import DialogMixin from '@/mixins/dialog'
import WindowsMixin from '@/mixins/windows'

export default {
  name: 'AvailableResourceAllConfigUpdateForCustomerDialog',
  mixins: [DialogMixin, WindowsMixin],
  data () {
    return {
      details: {},
      selectedProviders: [],
      treeObj: {
        Huawei: [],
        Aliyun: [],
        Qcloud: [],
        MgEcloud: [],
      },
      aliyun: [],
      tencentcloud: [],
      huaweicloud: [],
      mgecloud: [],
      loading: false,
      form: {
        fc: this.$form.createForm(this),
        fi: {
          cloudaccount: {},
          project: {},
        },
      },
      projects: [],
      decorators: {
        providers: [
          'providers',
          {
            rules: [
              { required: true, message: '请选择厂商' },
            ],
          },
        ],
        effect_at: [
          'effect_at',
          {
            initialValue: this.$moment(),
            rules: [
              { required: true, message: '请选择生效日期' },
            ],
          },
        ],
      },
      formItemLayout: {
        wrapperCol: {
          span: 19,
        },
        labelCol: {
          span: 5,
        },
      },
    }
  },
  created () {
    this.loadTree()
  },
  methods: {
    loadTree () {
      this.$http.get('/v1/product_prices/tree', {
        params: { enabled: true, scope: 'system' },
      })
        .then((response) => {
          // console.log(response)
          const data = response.data
          // const data = res.data
          if (data && data.length > 0) {
            // console.log('loadTree data=', data)
            this.getDictDatas(this.DICT_TYPE.PROVIDER).forEach(provider => {
              const providerData = data.find(item => item.provider === provider.value)
              // console.log('loadTree providerData=', providerData)
              if (providerData) {
                const treeData = providerData.product_types.map(item => ({
                  key: item.product_type,
                  value: item.product_type,
                  label: item.product_type_name,
                  children: item.products.map(item => ({
                    key: item.product_id,
                    value: item.product_id,
                    label: item.product_name,
                  })),
                }))
                this.treeObj[provider.value] = treeData
              }
            })
          }
          // 设置详情
          this.params.details.id && this.loadDetail()
        })
    },
    loadDetail () {
      this.$http.get(`/v1/product_users/${this.params.details.id}`)
        .then((response) => {
          const data = response.data
          // console.log(data)
          this.details = data
          this.setDetail()
        })
    },
    setDetail () {
      this.$nextTick(() => {
        this.form.fc.setFieldsValue({
          providers: this.details.providers || [],
          effect_at: this.details.effect_at,
        })
        this.selectedProviders = this.details.providers || []
        const providers_products = this.details.providers_products || []
        this.aliyun = providers_products.find(item => item.provider === 'Aliyun')?.product_ids || []
        this.tencentcloud = providers_products.find(item => item.provider === 'Qcloud')?.product_ids || []
        this.huaweicloud = providers_products.find(item => item.provider === 'Huawei')?.product_ids || []
        this.mgecloud = providers_products.find(item => item.provider === 'MgEcloud')?.product_ids || []
      })
    },
    async saveOrUpdate (data) {
      try {
        if (this.params.details.id) {
          // return new this.$Manager('product_users', 'v1').update({ id: this.params.details.id, data })
          return this.$http.post(`/v1/product_users/${this.params.details.id}/patch`, data)
        } else {
          return new this.$Manager('product_users', 'v1').create({ data })
        }
      } catch (error) {
        throw error
      }
    },
    async handleConfirm () {
      // console.log('this.params=', this.params)
      if (this.selectedProviders.length === 0) {
        return this.$message.error('请选择可用资源')
      } else {
        if (this.selectedProviders.includes('Huawei') && this.huaweicloud.length === 0) { return this.$message.error('华为云可用资源不能为空') }
        if (this.selectedProviders.includes('Aliyun') && this.aliyun.length === 0) { return this.$message.error('阿里云可用资源不能为空') }
        if (this.selectedProviders.includes('Qcloud') && this.tencentcloud.length === 0) { return this.$message.error('腾讯云可用资源不能为空') }
        if (this.selectedProviders.includes('MgEcloud') && this.mgecloud.length === 0) { return this.$message.error('移动云可用资源不能为空') }
      }

      this.loading = true
      try {
        const { ...values } = await this.form.fc.validateFields()
        values.user_id = this.params.user.id
        values.providers_products = this.getProvidersProducts()
        await this.saveOrUpdate(values)
        this.$message.success('可用资源配置成功')
        this.cancelDialog()
        this.params.refresh()
      } finally {
        this.loading = false
      }
    },
    getProvidersProducts () {
      const result = []
      if (this.selectedProviders.includes('Aliyun')) {
        result.push({
          provider: 'Aliyun',
          product_ids: this.aliyun,
        })
      }
      if (this.selectedProviders.includes('Qcloud')) {
        result.push({
          provider: 'Qcloud',
          product_ids: this.tencentcloud,
        })
      }
      if (this.selectedProviders.includes('Huawei')) {
        result.push({
          provider: 'Huawei',
          product_ids: this.huaweicloud,
        })
      }
      if (this.selectedProviders.includes('MgEcloud')) {
        result.push({
          provider: 'MgEcloud',
          product_ids: this.mgecloud,
        })
      }
      return result
    },
    onProvidersChange (checkedValues) {
      console.log('checked = ', checkedValues)
      this.selectedProviders = checkedValues
      if (!this.selectedProviders.includes('Aliyun')) {
        this.aliyun = []
      }
      if (!this.selectedProviders.includes('Qcloud')) {
        this.tencentcloud = []
      }
      if (!this.selectedProviders.includes('Huawei')) {
        this.huaweicloud = []
      }
      if (!this.selectedProviders.includes('MgEcloud')) {
        this.mgecloud = []
      }
    },
    onValidDateChange (date, dateString) {
      console.log(date, dateString)
    },
  },
}
</script>
<style lang="scss" scoped>
.ant-form-item{
  margin-bottom: 0;
}
</style>
