<template>
  <base-dialog @cancel="cancelDialog">
    <div slot="header">授信额度-修改</div>
    <div slot="body">
      <a-form
        :form="form.fc"
        v-bind="formItemLayout">
        <a-row>
          <a-col :span="24">
            <span class="info-title">账号信息</span>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="12">
            <a-form-item label="ID">
              <span>{{ params.user.name }}</span>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="创建时间">
              <span>{{ $moment(params.user.created_at).format('YYYY-MM-DD HH:mm:ss') }}</span>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="12">
            <a-form-item label="手机号">
              <span>{{ params.user.mobile }}</span>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="备注">
              <span>{{ params.user.description }}</span>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="24">
            <span class="info-title">授信额度调整</span>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="12">
            <a-form-item label="账号总额度">
              <span>￥{{ params.details.amount | numerify('0,0.0000') }}</span>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="剩余可用额度">
              <span>￥{{ params.details.balance_amount | numerify('0,0.0000') }}</span>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="12">
            <a-form-item label="已用额度">
              <span>￥{{ params.details.use_amount | numerify('0,0.0000') }}</span>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="冻结额度">
              <span>￥{{ params.details.frozen_amount | numerify('0,0.0000') }}</span>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="12">
              <a-form-item label="调整额度为" class="mb-0">
                <a-input type="number" prefix="￥" v-decorator="decorators.amount" placeholder="">
                  <a-tooltip slot="suffix" title="新设置额度需≥已用额度+冻结额度">
                    <a-icon type="info-circle" style="color: rgba(0,0,0,.45)" />
                  </a-tooltip>
                </a-input>
              </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item>
              <span>新设置额度需≥已用额度+冻结额度</span>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <div slot="footer">
      <a-button type="primary" @click="handleConfirm" :loading="loading">{{ $t('dialog.ok') }}</a-button>
      <a-button @click="cancelDialog">{{ $t('dialog.cancel') }}</a-button>
    </div>
  </base-dialog>
</template>

<script>
import DialogMixin from '@/mixins/dialog'
import WindowsMixin from '@/mixins/windows'

export default {
  name: 'UserCreditesUpdateForCustomerDialog',
  mixins: [DialogMixin, WindowsMixin],
  data () {
    // const amountValidator = (rule, value, _callback) => {
    //   if (value >= this.params.details.use_amount + this.params.details.frozen_amount) {
    //     _callback()
    //   } else {
    //     _callback(new Error('新设置额度需≥已用额度+冻结额度'))
    //   }
    // }
    return {
      loading: false,
      form: {
        fc: this.$form.createForm(this),
        fi: {
          cloudaccount: {},
          project: {},
        },
      },
      projects: [],
      decorators: {
        amount: [
          'amount',
          {
            rules: [
              { required: true, message: '请输入调整额度' },
              // { validator: amountValidator() },
            ],
          },
        ],
      },
      formItemLayout: {
        wrapperCol: {
          span: 17,
        },
        labelCol: {
          span: 7,
        },
      },
    }
  },
  async created () {
    // console.log('this.params=', this.params)
    await this.$nextTick()
    this.form.fc.setFieldsValue(
      { amount: this.params.details.amount },
    )
  },
  methods: {
    formatNumber (value) {
      // 格式化数字，保留两位小数
      if (!isNaN(parseFloat(value))) {
        return parseFloat(value).toFixed(4)
      } else {
        return value
      }
    },
    async handleConfirm () {
      const value = this.form.fc.getFieldValue('amount')
      if (+value < this.params.details.use_amount + this.params.details.frozen_amount) {
        this.$message.error('新设置额度需≥已用额度+冻结额度')
        return
      }
      if (+value <= 0) {
        this.$message.error('新设置额度需大于0')
        return
      }
      // console.log('this.params=', this.params)
      this.loading = true
      try {
        const { ...values } = await this.form.fc.validateFields()
        const data = { user_id: this.params.user.id, amount: this.formatNumber(values.amount) }
        if (this.params.isNew) {
          await new this.$Manager('user_credits', 'v1').create({ data })
        } else {
          // await new this.$Manager('user_credits', 'v1').update({ id: this.params.details.id, data })
          await this.$http.post(`/v1/user_credits/${this.params.details.id}/patch`, data)
        }
        this.cancelDialog()
        // 刷新详情面板
        this.params.refresh()
      } finally {
        this.loading = false
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.ant-form-item{
  margin-bottom: 0;
}
</style>
