<template>
    <ul class="nav-list">
        <li
            class="nav-item"
            :class="{'active': activeIndex === index && isShow}"
            v-for="(nav,index) in navList"
            :key="index"
            @click="handleNavClick(nav)">
            <a-dropdown
              class="nav-item-dropdown"
              @visibleChange="dropdownShow($event,index)"
              overlayClassName="nav-item-dropdown-menu-wrapper"
              :ref="'cateDropdownRef'+index">
               <!-- 标题 -->
              <div class="nav-item-title">{{nav.name}}</div>
              <a-menu slot="overlay" class="nav-item-dropdown-menu" @click="handleMenuClick">
                <a-menu-item v-if="nav.componentName">
                  <div class="nav-item-plate">
                    <component :is="nav.componentName" />
                  </div>
                  <img class="icon-close" src="@/assets/image/icons/dropdown-close.png" @click="dropdownClose(index)" v-if="nav.componentName" />
                </a-menu-item>
              </a-menu>
            </a-dropdown>
        </li>
    </ul>
</template>

<script>
import MgProduct from './MgProduct'
import MgSupport from './MgSupport'
export default {
  name: 'MgNav',
  components: {
    MgProduct,
    MgSupport,
  },
  data () {
    return {
      navList: [
        { name: '产品', path: '/', componentName: 'MgProduct' },
        // { name: '云商店', path: '/' },
        { name: '服务与支持', path: '/', componentName: 'MgSupport' },
        // { name: '招投标中心', path: '/portal/bidding' },
        { name: '关于芒果云', path: '/portal/about' },
      ],
      activeIndex: -1,
      isShow: false,
    }
  },
  watch: {
    // activeIndex (newValue, oldValue) {
    //   if (newValue === 1) {
    //     this.$message.info('云商店构建中，敬请期待...')
    //   }
    // },
    $route (to, from) {
    //   console.log('router change');
      this.dropdownClose(this.activeIndex)
    },
  },
  methods: {
    dropdownShow (isShow, index) {
    //   console.log('dropdownShow', isShow, index);
      this.isShow = isShow
      this.activeIndex = index
      this.$emit('visible-change', isShow)
    },
    dropdownClose (index) {
      // this.$refs['cateDropdownRef' + index] && this.$refs['cateDropdownRef' + index][0].hide()
    },
    handleNavClick (nav) {
      if (nav.componentName) {
        return
      }
      this.$router.push(nav.path)
    },
    handleMenuClick () {},
  },
}
</script>
<style lang="scss">
.nav-item-dropdown-menu-wrapper{
  width:100%;
  left: 0 !important;
  top: 3.333vw !important;
  .nav-item-dropdown-menu{
    .ant-dropdown-menu-item{
      padding: 0 !important;
    }

  }

}
</style>

<style lang="scss" scoped>

.nav-item-dropdown-menu{
    left: 0 !important;
    width: 100%;
    margin: 0;
    padding: 0;
    border: none;
    border-radius: 0;
    box-shadow: 0 0.417vw 0.625vw 0 rgba(11, 58, 122, 0.3);

    .icon-close{
        position: absolute;
        top: 2.083vw;
        right: 10.313vw;
        width: 1.667vw;
        height: 1.667vw;
        cursor: pointer;
    }

    ::v-deep{
        .popper__arrow{
            display: none !important;
        }
    }

    .nav-item-plate{
        width: 100%;
        height: 17.917vw;
        color: #646566;
        background: #fff;
    }
}

.nav-list{
    display: flex;
    margin: 0;

    .nav-item{
        padding: 0 1.042vw;
        font-size: 0.729vw;
        cursor: pointer;
        list-style: none;

        .nav-item-title{
            height: 3.333vw;
            color: #646566;
            line-height: 3.333vw;
            text-align: center;
            user-select: none;
        }

        &.active{
            .nav-item-title{
                color: #0066FE;
                position: relative;

                &::after {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    display: inline-block;
                    width: 100%;
                    height: 0.104vw;
                    background: #0066FE;
                    border-radius: 0.417vw;
                    content: "";
                }
            }
        }

    }
}
</style>
