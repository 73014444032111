import {
  getNameDescriptionTableColumn,
  getEnabledTableColumn,
  // getProjectDomainTableColumn,
  getTimeTableColumn,
  // getTagTableColumn,
} from '@/utils/common/tableColumn'
import i18n from '@/locales'

export default {
  created () {
    this.columns = [
      getNameDescriptionTableColumn({
        onManager: this.onManager,
        title: '客户ID',
        hideField: true,
        formRules: [
          { required: true, message: i18n.t('system.text_168') },
        ],
        edit: row => row.idp_driver !== 'ldap',
        slotCallback: row => {
          return (
            <side-page-trigger onTrigger={ () => this.handleOpenSidepage(row) }>{ row.name }</side-page-trigger>
          )
        },
      }),
      // {
      //   field: 'name',
      //   title: '账号ID',
      //   slots: {
      //     default: ({ row }) => {
      //       return [<list-body-cell-wrap copy row={ row } field='name' title={ row.name } />]
      //     },
      //   },
      // },
      {
        field: 'mobile',
        title: '手机号',
        slots: {
          default: ({ row }) => {
            return [<list-body-cell-wrap copy row={ row } field='mobile' title={ row.mobile } />]
          },
        },
      },
      {
        field: 'sub_account_count',
        title: '子账号数',
        slots: {
          default: ({ row }) => {
            return [<list-body-cell-wrap row={ row } field='sub_account_count' title={ row.sub_account_count } />]
          },
        },
      },
      {
        field: 'amount',
        title: '授信额度',
        slots: {
          default: ({ row }) => {
            return [<list-body-cell-wrap row={ row } field='amount' title={ row.amount } />]
          },
        },
      },
      {
        field: 'business_type',
        title: '合作方式',
        slots: {
          default: ({ row }) => {
            let type = '-'
            if (row.business_type === 1) {
              type = '定向'
            }
            if (row.business_type === 2) {
              type = '竞标'
            }
            return [<span>{ type } </span>]
          },
        },
      },
      {
        field: 'second_price',
        title: '二次定价',
        slots: {
          default: ({ row }) => {
            return [<span>{ row.second_price ? '是' : '否' } </span>]
          },
        },
      },
      getEnabledTableColumn(),
      // {
      //   field: 'displayname',
      //   title: i18n.t('scope.text_245'),
      //   slots: {
      //     default: ({ row }) => {
      //       return [<list-body-cell-wrap copy row={ row } field='displayname' title={ row.displayname || '-' } />]
      //     },
      //   },
      // },
      // getTagTableColumn({
      //   onManager: this.onManager,
      //   resource: 'users',
      //   needExt: true,
      //   columns: () => this.columns,
      // }),
      // getEnabledTableColumn({
      //   field: 'allow_web_console',
      //   title: i18n.t('system.text_512'),
      // }),
      // getEnabledTableColumn({
      //   field: 'enable_mfa',
      //   title: 'MFA',
      // }),
      // getProjectDomainTableColumn(),
      getTimeTableColumn({
        title: '注册时间',
      }),
    ]
  },
}
