<template>
    <div class="banner-container">
        <div class="item-container">
            <img class="item-bg" :src="data.bgImgUrl" alt="" />
            <div class="item-text">
                <h3 class="item-title">{{data.title}}</h3>
                <p class="item-desc">{{data.desc}}</p>
                <div class="item-btns">
                    <div
                      class="item-btn item-primary"
                      :class="{'item-disabled': !data.buyUrl}"
                      @click="handleBuy(data.buyUrl)"
                      v-if="showBuyBtn">
                      {{ data.buyText || '立即购买' }}
                    </div>
                    <div
                      class="item-btn"
                      @click="handleDocDetail(data.space)"
                      v-if="!data.hideDoc">
                      {{ data.docText || '产品文档'}}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import paramsMixin from '@/mixins/paramsMixin'

export default {
  name: 'ProductBanner',
  mixins: [paramsMixin],
  props: {
    productStatus: {
      type: Boolean,
      default: true, // 默认资源可用
    },
  },
  data () {
    return {
      data: {
        // title: '弹性云服务器ECS',
        // desc: '提供安全稳定、可随时自助获取、弹性伸缩的计算服务，灵活计费，极简运维，极大降低企业成本。',
        // id: 22,
      },
    }
  },
  computed: {
    showBuyBtn () {
      if (this.productStatus) {
        return !this.data.hideBuy
      }
      return false
    },
  },
  methods: {
    handleBuy (url) {
      // 如果没登录，需要先跳转登录
      // if (!this.userInfo) {
      //   this.$router.push('/login')
      //   return
      // }
      if (url) {
        // window.location.href = url;
        // window.open(location.origin + url, '_blank');
        // window.open(location.origin + '/console/#' + url, '_blank')
        // 监测url是否含http或https
        if (url.indexOf('http') !== -1) {
          window.open(url, '_blank')
        } else {
          window.open(this.$router.resolve(url).href, '_blank')
        }
      }
    },
    handleDocDetail (space) {
      this.$router.push({
        name: 'documentsDetail',
        query: {
          space: space,
        },
      })
    },
  },

}
</script>

<style lang="scss" scoped>
.banner-container{
    width: 100%;
    height: 21.875vw;

    .item-container{
        position: relative;
        width: 100%;
        height: 100%;

        .item-img{
            position: absolute;
            top: 0;
            right: 18.75vw;
            z-index: 10;
            width: 44.375vw;
            height: 100%;
            object-fit: cover;
        }

        .item-bg{
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }

        .item-text{
            position: absolute;
            top: 50%;
            left: 18.75vw;
            z-index: 30;
            transform: translateY(-50%);

            .item-title{
                height: 2.5vw;
                color: #222;
                font-weight: 500;
                font-size: 2.083vw;
                line-height: 2.5vw;
            }

            .item-desc{
                width: 31.25vw;
                // height: 2.5vw;
                margin-top: 0.833vw;
                color: #646566;
                font-weight: normal;
                font-size: 0.729vw;
                line-height: 1.25vw;
                opacity: 0.5;
            }

            .item-btns{
                display: flex;
                height: 2.188vw;
                margin-top: 2.083vw;

                .item-btn{
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    width: 5.625vw;
                    height: 2.188vw;
                    border-radius: 1.302vw;
                    margin-right: 0.833vw;
                    color: #0066FE;
                    font-size: 0.729vw;
                    line-height: 1.146vw;
                    text-align: center;
                    border: 0.078vw solid #0066FE;
                    cursor: pointer;

                    &.item-primary{
                        color: #fff;
                        background: #0066FE;
                    }
                    &.item-disabled{
                      user-select: none;
                      cursor: not-allowed;
                      color: #646566;
                      background: rgba(0, 0, 0, 0.06);
                      border: none;
                    }
                }
            }

        }

    }

}
</style>
