// 门户配置参数混入
const paramsMixin = {
  props: {
    params: {
      type: String,
      default: '',
    },
  },
  data () {
    return {
      data: {},
    }
  },
  mounted () {
    // console.log(this.params, JSON.stringify(this.data));
  },
  watch: {
    params: {
      immediate: true,
      deep: true,
      handler (newValue, oldValue) {
        if (this.params) {
          this.data = JSON.parse(newValue)
        }
      },
    },
  },
}

export default paramsMixin
