<template>
    <div class="mg-product">
        <div class="main-box">
            <div class="left">
                <div class="categories">
                    <div
                        class="category"
                        v-for="(item,index) in categories"
                        :key="index"
                        @mouseenter="currentIndex = index"
                        :class="{active:currentIndex === index}">{{item.name}}</div>
                </div>
            </div>
            <div class="right">
                <product-menu :title="currentName" :menus="currentMenus" @menu-click="handleMenuClick" />
            </div>
        </div>
    </div>
</template>

<script>
import ProductMenu from '@/components/Menu'
import { hotProductMenus, mediaProductMenus, cloudProductMenus } from '@/maps/common'
// import { list } from '@/api/menu'

export default {
  name: 'MgProduct',
  components: {
    ProductMenu,
  },
  data () {
    return {
      categories: [
        {
          name: '热门产品',
          path: '/',
          menus: hotProductMenus,
        },
        {
          name: '媒体服务',
          path: '/',
          menus: mediaProductMenus,
        },
        {
          name: '云计算基础',
          path: '/',
          menus: cloudProductMenus,
        },
      ],
      currentIndex: 0,
      currentName: '热门产品',
      currentMenus: hotProductMenus,
    }
  },
  watch: {
    currentIndex (newValue, oldValue) {
    //   console.log('this.categories[newValue]=', this.categories[newValue]);
      this.currentName = this.categories[newValue].name
      // TODO 当currentIndex变化时，需要重新获取数据
      this.currentMenus = this.categories[newValue].menus
    },
  },
  created () {
    // this.getMenus();
  },
  methods: {
    filterOpenData (data) {
      const filteredData = []

      data.forEach(item => {
        if (item.is_open === 2) {
          filteredData.push(item)
        }

        if (item.child_tree) {
          const filteredChildTree = this.filterOpenData(item.child_tree)
          if (filteredChildTree.length > 0) {
            item.children = filteredChildTree
            if (!filteredData.some(e => e.id === item.id)) {
              filteredData.push(item)
            }
          }
        }
      })

      return filteredData
    },
    filterHotData (data) {
      const filteredData = []

      data.forEach(item => {
        if (item.is_hot === 1) {
          filteredData.push(item)
        }

        if (item.child_tree) {
          const filteredChildTree = this.filterHotData(item.child_tree)
          if (filteredChildTree.length > 0) {
            item.children = filteredChildTree
            if (!filteredData.some(e => e.id === item.id)) {
              filteredData.push(item)
            }
          }
        }
      })

      return filteredData
    },
    // getMenus () {
    //   list().then((res) => {
    //     console.log('getMenuList res= ', res)
    //     const data = res.data
    //     console.log('data= ', data)
    //     const filteredData = this.filterOpenData(data)
    //     console.log('filteredData= ', filteredData)
    //     const hotData = this.filterHotData(filteredData)
    //     console.log('hotData= ', JSON.stringify(hotData))
    //     this.currentMenus = filteredData
    //     // this.categories = filteredData.map(item => {
    //     //   if (item.name === '热门产品') {
    //     //     item.children = hotMenus;
    //     //   }
    //     //   return item;
    //     // });
    //   })
    // },
    handleMenuClick (item) {
      this.$router.push({
        name: 'products',
        params: {
          id: item.id,
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.mg-product{
    height: 100%;
    padding: 0 10.313vw;

    .main-box{
        display: flex;
        width: 100%;
        height: 100%;

        .left{
            box-sizing: border-box;
            width: 21.25vw;
            height: 100%;
            border-right: 1px solid #D8D8D8;

            .categories{
                display: flex;
                flex-direction: column;
                width: 100%;
                height: 100%;
                margin-top: 1.458vw;

                .category{
                    width: 100%;
                    height: 2.5vw;
                    padding-left: 0.521vw;
                    color: #999;
                    font-size: 0.833vw;
                    line-height: 2.5vw;
                    background: #fff;
                    cursor: pointer;

                    &.active{
                        color: #222;
                        background: #EEF0F5;
                    }
                }
            }
        }

        .right{
            flex: 1;
            height: 100%;
        }
    }
}
</style>
