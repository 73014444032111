/**
 * Created by lanzhiming
 *
 * 数据字典工具类
 */
import store from '@/store'

export const DICT_TYPE = {
  PROVIDER: 'provider', // 厂商
  // ========== PRODUCT 模块 ==========
  PRODUCT_TYPE: 'product_type', // 产品类型
  SERVER: 'server', // 计算
  STORAGE: 'storage', // 存储
  NETWORK: 'network', // 网络
  CHARGE_TYPE: 'charge_type', // 计费类型
  // ========== CUSTOMER 模块 ==========
  TRANSACTION_TYPE: 'transaction_type', // 交易类型
  CAPITAL_FORM: 'capital_form', // 资金形式
}

/**
 * 获取 dictType 对应的数据字典数组
 *
 * @param dictType 数据类型
 * @returns {*|Array} 数据字典数组
 */
export function getDictDatas (dictType) {
  return store.getters.dict_datas[dictType] || []
}

/**
 * 获取 dictType 对应的数据字典数组
 *
 * @param dictType 数据类型
 * @param values 数组、单个元素
 * @returns {*|Array} 数据字典数组
 */
export function getDictDatas2 (dictType, values) {
  if (values === undefined) {
    return []
  }
  // 如果是单个元素，则转换成数组
  if (!Array.isArray(values)) {
    values = [this.value]
  }
  // 获得字典数据
  const results = []
  for (const value of values) {
    const dict = getDictData(dictType, value)
    if (dict) {
      results.push(dict)
    }
  }
  return results
}

export function getDictData (dictType, value) {
  // 获取 dictType 对应的数据字典数组
  const dictDatas = getDictDatas(dictType)
  if (!dictDatas || dictDatas.length === 0) {
    return ''
  }
  // 获取 value 对应的展示名
  value = value + '' // 强制转换成字符串，因为 DictData 小类数值，是字符串
  for (const dictData of dictDatas) {
    if (dictData.value === value) {
      return dictData
    }
  }
  return undefined
}

export function getDictDataLabel (dictType, value) {
  const dict = getDictData(dictType, value)
  return dict ? dict.label : ''
}
