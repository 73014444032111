<template>
<div class="header" :class="{'header-active':isNavShow}">
    <div class="header-left">
        <div class="logo-box" @click="$router.push('/')">
            <img class="logo-img" src="@/assets/image/logo.png" alt="" />
            <span class="logo-text">芒果云</span>
        </div>
        <mg-nav class="nav-list-box" @visible-change="handleNavShow" />
    </div>
    <div class="header-right">
        <a class="console" @click.prevent="handleGoConsole">控制台</a>
        <a-button class="login-btn" type="primary" v-if="!username" @click="login">登录</a-button>
        <div class="userinfo" v-else>
            <!-- 用户信息 -->
            <a-dropdown :trigger="['click']">
              <span class="public-flex-center">
                  <span class="ellipsis mw100" :title="username">
                      {{ username| nameFilter }}
                  </span>
                  <a-icon type="down" class="public-ml16" />
              </span>
              <a-menu slot="overlay" @click="onClickLogoutHandler">
                <a-menu-item key="logout">
                  退出
                </a-menu-item>
              </a-menu>
            </a-dropdown>
        </div>
    </div>
</div>
</template>

<script>
import { mapGetters } from 'vuex'
import MgNav from './MgNav'
// import util from '@/libs/util'
// import { logout } from '@/api/login';
// import { TOKEN_KEY } from '@/maps/common';
export default {
  name: 'LayoutHeader',
  components: {
    MgNav,
  },
  filters: {
    nameFilter (val) {
      const compare = val
      let result = ''
      if (compare && compare.length > 12) {
        result = compare.substr(0, 12) + '...'
      } else {
        result = compare
      }
      return result
    },
  },
  data () {
    return {
      isNavShow: false,
    }
  },
  computed: {
    ...mapGetters(['userInfo']),
    username () {
      return this.userInfo.displayname || this.userInfo.name
    },
    // ...mapState('user', ['userInfo']),
  },
  async created () {
    // await this.$store.dispatch('auth/getInfo')
  },
  methods: {
    handleNavShow (isShow) {
      this.isNavShow = isShow
    },
    login () {
      // this.$router.push('/login?redirect=' + encodeURIComponent(window.location.href));
      // this.$router.push('/login')
      this.$router.push('/auth/login')
    },
    handleGoConsole () {
      // this.$router.push('/dashboard')
      window.open(this.$router.resolve('/dashboard').href, '_blank')
    },
    async onClickLogoutHandler ({ key }) {
      // 登出命令
      if (key === 'logout') {
        await this.$store.dispatch('auth/logout')
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 3.333vw;
    padding: 0 8.333vw;

    &.header-active{
      box-shadow: 0 1px 0.417vw 0 rgba(0, 0, 0, 0.08);
    }

    .header-left{
        display: flex;
        align-items: center;

        .logo-box{
            display: flex;
            align-items: center;
            cursor: pointer;

            .logo-img{
                width: 2.5vw;
                height: 2.5vw;
            }

            .logo-text{
                height: 1.042vw;
                color: #3D3D3D;
                font-weight: 500;
                font-size: 1.042vw;
                font-family: "AlibabaPuHuiTi2", sans-serif;
                line-height: 1.042vw;
                opacity: 1;
            }
        }

        .nav-list-box{
            margin-left: 1.042vw;
        }
    }

    .header-right{
        display: flex;
        align-items: center;

        .ellipsis {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        .login-btn{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 3.75vw;
            height: 2.188vw;
            margin-left: 3.125vw;
            padding: 1.042vw;
            color: #FFF;
            font-weight: 500;
            font-size: 0.833vw;
            line-height: 1.25vw;
            background: #0066FE;
        }

        .userinfo{
            margin-left: 1.042vw;
            color: #646566;
        }
        .console{
          color: #646566;
        }
    }
}
</style>
