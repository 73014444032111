<template>
  <base-dialog @cancel="cancelDialog">
    <div slot="header">{{title}}</div>
    <div slot="body">
      <a-form
        :form="form.fc"
        v-bind="formItemLayout">
        <a-row>
          <a-col :span="24">
            <a-form-item label="字典类型">
              <span>{{$route.params.dictType}}</span>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="24">
            <a-form-item label="字典标签">
              <a-input v-decorator="decorators.name" />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="24">
            <a-form-item label="字典标签英文">
              <a-input v-decorator="decorators.en" />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="24">
            <a-form-item label="字典值">
              <a-input v-decorator="decorators.dict_value" />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="24">
            <a-form-item label="字典排序">
              <a-input-number v-decorator="decorators.priority" />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="24">
            <a-form-item label="状态">
              <a-switch default-checked v-decorator="decorators.enabled" />
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <div slot="footer">
      <a-button type="primary" @click="handleConfirm" :loading="loading">{{ $t('dialog.ok') }}</a-button>
      <a-button @click="cancelDialog">{{ $t('dialog.cancel') }}</a-button>
    </div>
  </base-dialog>
</template>

<script>
import DialogMixin from '@/mixins/dialog'
import WindowsMixin from '@/mixins/windows'

export default {
  name: 'DictDataUpdateDialog',
  mixins: [DialogMixin, WindowsMixin],
  data () {
    return {
      loading: false,
      form: {
        fc: this.$form.createForm(this),
        fi: {
        },
        fd: {
          id: '',
        },
      },
      decorators: {
        name: [
          'name',
          {
            rules: [
              { required: true, message: '请输入字典标签' },
            ],
          },
        ],
        en: [
          'en',
          {
            rules: [
              { required: true, message: '请输入字典标签英文' },
            ],
          },
        ],
        dict_value: [
          'dict_value',
          {
            rules: [
              { required: true, message: '请输入字典值' },
            ],
          },
        ],
        priority: [
          'priority',
          {
            initialValue: 100,
            rules: [
              { required: true, message: '请输入字典排序' },
            ],
          },
        ],
        enabled: [
          'enabled',
          {
            initialValue: true,
          },
        ],
      },
      formItemLayout: {
        wrapperCol: {
          span: 19,
        },
        labelCol: {
          span: 5,
        },
      },
    }
  },
  computed: {
    title () {
      return this.params.data ? '编辑字典数据' : '新增字典数据'
    },
  },
  async created () {
    if (this.params && this.params.data) {
      this.form.fd = this.params.data
      await this.$nextTick()
      this.form.fc.setFieldsValue({
        name: this.form.fd.name,
        en: this.form.fd.en,
        dict_value: this.form.fd.dict_value,
        priority: this.form.fd.priority,
        enabled: this.form.fd.enabled,
      })
    }
  },
  methods: {
    async saveOrUpdate (data) {
      try {
        if (this.form.fd.id) {
          return new this.$Manager('dict_datas').update({ id: this.form.fd.id, data })
        } else {
          return new this.$Manager('dict_datas').create({ data })
        }
      } catch (error) {
        throw error
      }
    },
    async handleConfirm () {
      console.log('this.params=', this.params)
      this.loading = true
      try {
        const { ...values } = await this.form.fc.validateFields()
        // values.id = this.params.data.id
        values.cn = values.name
        values.dict_type = this.$route.params.dictType
        await this.saveOrUpdate(values)
        this.cancelDialog()
        this.params.list.refresh()
      } finally {
        this.loading = false
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.ant-form-item{
  margin-bottom: 0;
}
</style>
