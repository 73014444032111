import http from '@/utils/http'

const state = {
  /**
   * 数据字典 MAP
   * key：数据字典大类枚举值 dictType
   * dictValue：数据字典小类数值 {dictValue: '', dictLabel: ''} 的数组
   */
  dictDatas: {},
}

const mutations = {
  SET_DICT_DATAS: (state, dictDatas) => {
    state.dictDatas = dictDatas
  },
}

const actions = {
  loadDictDatas ({ commit }, scope) {
    http({
      method: 'GET',
      url: '/v1/dict_datas',
      params: {
        scope: scope,
        show_fail_reason: true,
        details: false,
        limit: 1000000,
        enabled: true,
        dict_type_enabled: true,
      },
    })
      .then(res => {
        const response = res.data
        // console.log('response=', response)
        // 如果未加载到数据，则直接返回
        if (!response || !response.data) {
          return
        }
        // 设置数据
        const dictDataMap = {}
        response.data.forEach(dictData => {
        // 获得 dictType 层级
          const enumValueObj = dictDataMap[dictData.dict_type]
          if (!enumValueObj) {
            dictDataMap[dictData.dict_type] = []
          }
          // 处理 dictValue 层级
          dictDataMap[dictData.dict_type].push({
            value: dictData.dict_value,
            label: dictData.name || dictData.cn,
            priority: dictData.priority,
          })
        })
        // 排序
        Object.keys(dictDataMap).forEach(key => {
          dictDataMap[key].sort((a, b) => a.priority - b.priority)
        })

        // 存储到 Store 中
        commit('SET_DICT_DATAS', dictDataMap)
      })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
