<template>
    <div class="product-system">
        <div class="title">完善的媒体云产品体系</div>
        <div class="intro">围绕传媒行业，助力企业实现数字化转型</div>
        <div class="product-item">
            <img class="img2" src="https://img.cdn.mg.xyz/mgcloud/photo/image/about/about-system-bg.jpg" alt="" />
            <div class="content" v-if="false">
                <!-- <div class="name">云+公益</div> -->
                <div class="desc-title">免费对象</div>
                <div class="desc">中国民政部门下合法注册成立的非盈利性，非党派性，非成员组织，实现自主管理的，致力于社会公益事业和解决各种社会问题的民间志愿性社会中介组织。</div>
                <div class="desc-title">扶持内容</div>
                <div class="desc">每年2000元云资源扶持额度，按年申请，超出部分需自行支付。</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'ProductSystem',
}
</script>

<style lang="scss" scoped>
.product-system{
  display: flex;
  flex-direction: column;
  height: 31.25vw;
  padding: 0 18.75vw;
  background: #FFF;

  .title{
    height: 1.875vw;
    margin-top: 2.5vw;
    color: #222;
    font-weight: 500;
    font-size: 1.563vw;
    line-height: 1.875vw;
    text-align: center;
  }

  .intro{
    height: 1.146vw;
    margin-top: 1.25vw;
    color: #646566;
    font-weight: normal;
    font-size: 0.729vw;
    line-height: 1.146vw;
    text-align: center;
  }

  .list{
    display: flex;
    justify-content: space-between;
    margin-top: 1.25vw;

    .item{
      width: 11.667vw;
      height: 20.833vw;

      .img{
        width: 100%;
        height: 100%;
        border-radius: 0.313vw;
      }
    }
  }

  .product-item{
    display: flex;
    justify-content: space-between;
    height: 16.667vw;
    margin-top: 1.667vw;

    .img{
      width: 30.208vw;
      height: 16.667vw;
    }

    .img2{
      width: 100%;
      height: 16.667vw;
    }

    .content{
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 30.208vw;
      height: 16.667vw;
      padding: 2.917vw 4.167vw;

      .name{
        color: #3D3D3D;
        font-weight: 500;
        font-size: 0.938vw;
        line-height: 1.458vw;
        letter-spacing: 0;
      }

      .desc-title{
        margin-top: 1.042vw;
        color: #3D3D3D;
        font-weight: 500;
        font-size: 0.729vw;
        line-height: 1.146vw;
        letter-spacing: 0;
      }

      .desc{
        margin-top: 0.208vw;
        color: #646566;
        font-weight: normal;
        font-size: 0.729vw;
        line-height: 1.146vw;
      }

    }
  }
}
</style>
