<template>
  <base-dialog @cancel="cancelDialog">
    <div slot="header">配置可用区域</div>
    <div slot="body">
      <a-form
        :form="form.fc"
        v-bind="formItemLayout">
        <a-row>
          <a-col :span="12">
            <a-form-item label="厂商">
              <span>{{ params.row.provider_name }}</span>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="计费方式">
              <span>{{ this.getDictDataLabel(DICT_TYPE.CHARGE_TYPE,params.row.charge_type) }}</span>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="12">
            <a-form-item label="类型">
              <span>{{ params.row.product_type_name }}</span>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="资源">
              <span>{{ params.row.product_name }}</span>
            </a-form-item>
          </a-col>
        </a-row>

        <a-row>
          <a-col :span="24">
            <span class="info-title">可用区域选择</span>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="24">
            <a-checkbox-group
              v-model="region"
              name="region"
              @change="onRegionChange"
              class="checkbox-item">
              <a-row>
                <a-col
                  v-for="item in regionOptions"
                  :key="item.value"
                  :span="24">
                  <a-checkbox :value="item.value" class="checkbox-item-content">
                    <span :title="item.label">{{ item.label }}</span>
                  </a-checkbox>
                </a-col>
              </a-row>
            </a-checkbox-group>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <div slot="footer">
      <a-button type="primary" @click="handleConfirm" :loading="loading">确认可用区域</a-button>
      <a-button @click="cancelDialog">{{ $t('dialog.cancel') }}</a-button>
    </div>
  </base-dialog>
</template>

<script>
import DialogMixin from '@/mixins/dialog'
import WindowsMixin from '@/mixins/windows'

export default {
  name: 'AvailableRegionConfigUpdateForCustomerDialog',
  mixins: [DialogMixin, WindowsMixin],
  data () {
    return {
      details: {},
      region: [],
      regionOptions: [
        // { label: '华东-杭州1-1（阿里云-华东1（杭州）-可用区A）', value: '华东-杭州1-1（阿里云-华东1（杭州）-可用区A）' },
        // { label: '华东-杭州1-2（阿里云-华东1（杭州）-可用区B）', value: '华东-杭州1-2（阿里云-华东1（杭州）-可用区B）' },
        // { label: '华东-杭州1-3（阿里云-华东1（杭州）-可用区C）', value: '华东-杭州1-3（阿里云-华东1（杭州）-可用区C）' },
        // { label: '华东-上海1-1（阿里云-华东2（上海）-可用区A）', value: '华东-上海1-1（阿里云-华东2（上海）-可用区A）' },
        // { label: '华东-上海1-2（阿里云-华东2（上海）-可用区F）', value: '华东-上海1-2（阿里云-华东2（上海）-可用区F）' },
        // { label: '华东-上海1-3（阿里云-华东2（上海）-可用区G）', value: '华东-上海1-3（阿里云-华东2（上海）-可用区G）' },
      ],
      loading: false,
      form: {
        fc: this.$form.createForm(this),
        fi: {
          cloudaccount: {},
          project: {},
        },
      },
      decorators: {
        amount: [
          'amount',
          {
            rules: [
              { required: true, message: '请输入调整额度' },
            ],
          },
        ],
      },
      formItemLayout: {
        wrapperCol: {
          span: 17,
        },
        labelCol: {
          span: 7,
        },
      },
    }
  },
  created () {
    console.log('this.params.user=', this.params.user)
    this.loadAreaMappings()
  },
  methods: {
    loadAreaMappings () {
      this.$http
        .get(`/v2/area_mappings/provider?provider=${this.params.row.provider}`)
        .then((response) => {
          const data = response.data
          // console.log(data)
          if (data && data.length > 0) {
            this.regionOptions = data.map((item) => ({
              label:
                item.mapping_name +
                '（' +
                item.provider_name +
                '（' +
                item.region_name +
                '）-' +
                item.zone_name +
                '）',
              value: item.id,
            }))
            this.loadProductUserAreas()
          }
        })
    },
    loadProductUserAreas () {
      this.$http
        .get(`/v1/product_user_areas?user_id=${this.params.user.id}&product_price_id=${this.params.row.id}`)
        .then((response) => {
          const res = response.data
          const data = res.data
          // console.log('loadProductUserAreas', data)
          if (data && data.length > 0) {
            this.details = data[0]
            const area_ids =
              data[0].area_ids?.map((item) => parseInt(item, 10)) || []
            this.region = area_ids
          } else {
            this.details = {}
            this.region = []
          }
        })
    },
    async saveOrUpdate (data) {
      try {
        if (this.details.id) {
          return new this.$Manager('product_user_areas', 'v1').update({
            id: this.details.id,
            data,
          })
        } else {
          return new this.$Manager('product_user_areas', 'v1').create({ data })
        }
      } catch (error) {
        throw error
      }
    },
    async handleConfirm () {
      this.loading = true
      try {
        const values = {
          user_id: this.params.user.id,
          product_price_id: this.params.row.id,
          area_ids: this.region,
        }
        await this.saveOrUpdate(values)
        this.$message.success('可用区域配置成功')
        this.cancelDialog()
        this.params.refresh()
      } finally {
        this.loading = false
      }
    },
    onRegionChange (checkedValues) {
      console.log('checked = ', checkedValues)
      this.selectedFactories = checkedValues
    },
  },
}
</script>
<style lang="scss" scoped>
.ant-form-item {
  margin-bottom: 0;
}
.checkbox-item {
    width: 96%;
    margin-left: 10px;
    .checkbox-item-content{
      width: 100%;
      overflow: hidden; /*超出隐藏*/
      text-overflow: ellipsis;/*隐藏后添加省略号*/
      white-space: nowrap;/*强制不换行*/
    }
  }
</style>
