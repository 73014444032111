// import { getEnabledSwitchActions } from '@/utils/common/tableActions'
import i18n from '@/locales'
export default {
  created () {
    this.singleActions = [
      // {
      //   label: '修改',
      //   permission: 'product_prices_update',
      //   action: row => {
      //     // 跳转详情
      //     this.$router.push({
      //       path: '/product-price/createOrUpdate',
      //       query: {
      //         id: row.id,
      //       },
      //     })
      //   },
      // },
      {
        label: '定价设置',
        permission: 'product_prices_update',
        action: row => {
          let basePriceResource = '' // 默认配置，获取基础价格,按用量，按规格
          let resource = '' // 默认配置，按量付费时，按流量
          let extResource = '' // 表格2
          const product_code = row.product_code
          switch (product_code) {
            case 'ecs':
              resource = 'resource_bill_ecs_prices/ecs-prices'
              break
            case 'disk':
              resource = 'resource_bill_ebs_prices/ebs-prices'
              break
            case 'eip':
              resource = 'resource_bill_eip_prices/eip-instance-price'
              extResource = 'resource_bill_eip_prices/eip-network-price'
              break
            case 'slb':
              basePriceResource = 'v2/resource_bill_slb_lcu_prices/base-price'
              resource = 'resource_bill_slb_lcu_prices/slb-price-list'
              extResource = 'resource_bill_slb_spec_prices/slb-network-price-list'
              break
            case 'nat_gateway':
              resource = 'resource_bill_nat_gateway_prices/nat-gateway-price'
              break
            default:
              break
          }
          // 定价设置
          this.createDialog('ProductPricingUpdateDialog', {
            refresh: this.refresh,
            row: row,
            basePriceResource: basePriceResource,
            resource: resource,
            extResource: extResource,
          })
        },
        meta: row => {
          return {
            validate: row && row.product_status,
          }
        },
      },
      {
        label: i18n.t('system.text_153'),
        actions: row => {
          return [
            {
              label: '上架',
              permission: 'product_prices_perform_enable',
              action: () => {
                this.onManager('performAction', {
                  id: row.id,
                  managerArgs: {
                    action: 'enable',
                  },
                })
              },
              meta: row => {
                return {
                  validate: row && !row.enabled && row.product_status,
                }
              },
            },
            {
              label: '下架',
              permission: 'product_prices_perform_disable',
              action: () => {
                this.$confirm({
                  content: '下架该计算资源定价后，该资源在租户端及超管端将不可新建，请谨慎操作',
                  okText: '确认下架',
                  onOk: () => {
                    // 用户确认取消
                    this.onManager('performAction', {
                      id: row.id,
                      managerArgs: {
                        action: 'disable',
                      },
                    })
                  },
                  onCancel: () => {
                  },
                })
              },
              meta: row => {
                return {
                  validate: row && row.enabled && row.product_status,
                }
              },
            },
            {
              label: i18n.t('compute.perform_delete'),
              permission: 'product_prices_delete',
              action: obj => {
                this.createDialog('DeleteResDialog', {
                  vm: this,
                  data: [obj],
                  columns: this.columns,
                  title: i18n.t('compute.perform_delete'),
                  onManager: this.onManager,
                  name: '删除定价',
                })
              },
              meta: obj => {
                if (obj.enabled) {
                  return {
                    validate: false,
                    tooltip: '已上架',
                  }
                } else {
                  return {
                    validate: true,
                    tooltip: '',
                  }
                }
              },
            },
          ]
        },
      },
      // {
      //   label: '新建',
      //   // permission: 'product_create',
      //   action: obj => {
      //     this.$router.push({
      //       path: '/product/create',
      //       query: {
      //         id: obj.id,
      //       },
      //     })
      //   },
      //   // meta: obj => {
      //   //   const isReady = obj.status === 'ready'
      //   //   return {
      //   //     validate: isReady,
      //   //     tooltip: !isReady && i18n.t('compute.text_1048'),
      //   //   }
      //   // },
      // },

    ]
  },
}
