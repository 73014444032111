<template>
    <div class="banner-container">
        <div class="item-container">
            <img class="item-bg" src="https://img.cdn.mg.xyz/mgcloud/photo/image/about/about-banner-bg.jpg" alt="" />
            <div class="item-text">
                <h3 class="item-title">{{data.title}}</h3>
                <p class="item-desc">{{data.desc}}</p>
            </div>
        </div>
    </div>
</template>

<script>

export default {
  name: 'AboutBanner',
  data () {
    return {
      data: {
        title: '芒果云 | 提供领先的媒体云服务',
        desc: '芒果云，提供领先的云计算、媒体云等技术产品与服务，以卓越的科技能力打造丰富的传媒行业解决方案，构建开放共赢的云端生态，推动产业互联网建设，助力传媒相关行实现数字化升级。',
        // id: 22,
      },
    }
  },

}
</script>

<style lang="scss" scoped>
.banner-container{
    width: 100%;
    height: 21.875vw;

    .item-container{
        position: relative;
        width: 100%;
        height: 100%;

        .item-img{
            position: absolute;
            top: 0;
            right: 18.75vw;
            z-index: 10;
            width: 44.375vw;
            height: 100%;
            object-fit: cover;
        }

        .item-bg{
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            z-index: 20;
            width: 100%;
            height: 100%;
        }

        .item-text{
            position: absolute;
            top: 50%;
            left: 18.75vw;
            z-index: 30;
            transform: translateY(-50%);

            .item-title{
                height: 2.5vw;
                color: #FFF;
                font-weight: 500;
                font-size: 2.083vw;
                line-height: 2.5vw;
            }

            .item-desc{
                width: 31.25vw;
                height: 2.5vw;
                margin-top: 0.833vw;
                color: #FFF;
                font-weight: normal;
                font-size: 0.833vw;
                line-height: 1.25vw;
                opacity: 0.5;
            }

            .item-btns{
                display: flex;
                margin-top: 2.083vw;

                .item-btn{
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    width: 5.625vw;
                    height: 2.188vw;
                    margin-right: 0.833vw;
                    color: #FFF;
                    font-size: 0.729vw;
                    line-height: 1.146vw;
                    text-align: center;
                    border: 0.078vw solid #FFF;
                    cursor: pointer;

                    &.item-primary{
                        color: #000;
                        background: #FFF;
                    }
                }
            }

        }

    }

}
</style>
