// 订单状态选项
export const ORDER_STATUS_MAP = {
  paid: {
    label: '已支付',
    key: '1',
  },
  toBePaid: {
    label: '待支付',
    key: '2',
  },
  fail: {
    label: '创建失败',
    key: '3',
  },
  processing: {
    label: '处理中',
    key: '4',
  },
  cancel: {
    label: '作废',
    key: '5',
  },
  refund: {
    label: '退款',
    key: '6',
  },
}

// 订单类型选项
export const ORDER_TYPE_MAP = {
  buy: {
    label: '新购',
    key: '1',
  },
  renew: {
    label: '续费',
    key: '2',
  },
  up: {
    label: '升级',
    key: '3',
  },
  down: {
    label: '降级',
    key: '4',
  },
  refund: {
    label: '退款',
    key: '5',
  },
  change: {
    label: '变更',
    key: '6',
  },
}
