<template>
    <div class="after-sales">
        <div class="title">售后服务</div>
        <div class="list">
            <div class="item" v-for="(item,index) in list" :key="index">
                <div class="content">
                  <div class="name">{{item.name}}</div>
                  <div class="desc"> {{item.desc}}</div>
                  <a href="#" class="more" v-if="item.showMore">了解更多 <a-icon type="right" /></a>
                  <div class="more" v-else>{{item.moreText}}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'AfterSales',
  data () {
    return {
      list: [
        {
          name: '智能客服',
          desc: '智能诊断，极速解答为您极速定位问题、解答问题',
          showMore: true,
          moreText: '',
        },
        {
          name: '自助服务',
          desc: 'TOP问题解决方案、高效便捷工具助您高效完成产品运维',
          showMore: true,
          moreText: '',
        },
        {
          name: '工单',
          desc: '云产品技术问题、云业务问题，专业工程师为您即时解答',
          showMore: true,
          moreText: '',
        },
        {
          name: '专业服务',
          desc: '为您提供上云实施、云上管理、专家服务、职业培训的全流程专业服务，加速实现您的业务',
          showMore: true,
          moreText: '',
        },
        {
          name: '云声|建议与反馈',
          desc: '您的宝贵建议，使我们提供更完美的产品与服务',
          showMore: true,
          moreText: '',
        },
        {
          name: '联系方式',
          desc: '热线电话 | 邮件服务',
          showMore: false,
          moreText: '0731-88888888',
        },
      ],
    }
  },
}
</script>

<style lang="scss" scoped>
.after-sales{
  display: flex;
  flex-direction: column;
  height: 20.833vw;
  padding: 0 18.75vw;
  background: #FFF;

  .title{
    height: 1.875vw;
    margin-top: 2.5vw;
    color: #222;
    font-weight: 500;
    font-size: 1.563vw;
    line-height: 1.875vw;
    text-align: center;
  }

  .list{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1.25vw 3.125vw;
    margin-top: 1.667vw;

    .item{
      width: 18.75vw;
      height: 5.521vw;

      .content{
        display: flex;
        flex-direction: column;

        .name{
          height: 1.146vw;
          color: #3D3D3D;
          font-weight: 500;
          font-size: 0.729vw;
          line-height: 1.146vw;
        }

        .desc{
          margin-top: 0.417vw;
          color: #646566;
          font-weight: normal;
          font-size: 0.729vw;
          line-height: 1.146vw;
        }

        .more{
          margin-top: 0.417vw;
          color: #0066FE;
          font-weight: normal;
          font-size: 0.625vw;
          line-height: 0.938vw;

        }
      }
    }
  }
}
</style>
