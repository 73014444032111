<template>
  <base-dialog @cancel="cancelDialog" :width="1200">
    <div slot="header">公告配置-查看</div>
    <div slot="body">
      <a-form
        :form="form.fc"
        v-bind="formItemLayout">

        <a-row>
          <a-col :span="24">
            <a-form-item label="名称">
              <span>{{ params.row.name }}</span>
            </a-form-item>
          </a-col>
          <a-col :span="24">
            <a-form-item label="内容">
              <div v-html="params.row.content" ref="richTextContainer" />
            </a-form-item>
          </a-col>
          <a-col :span="24">
            <a-form-item label="附件">
              <a-upload :defaultFileList="fileList" disabled @preview="handleDownload" />
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <div slot="footer">
      <a-button @click="cancelDialog">取消</a-button>
    </div>
  </base-dialog>
</template>

<script>
import DialogMixin from '@/mixins/dialog'
import WindowsMixin from '@/mixins/windows'
import { downloadFile } from '~/utils/utils'

export default {
  name: 'PortalAnnouncementViewDialog',
  mixins: [DialogMixin, WindowsMixin],
  data () {
    return {
      loading: false,
      form: {
        fc: this.$form.createForm(this),
        fi: {},
      },
      formItemLayout: {
        wrapperCol: {
          span: 20,
        },
        labelCol: {
          span: 4,
        },
      },
    }
  },
  computed: {
    fileList () {
      const annexs = this.params.row.annexs
      if (annexs) {
        return annexs.split(',').map(item => {
          const name = item.includes('@@@') ? item.split('@@@')[1] : item
          return {
            uid: 'upload-' + this.params.row.id,
            name: name,
            status: 'done',
            url: this.params.row.cdn_uri + '/' + this.params.row.name + '/' + item,
          }
        })
      } else {
        return []
      }
    },
  },
  mounted () {
    this.$nextTick(() => {
      const tables = this.$refs.richTextContainer.querySelectorAll('table')
      tables.forEach(table => {
        table.classList.add('bidding-detail-content-table')
        table.classList.add('bidding-detail-content-table-border')
        const trs = table.querySelectorAll('tr')
        trs.forEach(tr => {
          tr.classList.add('bidding-detail-content-table-border')
          const tds = tr.querySelectorAll('td')
          tds.forEach(td => {
            td.classList.add('bidding-detail-content-table-border')
          })
          const ths = tr.querySelectorAll('th')
          ths.forEach(th => {
            th.classList.add('bidding-detail-content-table-border')
          })
        })
      })
    })
  },
  methods: {
    handleDownload (file) {
      // console.log('file', file)
      try {
        downloadFile(file.url, file.name)
      } catch (err) {
        console.log('下载失败', err)
        this.$message.error('下载失败')
      }
    },

  },
}
</script>
<style lang="scss" scoped>
.ant-form-item{
  margin-bottom: 0;
}
</style>
