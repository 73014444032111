<template>
  <div class="bidding-list">
    <div class="title">{{ currentTitle }}</div>
    <div class="filter-box" v-if="false">
      <div class="filter-label">信息筛选</div>
      <div class="filter-list">
        <div
          class="filter-item"
          :class="{ active: activeIndex === index }"
          v-for="(item, index) in filterList"
          :key="index"
          @click="handleFilter(item, index)">
          {{ item.name }}
        </div>
      </div>
    </div>
    <div class="table-box">
      <a-table :columns="columns" :data-source="tableData" :pagination="false">
        <a slot="name" slot-scope="text, record" class="name-link" @click.prevent="handleNameClick(record)">{{ text }}</a>
        <span slot="publicityf_at" slot-scope="text">{{ moment(text).format('YYYY-MM-DD') }}</span>
      </a-table>
      <a-pagination
        class="bidding-pagination"
        v-model="currentPage"
        :total="total"
        :pageSizeOptions="pageSizes"
        :page-size.sync="currentPagesize"
        @change="handleCurrentChange"
        @showSizeChange="handleSizeChange"
        show-size-changer
        show-quick-jumper />
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import http from '@/utils/http'
export default {
  name: 'BiddingList',
  data () {
    return {
      moment,
      columns: [
        {
          title: '信息名称',
          dataIndex: 'name',
          key: 'name',
          scopedSlots: { customRender: 'name' },
        },
        // {
        //   title: '所属地区',
        //   dataIndex: 'area',
        //   key: 'area',
        // },
        // {
        //   title: '来源',
        //   dataIndex: 'source',
        //   key: 'source',
        // },
        {
          title: '发布时间',
          dataIndex: 'publicityf_at',
          key: 'publicityf_at',
          scopedSlots: { customRender: 'publicityf_at' },
        },
        // {
        //   title: '投标截止时间',
        //   dataIndex: 'biddingEndTime',
        //   key: 'biddingEndTime',
        // },
      ],
      currentTitle: '全部信息',
      filterList: [
        { name: '全部信息', value: '0' },
        { name: '招标公告', value: '1' },
        { name: '中标公告', value: '2' },
        { name: '其他信息', value: '3' },
      ],
      activeIndex: 0,
      tableData: [],
      pageSizes: [20, 30, 50, 100],
      currentPage: 1,
      currentPagesize: 20,
      total: 0,
    }
  },
  mounted () {
    this.loadTableData()
  },
  methods: {
    loadTableData () {
      // 发送请求获取表格数据
      const params = {
        // offset: (this.currentPage - 1) * this.currentPagesize,
        // limit: this.currentPagesize,
        // filter: this.filterList.find(item => item.name === this.currentTitle)?.value,
        currentPage: this.currentPage,
        pageSize: this.currentPagesize,
      }
      http.get('/v1/bill-web/notice/list', { params: params })
        .then((response) => {
          const res = response.data
          console.log(res)
          this.tableData = res.data.list || []
          this.total = res.data.total
        })

      // const res = {
      //   data: {
      //     total: 1,
      //     list: [
      //       {
      //         id: 1,
      //         name: '芒果幻视2024年视频云直播转码服务项目公开甄选的公告',
      //         area: '湖南长沙',
      //         // source: '湖南芒果融创科技有限公司',
      //         source: '湖南芒果幻视科技有限公司',
      //         publishTime: '2024-03-15 12:00',
      //         biddingEndTime: '2024-03-25 12:00',
      //       },
      //     ],
      //   },
      // }
      // if (this.activeIndex > 1) {
      //   this.tableData = []
      //   this.total = 0
      //   return
      // }
      // this.tableData = res.data.list
      // this.total = res.data.total
    },
    handleFilter (item, index) {
      this.activeIndex = index
      this.currentTitle = item.name
      this.loadTableData()
    },
    handleNameClick (item) {
      console.log(item)
      this.$router.push({
        path: '/portal/biddingDetail',
        query: { id: item.id },
      })
    },
    handleSizeChange () {
      this.currentPage = 1
      this.loadTableData()
    },
    handleCurrentChange () {
      this.loadTableData()
    },
  },
}
</script>

<style lang="scss" scoped>
.bidding-list {
  display: flex;
  flex-direction: column;
  padding: 2.5vw 18.75vw;
  background: #fff;

  .title {
    height: 1.875vw;
    color: #222;
    font-weight: 500;
    font-size: 1.563vw;
    line-height: 1.875vw;
  }

  .filter-box {
    display: flex;
    margin-top: 1.875vw;

    .filter-label {
      display: inline-block;
      height: 1.979vw;
      padding: 0.417vw 1.25vw;
      color: #646566;
      font-size: 0.729vw;
      text-align: center;
    }

    .filter-list {
      display: flex;
      gap: 0.625vw;

      .filter-item {
        display: inline-block;
        box-sizing: border-box;
        height: 1.979vw;
        padding: 0.417vw 1.25vw;
        color: #646566;
        font-size: 0.729vw;
        text-align: center;
        border: 1px solid #646566;
        border-radius: 0.208vw;
        cursor: pointer;

        &.active {
          border: 1px solid #0066fe;
          background: rgba(118, 170, 250, 0.3);
        }
      }
    }
  }

  .table-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 1.875vw;
    border: 1px solid #E5E5E5;
    padding: 0 0.625vw;

    .bidding-table {
      width: 100%;
      height: 18.698vw;

      .name-link{
        color: #195AC3;
        &:hover{
          color: #195AC3;
        }
      }
    }

    .bidding-pagination {
      display: flex;
      justify-content: center;
      width: 100%;
      height: 2.5vw;
      margin-top: 5.208vw;
    }
  }
}
</style>
