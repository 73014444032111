<template>
    <div class="banner-container">
        <div class="item-container">
            <img class="item-bg" src="https://img.cdn.mg.xyz/mgcloud/photo/image/contactus/contactus-bg.png" alt="" />
            <div class="item-text">
                <h3 class="item-title">联系我们</h3>
                <p class="item-desc">获取专业工程师的帮助，快速解决您的问题</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'Banner',
}
</script>

<style lang="scss" scoped>
.banner-container{
    width: 100%;
    height: 13.958vw;

    .item-container{
        position: relative;
        width: 100%;
        height: 100%;

        .item-img{
            position: absolute;
            top: 0;
            right: 18.75vw;
            z-index: 10;
            width: 44.375vw;
            height: 100%;
            object-fit: cover;
        }

        .item-bg{
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            z-index: 20;
            width: 100%;
            height: 100%;
        }

        .item-text{
            position: absolute;
            top: 50%;
            left: 18.75vw;
            z-index: 30;
            transform: translateY(-50%);

            .item-title{
                height: 2.5vw;
                color: #fff;
                font-weight: 500;
                font-size: 2.083vw;
                line-height: 2.5vw;
            }

            .item-desc{
                width: 31.25vw;
                height: 2.5vw;
                margin-top: 0.833vw;
                color: #fff;
                font-weight: normal;
                font-size: 0.833vw;
                line-height: 1.25vw;
            }

        }

    }

}
</style>
