<template>
  <base-side-page
    @cancel="cancelSidePage"
    :title="$t('cost.text_1')"
    icon="res-secgroup"
    :res-name="detailData.name"
    :actions="params.actions"
    :current-tab="params.windowData.currentTab"
    :tabs="detailTabs"
    :loaded="loaded"
    @tab-change="handleTabChange">
    <!-- <template v-slot:actions>  详情跳转按钮注释掉
     {{singleActions}} <actions :options="singleActions" :row="detailData" button-type="link" button-size="small" />
    </template> -->
    <component
      :is="params.windowData.currentTab"
      :res-id="data.id"
      :orderProductList="params.orderProductList"
      :data="detailData" />
  </base-side-page>
</template>

<script>
import SidePageMixin from '@/mixins/sidePage'
import WindowsMixin from '@/mixins/windows'
import Actions from '@/components/PageList/Actions'
import SingleActionsMixin from '../mixins/singleActions'
import OrderDetail from './Detail'

export default {
  name: 'OrderSidePage',
  components: {
    OrderDetail,
    Actions,
  },
  mixins: [SidePageMixin, WindowsMixin, SingleActionsMixin],
  data () {
    const detailTabs = [
      { label: this.$t('compute.text_238'), key: 'order-detail' },
    ]
    return {
      detailTabs,
    }
  },
  computed: {
    getParams () {
      return null
    },
  },
  created () {
    if (this.params.tab) this.handleTabChange(this.params.tab)
  },
}
</script>
