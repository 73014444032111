<template>
  <div class="pricing-mode">
    <div class="left">
      <div class="title">整体定价</div>
      <div class="text">芒果云定价x</div>
      <a-input-number v-model="rate" @change="handleChange" />
      <div class="text">倍</div>
    </div>
    <div class="right">
      <a-button type="primary" class="btn-save" :disabled="disabled" @click="handleSave">保存整体定价</a-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PricingMode',
  data () {
    return {
      rate: 1,
      disabled: true,
    }
  },
  methods: {
    handleChange (val) {
      this.disabled = false
    },
    handleSave () {
      // this.$emit('save', this.rate)
      this.disabled = true
    },
  },
}
</script>

<style lang="scss" scoped>
.pricing-mode {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
  .left{
    display: flex;
    align-items: center;
    .title{
      font-weight: 600;
      margin-right: 20px;
    }
    .text{
      color: #333;
      margin-right: 10px;
    }
  }
  // .right{
  // }
}
</style>
