// ========== 账号相关 ==========
import { Base64 } from 'js-base64'
const UsernameKey = 'USERNAME'
const PasswordKey = 'PASSWORD'
const RememberMeKey = 'REMEMBER_ME'

export function getUsername () {
  return localStorage.getItem(UsernameKey)
}

export function setUsername (username) {
  localStorage.setItem(UsernameKey, username)
}

export function removeUsername () {
  localStorage.removeItem(UsernameKey)
}

export function getPassword () {
  const password = localStorage.getItem(PasswordKey)
  return password ? Base64.decode(password) : undefined
}

export function setPassword (password) {
  localStorage.setItem(PasswordKey, Base64.encode(password))
}

export function removePassword () {
  localStorage.removeItem(PasswordKey)
}

export function getRememberMe () {
  return localStorage.getItem(RememberMeKey) === 'true'
}

export function setRememberMe (rememberMe) {
  localStorage.setItem(RememberMeKey, rememberMe)
}

export function removeRememberMe () {
  localStorage.removeItem(RememberMeKey)
}
