<template>
  <base-dialog @cancel="cancelDialog">
    <div slot="header">配置可用地域{{ providerName }}</div>
    <div slot="body">
      <a-form
        :form="form.fc"
        v-bind="formItemLayout">
        <a-row>
          <a-col :span="24">
            <a-checkbox-group
              v-model="region"
              name="region"
              @change="onRegionChange"
              class="checkbox-item">
              <a-row>
                <a-col
                  v-for="item in regionOptions"
                  :key="item.id"
                  :span="24">
                  <a-checkbox :value="item.id" class="checkbox-item-content" @change="onChange">
                    <span :title="item.name">{{ item.name }}</span>
                  </a-checkbox>
                </a-col>
              </a-row>
            </a-checkbox-group>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <div slot="footer">
      <a-button type="primary" @click="handleConfirm" :loading="loading">确认配置</a-button>
      <a-button @click="cancelDialog">{{ $t('dialog.cancel') }}</a-button>
    </div>
  </base-dialog>
</template>

<script>
import _ from 'lodash'
import DialogMixin from '@/mixins/dialog'
import WindowsMixin from '@/mixins/windows'

export default {
  name: 'AvailableAreaConfigUpdateDialog',
  mixins: [DialogMixin, WindowsMixin],
  data () {
    return {
      regionBackup: [],
      region: [],
      regionOptions: [],
      addedSet: new Set(),
      removedSet: new Set(),
      loading: false,
      form: {
        fc: this.$form.createForm(this),
        fi: {
          cloudaccount: {},
          project: {},
        },
      },
      formItemLayout: {
        wrapperCol: {
          span: 17,
        },
        labelCol: {
          span: 7,
        },
      },
    }
  },
  computed: {
    providerName () {
      const item = this.getDictDatas(this.DICT_TYPE.PROVIDER).find(item => item.value === this.params.row.provider)
      if (item) return '-' + item?.label
      return ''
    },
  },
  created () {
    this.loadAreaConfig()
  },
  methods: {
    loadAreaConfig () {
      this.$http
        .get(`/v2/cloudregions/provider-region-list?provider=${this.params.row.provider}`)
        .then((response) => {
          const { data } = response.data
          // console.log(data)

          if (data && data.length > 0) {
            this.regionOptions = data
            const region = data.filter(item => item.checked).map(item => item.id)
            // const region = [1000]
            this.regionBackup = _.cloneDeep(region)
            this.region = region
          }
        })
    },
    async update (data) {
      try {
        // /{{localhosts}}/api/v2/cloudregions/provider-region-after
        this.$http.post('/v2/cloudregions/provider-region-after', data)
      } catch (error) {
        throw error
      }
    },
    async handleConfirm () {
      this.loading = true
      try {
        if (_.isEqual(this.region, this.regionBackup)) {
          this.$message.warning('未做任何修改')
          return
        }
        // console.log('addedSet = ', this.addedSet, 'removedSet = ', this.removedSet)
        const addedValues = this.regionOptions.filter(v => this.addedSet.has(v.id)).map(v => {
          return {
            ...v,
            checked: true,
          }
        })
        const removedValues = this.regionOptions.filter(v => this.removedSet.has(v.id)).map(v => {
          return {
            ...v,
            checked: false,
          }
        })
        // console.log('addedValues = ', addedValues, 'removedValues = ', removedValues)
        const values = [...addedValues, ...removedValues]
        await this.update(values)
        this.$message.success('可用地域配置成功')
        this.cancelDialog()
        this.params.refresh()
      } finally {
        this.loading = false
      }
    },
    onRegionChange (checkedValues) {
      console.log('checkedValues = ', checkedValues)
    },
    onChange (e) {
      console.log('one checked = ', e.target.value)
      const value = e.target.value
      if (e.target.checked) {
        // 判断是否之前本来就是属于勾选的
        if (!this.regionBackup.includes(value)) {
          this.addedSet.add(value)
        } else {
          this.removedSet.delete(value)
        }
      } else {
        if (!this.regionBackup.includes(value)) {
          this.addedSet.delete(value)
          return
        }
        // 取消勾选，先询问
        this.$confirm({
          // title: '确认取消勾选?',
          content: '取消配置该地域，可能会影响部分资源的上架，确认配置吗？',
          okText: '确认配置',
          onOk: () => {
            // 用户确认取消勾选
            this.removedSet.add(value)
          },
          onCancel: () => {
            // 用户取消，保持当前选中状态
            this.region.push(value)
          },
        })
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.ant-form-item {
  margin-bottom: 0;
}
.checkbox-item {
    width: 96%;
    margin-left: 10px;
    .checkbox-item-content{
      width: 100%;
      overflow: hidden; /*超出隐藏*/
      text-overflow: ellipsis;/*隐藏后添加省略号*/
      white-space: nowrap;/*强制不换行*/
    }
  }
</style>
