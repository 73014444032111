<template>
    <div class="application-scene" :class="data.style" :style="{'background': data.bgColor ? data.bgColor : '#FFFFFF'}">
        <div class="title">{{data.title}}</div>
        <div class="intro" v-if="data.intro">{{data.intro}}</div>
        <div class="tab-header" v-if="data.tabs.length > 1">
            <div class="tab" v-for="(item,index) in data.tabs" :key="index" :class="{active:activeIndex===index}" @click="changeTab(index)">
              {{item.label}}
            </div>
        </div>
        <div class="tab-content">
          <div class="left">
             <el-image
              v-if="activeTab.img"
              style="width: 100%; height: 100%"
              :src="activeTab.img"
              :preview-src-list="[activeTab.img]" />
              <!-- <img style="width: 100%; height: 100%" :src="activeTab.img" /> -->
          </div>
          <div class="right">
            <div class="content">
              <div class="content-title">{{activeTab.content.introTitle || '场景概述'}}</div>
              <div class="content-intro">{{activeTab.content.intro}}</div>
              <div class="content-title">{{activeTab.content.provideTitle || '我们能提供'}}</div>
              <div class="provide-list">
                <div class="provide-item" v-for="(item,index) in activeTab.content.provideList" :key="index">
                  <img class="img" src="@/assets/image/products/provide-icon.png" alt="" />
                  <div class="provide-item-content">
                    <div class="provide-item-title" v-if="item.name">{{item.name}}</div>
                    <div class="provide-item-desc" v-if="item.desc">{{item.desc}}</div>
                  </div>
                </div>
              </div>
              <template v-if="activeTab.content.relateProducts">
                <div class="content-title">{{activeTab.content.relateTitle || '相关产品'}}</div>
                <div class="relate-products">
                  <div
                    class="relate-product"
                    v-for="(item,index) in activeTab.content.relateProducts"
                    :key="index"
                    @click="goToProduct(item)">
                    <img class="relate-icon" :src="item.icon" alt="" v-if="item.icon" />
                    <!-- <img class="relate-icon" src="@/assets/image/products/icon-relate-product.png" alt=""> -->
                    <span class="relate-text">{{item.name}}</span>
                  </div>
                </div>
              </template>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
import paramsMixin from '@/mixins/paramsMixin'
export default {
  name: 'ApplicationScene',
  mixins: [paramsMixin],
  data () {
    return {
      list: [],
      activeIndex: 0,
      activeTab: {
        content: {
          provideList: [],
        },
      }, // TODO 获取后立马赋值
    }
  },
  watch: {
    data (newValue, oldValue) {
      this.activeTab = newValue.tabs[0]
      this.activeIndex = 0
    },
  },
  mounted () {
    this.activeIndex = 0
    this.activeTab = this.data.tabs[0]
  },
  methods: {
    changeTab (index) {
      this.activeIndex = index
      this.activeTab = this.data.tabs[index]
    },
    goToProduct (item) {
      if (item.productId === 0) return
      this.$router.push({
        name: 'products',
        params: {
          id: item.productId,
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.application-scene{
  display: flex;
  flex-direction: column;
  padding: 2.5vw 18.75vw;

  .title{
    height: 1.875vw;
    color: #222;
    font-weight: 500;
    font-size: 1.563vw;
    line-height: 1.875vw;
    text-align: center;
  }

  .intro{
    height: 1.146vw;
    margin-top: 1.25vw;
    color: #646566;
    font-weight: normal;
    font-size: 0.729vw;
    line-height: 1.146vw;
    text-align: center;
  }

  .tab-header{
    display: flex;
    gap: 2.5vw;
    justify-content: center;
    width: 100%;
    height: 2.083vw;
    margin-top: 2.5vw;
    border-bottom: 1px solid #E5E5E5;

    .tab{
      height: 2.031vw;
      padding-bottom: 0.781vw;
      color: #646566;
      font-size: 0.833vw;
      line-height: 1.25vw;
      cursor: pointer;
    }

    .active{
      position: relative;
      color: #0066FE;
      font-weight: 500;

      &::after {
        position: absolute;
        bottom: 0;
        left: 0;
        display: inline-block;
        width: 100%;
        height: 0.104vw;
        background: #0066FE;
        border-radius: 0.417vw;
        content: "";
      }
    }
  }

  .tab-content{
    display: flex;
    margin-top: 1.25vw;
    border: 0.078vw solid #E5E5E5;
    // box-shadow: 0 0 0.417vw 0 rgba(0, 0, 0, 0.2);

    .left{
      width: 37.5vw;
      border-right: 1px solid #E5E5E5;

      img{
        width: 100%;
        height: 100%;
      }
    }

    .right{
      flex: 1;
      padding-bottom: 2.083vw;

      .content{
        display: flex;
        flex-direction: column;
        padding: 2.083vw 1.25vw 0;

        .content-title{
          height: 1.146vw;
          color: #222222;
          font-weight: 500;
          font-size: 0.833vw;
          line-height: 1.146vw;
        }

        .content-intro{
          margin-top: 0.833vw;
          margin-bottom: 1.25vw;
          color: #646566;
          font-weight: normal;
          font-size: 0.729vw;
          line-height: 1.146vw;
        }

        .provide-list{
          display: flex;
          flex-direction: column;
          margin-bottom: 1.25vw;

          .provide-item{
            display: flex;
            width: 100%;
            margin-top: 0.833vw;

            .img{
              width: 0.833vw;
              height: 0.833vw;
            }

            .provide-item-content{
              display: flex;
              flex-direction: column;
              margin-left: 0.417vw;

              .provide-item-title{
                color: #3D3D3D;
                font-weight: 500;
                font-size: 0.729vw;
                line-height: 1.146vw;
              }

              .provide-item-desc{
                color: #646566;
                font-weight: normal;
                font-size: 0.729vw;
                line-height: 1.146vw;
              }

            }
          }
        }

        .relate-products{
          display: flex;
          gap: 0.833vw;
          margin-top: 0.833vw;

          .relate-product{
            display: flex;
            flex-direction: column;
            gap: 0.208vw;
            align-items: center;
            justify-content: center;
            box-sizing: border-box;
            width: 5.417vw;
            height: 4.271vw;
            border: 1px solid #E5E5E5;
            border-radius: 0.208vw;
            cursor: pointer;

            .relate-icon{
              width: 1.875vw;
              height: 1.875vw;
            }

            .relate-text{
              margin-top: 0.208vw;
              color: #222;
              font-weight: normal;
              font-size: 0.625vw;
              line-height: 0.938vw;
              text-align: center;
            }

            &:hover{
              box-shadow: 0 0.104vw 0.833vw 0 rgba(0, 96, 254, 0.2);

              .relate-text{
                color: #0066FE;
              }
            }
          }
        }
      }
    }
  }

}
.style1{
  .tab-content{
    border: none;
    .right{
      .content{
        padding-top: 0;
      }
    }
  }
}
</style>
