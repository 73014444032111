<template>
  <div class="detail-container">
    <div class="left">
      <div class="aside-box">
        <div class="title">{{title}}</div>
        <div class="page-title">{{nowSpaceShow.name}}</div>
        <div class="wiki-page-tree-box">
          <el-tree
            ref="wikiPageTreeRef"
            :current-node-key="nowPageId"
            :data="wikiPageList"
            :default-expanded-keys="wikiPageExpandedKeys"
            highlight-current
            :expand-on-click-node="true"
            :props="defaultProps"
            node-key="id"
            @node-click="handleNodeClick" />
        </div>
      </div>
    </div>
    <div class="right">
      <div class="doc-menu-main" v-if="!pageShowDetail">
        <div class="doc-title">{{nowSpaceShow.name}}</div>
        <div class="doc-menu-panel" v-for="item in wikiPageList" :key="item.id">
          <div class="doc-menu-title">{{item.name}}</div>
          <ul class="doc-menu-list">
            <template v-if="item.children">
              <li class="menu-list-item" v-for="c in item.children" :key="c.id" @click="handleNodeClick(c)">
                {{c.name}}
              </li>
            </template>
            <template v-else>
              <li class="menu-list-item" @click="handleNodeClick(item)">
                {{item.name}}
              </li>
            </template>
          </ul>
        </div>
      </div>
      <div class="wiki-page-detail-box" v-else>
        <div class="page-share-view-vue">
          <a-row type="border-card">
            <a-col :xs="24" :sm="20" :md="20" :lg="18" :xl="18">
              <div style="max-width: 1000px; margin: 0 auto; padding-left: 10px">
                <div class="wiki-title" ref="wikiTitleRef">{{ wikiPage.name }}</div>
                <div class="wiki-author">
                  <span v-if="wikiPage.updateTime">最后修改：{{ wikiPage.updateTime }}</span>
                  <span v-else>创建时间：{{ wikiPage.createTime }}</span>
                </div>
                <div ref="pageContentRef" class="wiki-page-content">
                  <div v-html="pageShowDetail" class="markdown-body" v-if="wikiPage.editorType == 2" />
                  <div v-html="pageShowDetail" class="wang-editor-body" v-else />
                </div>

              </div>
            </a-col>
            <a-col :xs="0" :sm="4" :md="4" :lg="6" :xl="6">
              <navigation :heading="navigationList" />
            </a-col>
          </a-row>
          <el-image-viewer
            v-if="showImagePreview"
            :on-close="()=>{showImagePreview=false}"
            :url-list="showImagePreviewList"
            :initialIndex="previewInitialIndex"
            :z-index="99999" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
import { mavonEditor } from 'mavon-editor'
import 'mavon-editor/dist/markdown/github-markdown.min.css'
import 'mavon-editor/dist/css/index.css'
import Navigation from '@/components/Navigation'
import htmlUtil from '@/libs/HtmlUtil'
import * as pageApi from '@/api/documents'

const computeFileSize = (fileSize) => {
  if (!fileSize) {
    return '-'
  }
  let size = ''
  if (fileSize < 0.1 * 1024) {
    size = fileSize.toFixed(2) + 'B'
  } else if (fileSize < 0.1 * 1024 * 1024) {
    size = (fileSize / 1024).toFixed(2) + 'KB'
  } else if (fileSize < 0.1 * 1024 * 1024 * 1024) {
    size = (fileSize / (1024 * 1024)).toFixed(2) + 'MB'
  } else {
    size = (fileSize / (1024 * 1024 * 1024)).toFixed(2) + 'GB'
  }
  const sizeStr = size + ''
  const index = sizeStr.indexOf('.')
  const dou = sizeStr.substr(index + 1, 2)
  if (dou === '00') {
    return sizeStr.substring(0, index) + sizeStr.substr(index + 3, 2)
  }
  return size
}

export default {
  name: 'DocDetail',
  components: {
    ElImageViewer,
    Navigation,
  },
  props: {
    title: {
      type: String,
      default: '文档',
    },
    space: {
      type: String,
      default: '',
    },
  },
  data () {
    return {
      nowPageId: '',
      nowSpaceShow: {},
      wikiPageList: [],
      wikiPageExpandedKeys: [],
      wikiPage: {},
      pageFileList: [],
      pageShowDetail: '',
      searchKeywords: '',
      defaultProps: {
        children: 'children',
        label: 'name',
      },
      navigationList: [],
      computeFileSize: computeFileSize,
      previewInitialIndex: 0,
      showImagePreview: false,
      showImagePreviewList: [],
    }
  },
  watch: {
    $route (newValue, oldValue) {
      this.getSpaceInfo()
      this.doGetPageList()
      // this.initQueryParam();
    },
  },
  mounted () {
    this.getSpaceInfo()
    this.doGetPageList()
    this.initQueryParam()
  },
  methods: {
    getSpaceInfo () {
      const data = new URLSearchParams()
      data.append('space', this.space)
      pageApi.openSpaceInfo(data).then(json => {
        this.nowSpaceShow = json.data
        console.log('this.nowSpaceShow', this.nowSpaceShow)
      })
    },
    doGetPageList () {
      const data = new URLSearchParams()
      data.append('space', this.space)
      pageApi.openPageList(data).then(json => {
        this.wikiPageList = json.data || []
        console.log('this.wikiPageList', this.wikiPageList)
        // this.nowPageId = '';
        this.initQueryParam()
      })
    },
    handleNodeClick (data) {
      console.log('点击节点：', data)
      if (this.nowPageId === data.id || data.editorType === 0) {
        return
      }
      this.nowPageId = data.id
      this.$router.push({
        name: 'documentsDetail',
        query: { pageId: data.id, space: this.space },
      })
      this.wikiPageExpandedKeys = [data.id]
      // this.loadPageDetail(this.nowPageId);
    },
    initQueryParam () {
      // console.log('this.$route.query', this.$route.query);
      if (this.$route.query.pageId) {
        const pageId = this.$route.query.pageId
        this.nowPageId = +pageId
        this.wikiPageExpandedKeys = [this.nowPageId]
        this.loadPageDetail(this.nowPageId)
        this.$nextTick(() => {
          this.$refs.wikiPageTreeRef.setCurrentKey(this.nowPageId)
        })
      } else {
        // console.log('----默认');
        const firstNode = this.wikiPageList[0] || {}
        if (firstNode.children) {
          this.wikiPageExpandedKeys = [firstNode.children[0].id]
        }
      }
    },
    loadPageDetail (pageId) {
      // const param = { pageId: pageId, space: this.space }
      const data = new URLSearchParams()
      data.append('space', this.space)
      data.append('pageId', pageId)
      pageApi.openPageDetail(data).then((json) => {
        const wikiPageRes = json.data.wikiPage || {}
        wikiPageRes.selfZan = json.data.selfZan || 0
        this.wikiPage = wikiPageRes
        const pageContent = json.data.pageContent || {}
        const pageFileList = json.data.fileList || []
        this.pageFileList = pageFileList.map(item => {
          return {
            ...item,
            fileUrl:
              item.fileUrl.includes('http')
                ? item.fileUrl
                : `https://docs.mg.xyz/${item.fileUrl}`,
          }
        })
        if (this.wikiPage.editorType === 2) {
          pageContent.content = mavonEditor.getMarkdownIt().render(pageContent.content)
        }
        this.pageShowDetail = pageContent.content
        const wikiTile = wikiPageRes.name || 'WIKI-内容展示'
        // document.title = wikiTile
        setTimeout(() => {
          this.previewPageImage()
          const navigationListVal = htmlUtil.createNavigationHeading()
          // 标题加到导航里面去
          if (this.navigationList.length > 0) {
            navigationListVal.unshift({
              level: 1,
              node: this.$refs.wikiTitleRef,
              text: wikiTile,
            })
          }
          this.navigationList = navigationListVal
        }, 500)
      })
    },
    previewPageImage () {
      const imgArr = []
      const imgSelector = this.$refs.pageContentRef.querySelectorAll('img')
      imgSelector.forEach((item, index) => {
        const src = item.src.includes('http')
          ? item.src
          : `https://docs.mg.xyz/${item.src}`
        imgArr.push(src)
        item.onclick = () => {
          this.previewInitialIndex = index
          this.showImagePreviewList = imgArr
          this.showImagePreview = true
        }
      })
    },
    closeImagePreview () {
      console.log('closeImagePreview')
      this.showImagePreview = false
    },
  },
}
</script>

<style lang="scss" scoped>
.detail-container {
  display: flex;
  padding: 0 18.75vw;
  background: #fff;

  .left{
    display: flex;
    flex-direction: column;
    width: 14.844vw;
    min-width: 14.844vw;
    min-height: 35.417vw;
    border-right: 1px solid #e6e6e6;

    .aside-box{
      display: flex;
      flex-direction: column;
      margin-bottom: 1.042vw;

      .title{
        width: 100%;
        padding: 0.833vw 0 1.25vw;
        overflow: hidden;
        color: #222;
        font-weight: 500;
        font-size: 1.25vw;
        line-height: 1.875vw;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .page-title{
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        height: 2.083vw;
        padding: 0.417vw 0.625vw;
        color: #222;
        font-size: 0.729vw;
        line-height: 1.146vw;
        background: #EEF0F5;
      }

      .wiki-page-tree-box{
        ::v-deep{
          .el-tree-node__content{
            height: 2.083vw;

            &:hover{
              color: #0066FE;
              background: #fff;
            }
          }

          .el-tree-node:focus>.el-tree-node__content {
            color: #0066FE;
            background: #fff;
          }

          .el-tree-node.is-current > .el-tree-node__content{
            color: #0066FE;
            background: #fff;
          }

        }
      }
    }
  }

  .right{
    flex: 1;
    padding: 1.042vw;

    .doc-menu-main{
      display: flex;
      flex-direction: column;
      width: 100%;

      .doc-title{
        margin-bottom: 2.083vw;
        color: #000;
        font-weight: 500;
        font-size: 1.667vw;
        line-height: 1.979vw;
      }

      .doc-menu-panel{
        display: flex;
        flex-direction: column;
        margin-bottom: 1.042vw;
        background: #F6F7FA;
        border: 1px solid #E5E8ED;
        border-radius: 0.104vw;

        &:last-child{
          margin-bottom: 0;
        }

        .doc-menu-title{
          padding: 0.625vw 1.042vw;
          color: #000;
          font-size: 1.042vw;
          line-height: 1.25vw;
          border-bottom: 1px solid #E5E8ED;
        }

        .doc-menu-list{
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          box-sizing: border-box;
          padding: 0.833vw 1.042vw;
          row-gap: 0.625vw;

          .menu-list-item{
            display: flex;
            align-items: center;
            overflow: hidden;
            color: #666;
            font-size: 0.729vw;
            line-height: 1.042vw;
            white-space: nowrap;
            text-overflow: ellipsis;

            &:hover{
              color: #0066FE;
              cursor: pointer;

            }
          }
        }
      }
    }
  }
}
</style>

<style scoped>
@import '../../../../assets/style/wangEditor.css';

.page-share-view-vue .wiki-title {
  font-weight: bold;
  font-size: 2em;
  text-align: center;
}

.page-share-view-vue .wiki-author {
  padding: 20px 0;
  color: #888;
  font-size: 14px;
  line-height: 40px;
}

.page-share-view-vue .wiki-page-content img {
  max-width: 100%;
  cursor: pointer;
}

.page-share-view-vue .wiki-page-content img:hover {
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.3);
}

.page-share-view-vue .upload-page-file .el-upload-list {
  display: none;
}

.page-share-view-vue .is-link {
  color: #1e88e5;
  cursor: pointer;
}
</style>
