// import { getEnabledSwitchActions } from '@/utils/common/tableActions'
import i18n from '@/locales'
export default {
  created () {
    this.singleActions = [
      // {
      //   label: '修改',
      //   permission: 'products_update',
      //   action: row => {
      //     // 跳转详情
      //     this.$router.push({
      //       path: '/product/createOrUpdate',
      //       query: {
      //         id: row.id,
      //       },
      //     })
      //   },
      // },
      {
        label: '配置可用区域',
        permission: 'products_update',
        action: row => {
          this.createDialog('AvailableRegionAllConfigUpdateForProductDialog', {
            refresh: this.list,
            row: row,
          })
        },
      },
      {
        label: i18n.t('system.text_153'),
        actions: row => {
          return [
            // ...getEnabledSwitchActions(this, row, ['products_perform_enable', 'products_perform_disable'], {
            //   actions: [
            //     () => {
            //       this.onManager('performAction', {
            //         id: row.id,
            //         managerArgs: {
            //           action: 'enable',
            //         },
            //       })
            //     },
            //     () => {
            //       return this.onManager('performAction', {
            //         id: row.id,
            //         managerArgs: {
            //           action: 'disable',
            //         },
            //       })
            //     },
            //   ],
            // }),
            {
              label: '启用',
              permission: 'products_perform_enable',
              action: row => {
                this.onManager('performAction', {
                  id: row.id,
                  managerArgs: {
                    action: 'enable',
                  },
                })
              },
              meta: obj => {
                if (obj.enabled) {
                  return {
                    validate: false,
                    tooltip: '',
                  }
                } else {
                  return {
                    validate: true,
                    tooltip: '',
                  }
                }
              },
            },
            {
              label: '禁用',
              permission: 'products_perform_disable',
              action: row => {
                // 提示
                this.$confirm({
                  content: '禁用该计算资源后，该资源在租户端及超管端将不可新建，请谨慎操作',
                  okText: '确认禁用',
                  onOk: () => {
                    // 用户确认取消
                    this.onManager('performAction', {
                      id: row.id,
                      managerArgs: {
                        action: 'disable',
                      },
                    })
                  },
                  onCancel: () => {
                  },
                })
              },
              meta: obj => {
                if (obj.enabled) {
                  return {
                    validate: true,
                    tooltip: '',
                  }
                } else {
                  return {
                    validate: false,
                    tooltip: '',
                  }
                }
              },
            },
          ]
        },
      },
      // {
      //   label: '新建',
      //   // permission: 'product_create',
      //   action: obj => {
      //     this.$router.push({
      //       path: '/product/create',
      //       query: {
      //         id: obj.id,
      //       },
      //     })
      //   },
      //   // meta: obj => {
      //   //   const isReady = obj.status === 'ready'
      //   //   return {
      //   //     validate: isReady,
      //   //     tooltip: !isReady && i18n.t('compute.text_1048'),
      //   //   }
      //   // },
      // },

    ]
  },
}
