<template>
  <div class="product-architecture">
      <div class="title">{{data.title}}</div>
      <div class="intro" v-if="data.intro">{{data.intro}}</div>
      <div class="content">
          <el-image
            v-if="data.img"
            style="width: 100%; height: 100%"
            :src="data.img"
            :preview-src-list="[data.img]" />
      </div>
  </div>
</template>

<script>
import paramsMixin from '@/mixins/paramsMixin'
export default {
  name: 'ProductArchitecture',
  mixins: [paramsMixin],
  data () {
    return {
      list: [],
    }
  },
}
</script>

<style lang="scss" scoped>
.product-architecture{
  display: flex;
  flex-direction: column;
  // height: 56.302vw;
  padding-top: 2.5vw;
  background: #FAFAFB;

  .title{
    height: 1.875vw;
    color: #222;
    font-weight: 500;
    font-size: 1.563vw;
    line-height: 1.875vw;
    text-align: center;
  }

  .intro{
    padding: 0 18.75vw;
    height: 1.146vw;
    margin-top: 1.25vw;
    color: #646566;
    font-weight: normal;
    font-size: 0.729vw;
    line-height: 1.146vw;
    text-align: center;
  }

  .content{
    padding: 0 18.75vw 2.5vw 18.75vw;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #DCE4F0 100%, #E7EAED 100%);
    margin-top: 1.875vw;
    display: flex;
    justify-content: center;
    align-items: center;

    .img{
      width: 100%;
    }
  }
}
</style>
