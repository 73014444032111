<template>
  <base-dialog @cancel="cancelDialog" :width="1512">
    <div slot="header">统一配置可用区域</div>
    <div slot="body">
      <div class="content">
        <div class="left">
          <div class="city-list">
            <div class="city-item" v-for="city in cityList" :key="city.value" @click="handleCityClick(city)">
              <div class="city-item-left" :title="city.label">
                <span class="city-item-title">{{city.label}}</span>
              </div>
              <a-icon type="plus" class="city-item-add" @click="handleAddCityRegion(city)" />
            </div>
          </div>
          <div class="region-list">
            <div class="region-item" v-for="item in regionList" :key="item.id">
              <div class="region-item-left" :title="`${item.mapping_name}（${item.provider_name}（${item.region_name}）-${item.zone_name}）`">
                <span class="region-item-title">{{item.mapping_name}}</span>
                <span class="region-item-desc">（{{item.provider_name}}（{{item.region_name}}）-{{item.zone_name}}）</span>
              </div>
              <a-icon type="plus" class="region-item-add" @click="handleAddRegion(item)" />
            </div>
          </div>
        </div>
        <div class="right">
          <div class="region-title">可用区域</div>
          <div class="region-list">
             <div class="region-item" v-for="item in availableRegionList" :key="item.id">
              <div class="region-item-left" :title="`${item.mapping_name}（${item.provider_name}（${item.region_name}）-${item.zone_name}）`">
                <span class="region-item-title">{{item.mapping_name}}</span>
                <span class="region-item-desc">（{{item.provider_name}}（{{item.region_name}}）-{{item.zone_name}}）</span>
              </div>
              <a-icon type="close" class="region-item-remove" @click="handleRemoveRegion(item)" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div slot="footer">
      <a-button type="primary" @click="handleConfirm" :loading="loading">确认可用区域</a-button>
      <a-button @click="cancelDialog">{{ $t('dialog.cancel') }}</a-button>
    </div>
  </base-dialog>
</template>

<script>
import _ from 'lodash'
import DialogMixin from '@/mixins/dialog'
import WindowsMixin from '@/mixins/windows'

export default {
  name: 'AvailableRegionAllConfigUpdateForCustomerDialog',
  mixins: [DialogMixin, WindowsMixin],
  data () {
    return {
      treeData: [],
      cityList: [
        // { label: '杭州', value: '1' },
      ],
      regionList: [
        // { label: '华东-杭州1-1（阿里云-华东1（杭州）-可用区A）', value: '华东-杭州1-1（阿里云-华东1（杭州）-可用区A）' },
        // { label: '华东-杭州1-2（阿里云-华东1（杭州）-可用区B）', value: '华东-杭州1-2（阿里云-华东1（杭州）-可用区B）' },
        // { label: '华东-杭州1-3（阿里云-华东1（杭州）-可用区C）', value: '华东-杭州1-3（阿里云-华东1（杭州）-可用区C）' },
        // { label: '华东-上海1-1（阿里云-华东2（上海）-可用区A）', value: '华东-上海1-1（阿里云-华东2（上海）-可用区A）' },
        // { label: '华东-上海1-2（阿里云-华东2（上海）-可用区F）', value: '华东-上海1-2（阿里云-华东2（上海）-可用区F）' },
        // { label: '华东-上海1-3（阿里云-华东2（上海）-可用区G）', value: '华东-上海1-3（阿里云-华东2（上海）-可用区G）' },
      ],
      availableRegionList: [],
      loading: false,
      form: {
        fc: this.$form.createForm(this),
        fi: {
          cloudaccount: {},
          project: {},
        },
      },
    }
  },
  created () {
    this.loadAreaMappingsTree()
  },
  methods: {
    loadAreaMappingsTree () {
      const providers = this.params.details.providers || []
      const provider = providers.join(',')
      this.$http.get(`/v2/area_mappings/tree?provider=${provider}`)
        .then((response) => {
          const data = response.data
          // console.log(data)
          if (data && data.length > 0) {
            this.treeData = data
            this.cityList = data.map(item => ({ label: item.city_name, value: item.city_name }))
            // this.regionOptions = data.map(item => ({ label: item.mapping_name + '（' + item.provider_name + '（' + item.region_name + '）-' + item.zone_name + '）', value: item.id }))
            this.loadProductUserAreas()
          }
        })
    },
    loadProductUserAreas () {
      this.$http.get(`/v1/product_user_areas?user_id=${this.params.user.id}`)
        .then((response) => {
          const res = response.data
          const data = res.data
          // console.log(data)
          if (data && data.length > 0) {
            const area_ids = data[0].area_ids.map(item => parseInt(item, 10))
            // console.log('area_ids=', area_ids)
            const areas = []
            this.treeData.forEach(item => {
              const regionList = item.regions.filter(region => area_ids.includes(region.id))
              if (regionList.length > 0) {
                areas.push(...regionList)
                // console.log('regionList=', regionList)
              }
            })
            // console.log('areas=', areas)
            this.availableRegionList = areas
          }
        })
    },
    handleCityClick (city) {
      console.log(city)
      this.regionList = this.treeData.find(item => item.city_name === city.label)?.regions || []
    },
    // 将该城市下的所有可用区添加到可用区域列表中
    handleAddCityRegion (city) {
      this.handleCityClick(city)
      const oldList = [...this.availableRegionList].concat([...this.regionList])
      this.availableRegionList = _.uniqBy(oldList, 'id')
    },
    // 将该可用区域（单个）添加到可用区域列表中
    handleAddRegion (item) {
      const oldList = [...this.availableRegionList].concat([item])
      this.availableRegionList = _.uniqBy(oldList, 'id')
    },
    // 将该可用区域（单个）从可用区域列表中移除
    handleRemoveRegion (item) {
      const oldList = [...this.availableRegionList]
      const index = oldList.findIndex(i => i.id === item.id)
      if (index > -1) {
        oldList.splice(index, 1)
        this.availableRegionList = oldList
      }
    },
    async handleConfirm () {
      console.log('this.params=', this.params)
      this.loading = true
      try {
        this.$http.post('/v1/product_user_areas/batch-areas', {
          user_id: this.params.user.id,
          area_ids: this.availableRegionList.map(item => item.id),
        })
          .then((response) => {
            console.log(response)
            this.$message.success('操作成功')
          })
        this.cancelDialog()
      } finally {
        this.loading = false
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.ant-form-item{
  margin-bottom: 0;
}
.content{
  display: flex;
  gap: 44px;
  padding: 10px;
  .left{
    display: flex;
    width: 789px;
    height: 607px;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.16);
    border-radius: 8px;
    .city-list{
      width: 202px;
      padding: 24px 12px;
      border-right: 1px solid #D8D8D8;
      .city-item{
        padding: 0 12px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .city-item-left{
          width: 100%;
          overflow: hidden; /*超出隐藏*/
          text-overflow: ellipsis;/*隐藏后添加省略号*/
          white-space: nowrap;/*强制不换行*/
          .city-item-title{
            font-size: 24px;
            line-height: 36px;
            height: 36px;
            color: #333333;
            user-select: none;
          }
        }
        .city-item-add{
          font-size: 24px;
          display: none;
        }
        &:hover{
          cursor: pointer;
          background: #e2e2e2;
          border-radius: 8px;
          .city-item-add{
            display: block;
          }
        }
      }
    }
  }
  .right{
    width: 604px;
    height: 607px;
    overflow: auto;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.16);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    .region-title{
      font-size: 28px;
      font-weight: 500;
      padding: 24px;
      color: #333333;
      border-bottom: 1px solid #D8D8D8;
      user-select: none;
    }
  }
  .region-list{
    width: 587px;
    height: 607px;
    display: flex;
    flex-direction: column;
    padding: 24px 12px;
    .region-item{
      padding: 0 12px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .region-item-left{
        width: 100%;
        overflow: hidden; /*超出隐藏*/
        text-overflow: ellipsis;/*隐藏后添加省略号*/
        white-space: nowrap;/*强制不换行*/
        // display: flex;
        .region-item-title{
          font-size: 24px;
          line-height: 36px;
          font-weight: 500;
          height: 36px;
          color: #333333;
          user-select: none;
        }
        .region-item-desc{
          font-size: 20px;
          line-height: 36px;
          font-weight: 400;
          height: 36px;
          color: #999;
        }
      }
      .region-item-add{
        font-size: 24px;
        display: none;
      }
      .region-item-remove{
        font-size: 24px;
        display: none;
      }
       &:hover{
        cursor: pointer;
        background: #e2e2e2;
        border-radius: 8px;
        .region-item-add{
          display: block;
        }
        .region-item-remove{
          display: block;
        }
      }
    }
  }
}
</style>
